import type { Workspace } from '@api/tdm';
import { UserRole } from '@api/tdm';
import { DEFAULT_MULTI_LABEL_KEY } from '@coral/constants';
import { SplitType } from '@core/types';
import publicRuntimeConfig from '@utils/publicRuntimeConfig';

export const HEADER_HEIGHT = 48;
export const DOC_HEADER = 48;
export const DEFAULT_SF_VERSION = '0';
export const VERSION =
  publicRuntimeConfig.SNORKEL_FLOW_VERSION || DEFAULT_SF_VERSION;
export const SETTINGS_VERSION = '0';
export const TOTAL_DOC_HEADER = HEADER_HEIGHT + DOC_HEADER;

export const FONT_SANS =
  "'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
export const FONT_MONO =
  "'apercu-mono-regular-pro', Monaco, Courier, monospace";

export const DEFAULT_FONT_SIZE = '12px';

export const RED = 'rgba(237, 0, 23, 1)';
export const ORANGE = 'rgba(255, 123, 0, 1)';
export const GREEN = 'rgba(0, 194, 89, 1)';

export const PRINT_MARGIN = '0.5in';
export const ACCEPTABLE_PRINT_WIDTH_FOR_SCREEN = '860px';
export const ACCEPTABLE_PRINT_WIDTH = '970px';

export const StudioSplits = [SplitType.DEV, SplitType.VALID, SplitType.TEST];
export const FullSplits = [
  SplitType.TRAIN,
  SplitType.DEV,
  SplitType.VALID,
  SplitType.TEST,
];
export const AnnotationSplits = [
  SplitType.DEV,
  SplitType.VALID,
  SplitType.TEST,
  SplitType.TRAIN,
];
export const DataSourceSplits = [
  SplitType.TRAIN,
  SplitType.VALID,
  SplitType.TEST,
];

export const REDUCER = 'Reducer';
export const NORMALIZER = 'Normalizer';
export const EXTRACTOR = 'Extractor';

export const USER_DEFINED_OPERATOR_TYPE = 'UserDefinedOperator';
export const MODEL_OPERATOR_TYPE = 'Model';
export const USER_DEFINED_REDUCER_CATEGORY = 'UserDefinedReducer';
export const SPAN_NORMALIZER = 'SpanNormalizer';
export const SPAN_EXTRACTOR_OPERATOR_TYPE = 'SpanExtractor';

export const SPAN_PREVIEW_PRE_PROCESSOR = 'SpanPreviewPreprocessor';
export const CONTEXT_AGGREGATOR_TYPE = 'ContextAggregator';
export const MODEL_BASED_FEATURIZER = 'ModelBasedFeaturizer';

export const TAGS = 'Tags';
export const SPAN_TAGS = 'Span Tags';
export const DOC_TAGS = 'Document Tags';

export const GROUND_TRUTH = 'Ground Truth Label';
export const SPAN_GROUND_TRUTH = 'Span Ground Truth Label';

export const DATAVIEWER_TABLE_HEIGHT = 38;
export const DATAVIEWER_HEADER_FOOTER_HEIGHT = 190;
export const MIN_DATA_TABLE_ENTRIES = 20;

export const DEFAULT_SCROLL_WIDTH = 10;

export const POSITIVE_CHART_COLOR = '#08BDBA'; // teal-400
export const NEGATIVE_CHART_COLOR = '#FF8389'; // red-400

export const MODEL = 'Model';
export const CONFIDENCE = 'Confidence';
export const PREDICTION = 'Prediction';
export const ENTROPY = 'Entropy';
export const TRAINING_SET = 'Training set';

export const FieldSelectInputType = 'DataframeFieldChoices';
export const MultiFieldSelectInputType = 'DataframeMultiFieldChoices';
export const CustomChoices = 'CustomChoices';

export const HEADER_MENU_BG_COLOR = '#383d43';

export enum PublicRoutes {
  ADMIN = '/admin',
  ADMIN_LOGIN = '/admin/login',
  ADMIN_CREATE_USER = '/admin/create-user',
  INVITE = '/invite',
}

export enum PrivateRoutes {
  ADMIN_SETTINGS = '/admin/settings',
}

export const MAX_CARDINALITY = 32;
export const MID_CARDINALITY = 10; // We show Confusion matrix as a table and disable margin-matrix for mid-cardinality.

/**
 * Routes for DevelopmentRoutes and AnnotationRoutes
 * should be node level routes rather than workspace or application
 * level routes
 */
export enum DevelopmentRoutes {
  ANALYSIS = 'analysis',
  STUDIO = 'studio',
  TRAIN = 'train',
  MANAGER = 'manager',
  MODELS = 'models',
  DATASOURCES = 'datasources',
  ANALYZE = 'analyze',
  DEVELOP = 'develop',
  EVALUATE = 'evaluate',
}

export enum AnnotationRoutes {
  BATCH = 'batch',
  ANNOTATION = 'annotation',
}

export enum ApplicationAnnotationRoutes {
  WORK = 'work',
}

export const NEGATIVE_CLASS_LABEL = 0;
export const SLIM_MODAL_WIDTH = 400;
export const DATASET_PAGE_URL = '/datasets';
export const { SENTRY_DSN, SENTRY_ENVIRONMENT } = publicRuntimeConfig;

export const STANDARD_DEBOUNCE = 350;
export const STANDARD_THROTTLE = 350;
export const LONGER_DEBOUNCE = 500;
export const LONGER_THROTTLE = 500;
export const SEARCH_DEBOUNCE = 200;

export const DEFAULT_JOBS_LIMIT = 250;

export const CONVERSATION_TEXT_FIELD = 'conversation_text';
export const CONVERSATION_JSON_FIELD = 'conversation_json';
export const HUMAN_WILL_NOTICE_MS = 100;
export const ANOMALY_DETECTION_NODE = 'AnomalyDetectionNode';
export const MULTILABEL_CLASSIFICATION_NODE = 'MultilabelClassificationNode';
export const LLM_FINE_TUNING_NODE = 'LLMFineTuningNode';

export const DEFAULT_SELECTED_LF: number = -Infinity;

export const DevelopmentEnabledUsers: (UserRole | undefined)[] = [
  UserRole.Admin,
  UserRole.Standard,
];

export const CANDIDATE_SPAN_UID_FIELD = 'current_text_selection';

export { DEFAULT_MULTI_LABEL_KEY };

export const NON_EDITABLE_LFS = [
  'boolean_code',
  'code',
  'sequence_code',
  'multipolar_code',
  'sequence_multipolar_code',
];

/* Models */

export enum ModelMetric {
  ACCURACY = 'accuracy',
  ACCURACY_BALANCED = 'accuracy_balanced',
  ACCURACY_MACRO = 'accuracy_macro',
  ANOMALY_F1 = 'anomaly_f1',
  ANOMALY_PRECISION = 'anomaly_precision',
  ANOMALY_RECALL = 'anomaly_recall',
  F1 = 'f1',
  F1_MACRO = 'f1_macro',
  F1_MICRO = 'f1_micro',
  OVERLAP_SPAN_F1 = 'overlap_span_f1',
  OVERLAP_SPAN_PRECISION = 'overlap_span_precision',
  OVERLAP_SPAN_RECALL = 'overlap_span_recall',
  PRECISION = 'precision',
  PRECISION_MACRO = 'precision_macro',
  RECALL = 'recall',
  RECALL_MACRO = 'recall_macro',
  SPAN_F1 = 'span_f1',
  SPAN_PRECISION = 'span_precision',
  SPAN_RECALL = 'span_recall',
  TOKEN_F1 = 'token_f1',
  TOKEN_PRECISION = 'token_precision',
  TOKEN_RECALL = 'token_recall',
  TOP_3_ACCURACY = 'top_3_accuracy',
  ANY_PRESENT = 'any-present',
  ALL_PRESENT = 'all-present',
}

export const ModelMetricTitleMap: { [K in ModelMetric]: string } = {
  [ModelMetric.ACCURACY]: 'Accuracy',
  [ModelMetric.ACCURACY_BALANCED]: 'Accuracy Balanced',
  [ModelMetric.ACCURACY_MACRO]: 'Accuracy Macro',
  [ModelMetric.ANOMALY_F1]: 'Anomaly F1',
  [ModelMetric.ANOMALY_PRECISION]: 'Anomaly Precision',
  [ModelMetric.ANOMALY_RECALL]: 'Anomaly Recall',
  [ModelMetric.F1]: 'F1',
  [ModelMetric.F1_MACRO]: 'F1 Macro',
  [ModelMetric.F1_MICRO]: 'F1 Micro',
  [ModelMetric.OVERLAP_SPAN_F1]: 'Overlap Span F1',
  [ModelMetric.OVERLAP_SPAN_PRECISION]: 'Overlap Span Precision',
  [ModelMetric.OVERLAP_SPAN_RECALL]: 'Overlap Span Recall',
  [ModelMetric.PRECISION]: 'Precision',
  [ModelMetric.PRECISION_MACRO]: 'Precision Macro',
  [ModelMetric.RECALL]: 'Recall',
  [ModelMetric.RECALL_MACRO]: 'Recall Macro',
  [ModelMetric.SPAN_F1]: 'Span F1',
  [ModelMetric.SPAN_PRECISION]: 'Span Precision',
  [ModelMetric.SPAN_RECALL]: 'Span Recall',
  [ModelMetric.TOKEN_F1]: 'Token F1',
  [ModelMetric.TOKEN_PRECISION]: 'Token Precision',
  [ModelMetric.TOKEN_RECALL]: 'Token Recall',
  [ModelMetric.TOP_3_ACCURACY]: 'Top 3 Accuracy',
  [ModelMetric.ANY_PRESENT]: 'Any Present',
  [ModelMetric.ALL_PRESENT]: 'All Present',
};

export const SCROLLABLE_CONTAINER_ID = 'scrollable-container';

export const DEFAULT_WORKSPACE_NAME = 'default';
export const DEFAULT_WORKSPACE_UID = 1;

export const DEFAULT_WORKSPACE: Workspace = {
  workspace_uid: DEFAULT_WORKSPACE_UID,
  name: DEFAULT_WORKSPACE_NAME,
  config: { dataset_limit: 0, application_limit: 0 },
};

export const USER_GUIDE_PYTHON_SDK_URL = '/user_guide/python_sdk/index.html';

const urlZendeskRoot = 'https://support.snorkel.ai/hc/en-us';
const urlZendeskCategories = `${urlZendeskRoot}/categories`;
export const urlZendeskArticles = `${urlZendeskRoot}/articles`;
export const urlZendeskUserGuide = `${urlZendeskCategories}/7294880777364-User-Guide`;
export const urlZendeskArticleDataFormatting = `${urlZendeskArticles}/23190834860436`;

export const UNKNOWN_ERROR_MESSAGE = 'An unknown error has happened';

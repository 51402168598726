import type { PropsWithChildren } from 'react';
import React from 'react';

import StackPrimitive from '../../../layout/__internal__/StackPrimative';

type AccordionControlProps = PropsWithChildren<{}>;

const AccordionControl = ({ children }: AccordionControlProps) => (
  <StackPrimitive className="box-border flex h-toolbar flex-row items-center justify-between border-b bg-coolGray-100 p-3">
    {children}
  </StackPrimitive>
);

export default AccordionControl;

import {
  AnnotationRoutes,
  ApplicationAnnotationRoutes,
  DevelopmentRoutes,
} from '@core/constants';
import { WorkspaceType } from '@global/state/reducers/workspaces/types';
import type { SupportedFeatures } from '@utils/supportedFeatures';

const getWorkspaceFromFeaturesAndPath = (
  supportedFeatures: SupportedFeatures,
  currentPath: string,
  currentWorkspace?: WorkspaceType,
) => {
  const developmentSupported = supportedFeatures.development_workspace;

  if (
    Object.values(DevelopmentRoutes).some(path =>
      currentPath.split('/').includes(path),
    ) &&
    developmentSupported
  ) {
    return WorkspaceType.DEVELOPMENT;
  }

  if (
    Object.values(AnnotationRoutes).some(path =>
      currentPath.split('/').includes(path),
    )
  ) {
    return WorkspaceType.ANNOTATION;
  }

  if (currentPath.split('/').includes(ApplicationAnnotationRoutes.WORK)) {
    return WorkspaceType.ANNOTATION;
  }

  if (!developmentSupported) return WorkspaceType.ANNOTATION;

  return currentWorkspace || WorkspaceType.DEVELOPMENT;
};

export default getWorkspaceFromFeaturesAndPath;

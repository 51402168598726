import React from 'react';

type EmptyDropdownMessageProps = {
  hasGroup?: boolean;
  emptyMessage?: string;
};

const EmptyDropdownMessage = ({
  hasGroup = false,
  emptyMessage,
}: EmptyDropdownMessageProps) => {
  if (hasGroup) {
    return <div className="px-4 py-2">No items in this group</div>;
  }

  return <div className="px-3 py-2">{emptyMessage || 'No results'}</div>;
};

export default EmptyDropdownMessage;

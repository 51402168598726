import type { Workspace } from '@api/tdm';

export enum WorkspaceType {
  DEVELOPMENT = 'default',
  ANNOTATION = 'annotator',
}

export type WorkspacesState = Readonly<{
  type: WorkspaceType;
  adminWorkspaces: Workspace[];
  workspaces: Workspace[];
  selectedWorkspace: Workspace;
}>;

import Button from '@coral/components/Button';
import Icon, { Icons } from '@coral/components/Icon';
import Textv2 from '@coral/components/Textv2';
import HStack, { HStackAlignment, HStackPosition } from '@coral/layout/HStack';
import VStack from '@coral/layout/VStack';
import type {
  NotificationId,
  ProgressNotificationProps,
} from '@global/state/reducers/notifications/types';

import ProgressBar from './ProgressBar';

type ProgressBarNotificationProps = {
  notification: ProgressNotificationProps;
  onClose: (uid: NotificationId) => void;
};

const ProgressBarNotification = ({
  notification,
  onClose,
}: ProgressBarNotificationProps) => {
  const { uid, message, percent = 0 } = notification;

  const handleClose = () => {
    onClose(uid);
  };

  return (
    <div
      role="alert"
      data-cy="notification"
      className="flex flex-col rounded border-l-4 border-gray-500 bg-white px-2 py-4 text-sm shadow-lg"
    >
      <HStack
        alignment={HStackAlignment.CENTER}
        position={HStackPosition.BETWEEN}
      >
        <Textv2 prefixEl={<Icon name={Icons.INFORMATION__NORMAL} />}>
          Status
        </Textv2>
        <Button
          data-cy="close-notification"
          icon={Icons.X}
          onClick={handleClose}
          blank
        />
      </HStack>
      <VStack>
        <p className="flex text-gray-600">{message}</p>
        <div className="my-2">
          <ProgressBar value={percent} />
        </div>
      </VStack>
    </div>
  );
};

export default ProgressBarNotification;

import type { ReactText } from 'react';
import React from 'react';

import { TextSize, Variant } from '../types';

const getElement =
  (variant: Variant, size: TextSize, href?: string) =>
  (children?: ReactText) => {
    const anchored = href ? <a href={href}>{children}</a> : children;

    if (variant === Variant.heading)
      switch (size) {
        case TextSize.xsmall:
          return <h4>{anchored}</h4>;
        case TextSize.small:
          return <h3>{anchored}</h3>;
        case TextSize.main:
          return <h2>{anchored}</h2>;
        case TextSize.large:
          return <h1>{anchored}</h1>;
        default:
          return <h2>{anchored}</h2>;
      }

    return anchored;
  };

export default getElement;

import type { ApplicationDetails } from '@app/Applications/types';
import { emptyObj } from '@core/empty';
import type { NodeDetails } from '@core/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { NodeDetailsState } from './types';

export const initialState: NodeDetailsState = {
  application: emptyObj as ApplicationDetails,
  node: { node_config: emptyObj } as NodeDetails,
};

const reducerSlice = createReducerSlice('nodeDetails', initialState, {
  reset(): NodeDetailsState {
    return initialState;
  },

  setNodeDetails(
    state: NodeDetailsState,
    payload: NodeDetailsState,
  ): NodeDetailsState {
    return {
      ...state,
      ...payload,
    };
  },

  setNodeDetailsNode(
    state: NodeDetailsState,
    payload: NodeDetails,
  ): NodeDetailsState {
    return {
      ...state,
      node: {
        ...payload,
      },
    };
  },
});

export const { actionTypes, actionCreators, reducer } = reducerSlice;

export default null;

import { formatDistanceToNow, utcToLocalDate } from '@coral/utils/date';

type ConvertToTimeAgoArgs = {
  time: string | number | undefined;
  fallback?: string;
};

function isNumeric(str: string) {
  return /^\d+$/.test(str);
}

const convertToTimeAgo = ({ time, fallback }: ConvertToTimeAgoArgs) => {
  if (!time) {
    return fallback || '';
  }

  const adjustedTime =
    typeof time === 'string' && isNumeric(time) ? parseInt(time, 10) : time;

  return formatDistanceToNow(
    utcToLocalDate(new Date(adjustedTime).toISOString()),
    {
      addSuffix: true,
    },
  );
};

export default convertToTimeAgo;

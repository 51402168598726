import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useCandidateStateContextIndex from '@app/StudioPage/hooks/candidateState/useCandidateStateContextIndex';
import useCandidateStateSelected from '@app/StudioPage/hooks/candidateState/useCandidateStateSelected';
import type { ContextBody, Contexts, Span, Spans } from '@core/types';
import * as selectors from '@global/state/selectors';

export type UseCandidateStateResult = ReturnType<
  typeof useCandidateStateSelected
> &
  ReturnType<typeof useCandidateStateContextIndex> & {
    current?: Contexts[0];
    selectedSpan?: Span;
    spans?: Spans;
    context?: ContextBody;
  };

const useCandidateState = (): UseCandidateStateResult => {
  const { selected, setSelected } = useCandidateStateSelected();
  const { contextIndex, setContextIndex } = useCandidateStateContextIndex();

  const uidField = useSelector(selectors.candidate.selectUidField);
  const contexts = useSelector(selectors.candidate.selectContexts);

  const current = contexts?.[contextIndex];

  const { selectedSpan, spans, context } = useMemo(() => {
    const currentSpans = current?.spans;
    const currentContext = current?.context;

    const currentSelectedSpan =
      selected.length === 1 && uidField
        ? currentSpans?.find(span => span[uidField] === selected[0])
        : undefined;

    return {
      selectedSpan: currentSelectedSpan,
      spans: currentSpans,
      context: currentContext,
    };
  }, [selected, current, uidField]);

  return {
    selected,
    contextIndex,
    current,
    selectedSpan,
    spans,
    context,
    setSelected,
    setContextIndex,
  };
};

export default useCandidateState;

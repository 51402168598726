import { Tool } from '@app/StudioPage/components/LFComposerToolbar/types';
import { emptyList } from '@core/empty';
import { createReducerSlice } from '@utils/redux/reducer';

import type { WaveState } from './types';

export const initialState: WaveState = {
  // common:
  activeLFConfig: null,
  activeTool: Tool.DEFAULT,
  currentLFPage: 1,
  currentViewPage: 1,
  // image/text:
  textQuery: '',
  queryStatus: {
    isFilled: false,
    isPreviewed: false,
  },
  bulkSelectedImages: emptyList,
};

const reducerSlice = createReducerSlice('wave', initialState, {
  setActiveLFConfig(
    state: WaveState,
    activeLFConfig: WaveState['activeLFConfig'],
  ): WaveState {
    return activeLFConfig === state.activeLFConfig
      ? state
      : { ...state, activeLFConfig };
  },

  setActiveTool(
    state: WaveState,
    activeTool: WaveState['activeTool'],
  ): WaveState {
    return activeTool === state.activeTool ? state : { ...state, activeTool };
  },

  setCurrentLFPage(
    state: WaveState,
    currentLFPage: WaveState['currentLFPage'],
  ): WaveState {
    return currentLFPage === state.currentLFPage
      ? state
      : { ...state, currentLFPage };
  },

  setCurrentViewPage(
    state: WaveState,
    currentViewPage: WaveState['currentViewPage'],
  ): WaveState {
    return currentViewPage === state.currentViewPage
      ? state
      : { ...state, currentViewPage };
  },

  setTextQuery(state: WaveState, textQuery: WaveState['textQuery']): WaveState {
    return textQuery === state.textQuery ? state : { ...state, textQuery };
  },

  setQueryStatus(
    state: WaveState,
    queryStatus: WaveState['queryStatus'],
  ): WaveState {
    return queryStatus === state.queryStatus
      ? state
      : { ...state, queryStatus };
  },

  setBulkSelectedImages(
    state: WaveState,
    bulkSelectedImages: WaveState['bulkSelectedImages'],
  ): WaveState {
    return bulkSelectedImages === state.bulkSelectedImages
      ? state
      : { ...state, bulkSelectedImages };
  },

  resetBulkSelectedImages(state: WaveState): WaveState {
    return emptyList === state.bulkSelectedImages
      ? state
      : { ...state, bulkSelectedImages: emptyList };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

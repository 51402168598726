import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { GetCurrentUserResponse } from '@api/tdm';
import {
  calcExpiryFromAccessToken,
  getGlobalAccessToken,
  setGlobalAccessToken,
} from '@global/AccessTokenManager';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import useConfirmCurrentUserAccess from '@hooks/useConfirmCurrentUserAccess';
import UseRequestProvider from '@hooks/useRequest/UseRequestProvider';
import useUserLogout from '@hooks/useUserLogout';
import AccessToken from '@utils/AccessToken';
import { push } from '@utils/api';
import { getReturnUrl } from '@utils/auth/returnUrlHelper';
import getIsServerRendered from '@utils/getIsServerRendered';
import makeGetAccessToken from '@utils/makeGetAccessToken';
import { getUserSupportedFeatures } from '@utils/supportedFeatures';

type AuthContextProviderProps = PropsWithChildren<{
  accessToken: string;
  existingUser?: GetCurrentUserResponse;
  basePath: string;
}>;

type AuthCredentials = Readonly<{
  accessToken: string;
  expiry: number;
}>;

const initializeAccessToken = (initialAccessToken: string) => {
  const globalAccessToken = getGlobalAccessToken();

  if (
    (globalAccessToken === null || globalAccessToken.length === 0) &&
    initialAccessToken.length > 0
  ) {
    setGlobalAccessToken(initialAccessToken);
  }
};

const useInitializeUser = (existingUser?: GetCurrentUserResponse) => {
  const dispatch = useDispatch();
  const userUid = useSelector(selectors.auth.selectUserUid);

  useEffect(() => {
    if (userUid === -1 && existingUser) {
      dispatch(actions.auth.setUser(existingUser));
    }
  }, [dispatch, existingUser, userUid]);
};

const useAuthCredentials = (accessToken: string): AuthCredentials => {
  const authCredentials = useMemo(() => {
    return { accessToken, expiry: calcExpiryFromAccessToken(accessToken) };
  }, [accessToken]);

  return authCredentials;
};

const AuthInitializer = ({
  accessToken,
  existingUser,
  basePath,
  children,
}: AuthContextProviderProps) => {
  initializeAccessToken(accessToken);
  useInitializeUser(existingUser);
  const authCredentials = useAuthCredentials(getGlobalAccessToken()!);
  const logout = useUserLogout();
  const dispatch = useDispatch();
  useConfirmCurrentUserAccess(authCredentials);

  useEffect(() => {
    const accessTokenBroadcastChannel =
      AccessToken.getInstance().getAccessTokenBroadcastChannel();

    dispatch(actions.auth.setSupportedFeatures(getUserSupportedFeatures()));

    accessTokenBroadcastChannel.onmessage = async event => {
      if (getIsServerRendered()) {
        return;
      }

      if (Object.hasOwn(event.data, 'globalAccessToken')) {
        const otherAccessToken = event.data?.globalAccessToken;

        if (otherAccessToken) {
          setGlobalAccessToken(otherAccessToken, true);

          dispatch(
            actions.auth.setSupportedFeatures(getUserSupportedFeatures()),
          );

          if (!accessToken && !!otherAccessToken) {
            push(getReturnUrl());
          }
        } else {
          logout();
        }
      } else {
        const getAccessToken = makeGetAccessToken();

        try {
          await getAccessToken(null);
        } catch (e) {
          logout();
        }
      }
    };

    return () => {
      accessTokenBroadcastChannel.onmessage = null;
    };
  }, [accessToken, logout, dispatch]);

  return (
    <UseRequestProvider
      accessToken={authCredentials}
      basePath={basePath}
      debugMode
    >
      {children}
    </UseRequestProvider>
  );
};

export default AuthInitializer;

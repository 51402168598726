import type { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import type { MutatorOptions, SWRConfiguration } from 'swr';
import useSWR from 'swr';

import useRequest from '@hooks/useRequestWithLogging';
import cacheKeyMiddleware from '@utils/requestCaching/cacheKeyMiddleware';
import type {
  CachedData,
  MutateArgs,
  UseCachedRequestOptions,
  UseCachedRequestResult,
} from '@utils/requestCaching/types';
import { mutatorOptions } from '@utils/requestCaching/types';

type ExtendedSWROptions<
  TCustom,
  TResponse extends Promise<AxiosResponse<any>>,
> = SWRConfiguration<CachedData<TCustom, TResponse>> & { cacheKey?: string };

const useCachedRequest = <
  TCustom,
  TArgs extends Record<any, any>,
  TResponse extends Promise<AxiosResponse<any>> = Promise<AxiosResponse<any>>,
>(
  openApiPromise: (args: TArgs) => TResponse,
  args: TArgs,
  options: UseCachedRequestOptions<CachedData<TCustom, TResponse>> = {},
): UseCachedRequestResult<TCustom, TResponse> => {
  const { useSWROptions, suspendFetch, onMutate, ...requestOptions } = options;

  const request = useRequest();

  const { data, error, isValidating, isLoading, mutate } = useSWR<
    CachedData<TCustom, TResponse>
  >(
    suspendFetch ? null : [openApiPromise, requestOptions, args],
    () =>
      request(
        openApiPromise,
        args,
        requestOptions,
        !!useSWROptions?.refreshInterval,
      ),
    {
      fallbackData: requestOptions?.fallback,
      use: [cacheKeyMiddleware],
      ...(useSWROptions ?? {}),
    } as ExtendedSWROptions<TCustom, TResponse>,
  );

  const boundUpdateThenRevalidate = useCallback(
    async (
      mutateArgs?: MutateArgs<CachedData<TCustom, TResponse>>,
      swrMutateOptions?: MutatorOptions<any>,
    ) => {
      const { optimisticData, onMutateArgs } = mutateArgs ?? {};

      const mutateOptions = {
        ...mutatorOptions,
        optimisticData,
      };

      if (onMutate) {
        const result = onMutate(onMutateArgs);
        mutate(result, { ...mutateOptions, ...swrMutateOptions });

        return;
      }

      mutate(optimisticData, { ...mutateOptions, ...swrMutateOptions });
    },
    [mutate, onMutate],
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate: boundUpdateThenRevalidate,
  };
};

export default useCachedRequest;

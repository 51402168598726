import type { MouseEventHandler } from 'react';
import React, { useState } from 'react';

import HStack, { HStackAlignment, HStackSpacing } from '../../../layout/HStack';
import Icon, { Icons } from '../../Icon';
import Text, { TextSize, TextVariant, TextWeight } from '../../Text';
import { TextColor } from '../../Text/types';
import type { OnboardingInnerAccordionProps } from '../types';

const OnboardingInnerAccordion = ({
  summary,
  children,
  open,
  hasBorder = true,
  summaryTextSize = TextSize.s,
  isDisabled = false,
  ...props
}: OnboardingInnerAccordionProps) => {
  const [isOpen, toggleIsOpen] = useState<boolean>(open || false);

  if (open !== undefined && open !== isOpen) {
    toggleIsOpen(open);
  }

  const onAccordionClick: MouseEventHandler<HTMLDetailsElement> = e => {
    if (isDisabled || open !== undefined) {
      e.preventDefault();

      return;
    }

    e.stopPropagation();

    toggleIsOpen(!isOpen);
  };

  const onToggle = (e: React.MouseEvent<HTMLDetailsElement>) => {
    e.stopPropagation();
  };

  // in order to make typescript understand that those props are compatible
  const textProps:
    | { size: TextSize.s; variant: TextVariant.subtitle }
    | { size: TextSize.l; variant: TextVariant.title } =
    summaryTextSize === TextSize.l
      ? {
          size: summaryTextSize,
          variant: TextVariant.title,
        }
      : {
          size: summaryTextSize,
          variant: TextVariant.subtitle,
        };

  return (
    <details
      open={open}
      {...props}
      className={hasBorder ? 'rounded border' : ''}
      onToggle={onToggle}
    >
      <summary
        onClick={onAccordionClick}
        className="flex cursor-pointer select-none items-center justify-between rounded p-2 focus:outline-none"
      >
        <HStack alignment={HStackAlignment.CENTER} spacing={HStackSpacing.s}>
          <Icon
            name={Icons.CHEVRON__RIGHT}
            className={isOpen ? 'rotate-90' : ''}
          />

          <Text
            {...textProps}
            className={isDisabled ? TextColor.DISABLED : ''}
            weight={TextWeight.semibold}
          >
            {summary}
          </Text>
        </HStack>
      </summary>
      <div className="box-border">{children}</div>
    </details>
  );
};

export default OnboardingInnerAccordion;

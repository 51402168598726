import { useSelector } from 'react-redux';

import MenuButton from '@components/Form/MenuButton';
import UserWithProfileHorizontal from '@components/User/UserWithProfileHorizontal';
import Icon from '@coral/components/Icon';
import Icons from '@coral/components/Icon/icons';
import type { User } from '@global/state/reducers/users/types';
import * as selectors from '@global/state/selectors';
import { validateRequestParam } from '@utils/validateRequestParams';

import SidebarMenuItem from './common/SidebarMenuItem';
import DocumentationMenu from './DocumentationMenu';
import LicenseWarnings from './LicenseWarnings';
import UserMenu from './UserMenu';

export const ROUTES_SUPPORTING_WORKSPACE_SWITCHING = [
  '',
  'applications',
  'datasets',
  'deployments',
];

const NavigationSidebarFooter = () => {
  const user = useSelector(selectors.auth.selectUser);
  const userUid = useSelector(selectors.auth.selectUserUid);

  const renderMyWorkItem = () => (
    <SidebarMenuItem
      href="/work"
      data-cy="my-work-nav-button"
      className="flex items-center justify-between gap-3 px-3 py-3"
      openInNewTab={false}
    >
      <Icon name={Icons.CHECKMARK__IN_CIRCLE} />
      <span className="flex-1 truncate">My work</span>
    </SidebarMenuItem>
  );

  const renderDocumentationItem = () => (
    <MenuButton
      menuProps={{
        elevation: 0,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
      menu={<DocumentationMenu />}
      button={
        <SidebarMenuItem
          className="flex items-center justify-between gap-3 px-3 py-3"
          data-cy="documentation-button"
        >
          <Icon name={Icons.HELP} />
          <span className="flex-1 truncate">Documentation</span>
          <Icon name={Icons.CHEVRON__DOWN} />
        </SidebarMenuItem>
      }
    />
  );

  const renderUserItem = () => (
    <MenuButton
      menuProps={{
        elevation: 0,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
      menu={<UserMenu />}
      button={
        <SidebarMenuItem className="flex py-2" data-cy="user-settings-button">
          <UserWithProfileHorizontal user={user as unknown as User} />
          <Icon name={Icons.CHEVRON__DOWN} />
        </SidebarMenuItem>
      }
    />
  );

  return (
    <ul className="flex flex-col gap-1">
      <div className="px-2">
        <LicenseWarnings />
      </div>
      {renderMyWorkItem()}
      {renderDocumentationItem()}
      {validateRequestParam(userUid) ? renderUserItem() : null}
    </ul>
  );
};

export default NavigationSidebarFooter;

/* eslint-disable local-rules/no-layering-violations */
import type { GraphStructure } from '../hooks/useGraph/types';
import { GraphCombiner } from '../hooks/useGraph/types';

const getDefaultGraph = (
  totalItems: number,
  combiner?: GraphCombiner,
): GraphStructure => {
  return [combiner || GraphCombiner.AND, ...[...Array(totalItems).keys()]];
};

export default getDefaultGraph;

import type { DataTagMap } from '@coral/types/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { TagsState } from './types';

export const initialState: TagsState = {
  currentTagId: -1,
  dataTags: {},
  docDataTags: {},
  isSuggestLFMenuOpen: false,
  isTagsMenuOpen: false,
};

type DocDataAndDataTags = {
  docDataTags: DataTagMap;
  dataTags: DataTagMap;
};

const reducerSlice = createReducerSlice('tags', initialState, {
  setDataTags(state: TagsState, dataTags: DataTagMap): TagsState {
    return dataTags === state.dataTags ||
      (Object.keys(dataTags).length === 0 &&
        Object.keys(state.dataTags).length === 0)
      ? state
      : { ...state, dataTags };
  },

  setDocDataTags(state: TagsState, docDataTags: DataTagMap): TagsState {
    return docDataTags === state.docDataTags ||
      (Object.keys(docDataTags).length === 0 &&
        Object.keys(state.docDataTags).length === 0)
      ? state
      : { ...state, docDataTags };
  },

  setDocDataAndDataTags(
    state: TagsState,
    { docDataTags, dataTags }: DocDataAndDataTags,
  ): TagsState {
    return { ...state, dataTags, docDataTags };
  },

  setIsTagsMenuOpen(state: TagsState, isTagsMenuOpen: boolean): TagsState {
    return isTagsMenuOpen === state.isTagsMenuOpen
      ? state
      : { ...state, isTagsMenuOpen };
  },

  setIsSuggestLFMenuOpen(
    state: TagsState,
    {
      isSuggestLFMenuOpen,
      tagId,
    }: { isSuggestLFMenuOpen: boolean; tagId: number },
  ): TagsState {
    return isSuggestLFMenuOpen === state.isSuggestLFMenuOpen &&
      tagId === state.currentTagId
      ? state
      : { ...state, isSuggestLFMenuOpen, currentTagId: tagId };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

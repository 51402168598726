import pino from 'pino';

import {
  getGlobalAccessToken,
  setGlobalAccessToken,
} from '@global/AccessTokenManager';
import { makeRequestWithoutErrorHandling } from '@hooks/useUntypedRequest';

export type Logger = {
  debug: (info: string | object) => void;
  info: (info: string | object) => void;
  warn: (info: string | object) => void;
  error: (info: string | object) => void;
};

const useLoggerPino = (): Logger => {
  const request = makeRequestWithoutErrorHandling(
    getGlobalAccessToken()!,
    setGlobalAccessToken,
  );

  const logger = pino({
    browser: {
      asObject: true,
      write: (o: any) => {
        // Write the string label of the level
        o.level = pino.levels.labels[o.level];

        // Fire request to /api/log
        request('log', { body: { log: JSON.stringify(o) } });
      },
    },
    level: 'trace',
    timestamp: pino.stdTimeFunctions.isoTime,
    formatters: {
      level: label => ({ level: label }),
    },
  });

  return logger;
};

const loggerNull: Logger = {
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: () => {},
};

const useLoggerNull = (): Logger => loggerNull;

const useLogger: () => Logger =
  process.env.NODE_ENV === 'test' ? useLoggerNull : useLoggerPino;

export default useLogger;

import type { NextPageContext } from 'next';

import type { AccessTokenData } from '@core/types';
import AccessToken from '@utils/AccessToken';
import fetchFromApi from '@utils/api/fetchFromApi';
import getAccessToken from '@utils/getAccessToken';
import getErrorMessage from '@utils/getErrorMessage';
import getIsServerRendered from '@utils/getIsServerRendered';
import parseCookie from '@utils/parseCookie';

import isExternalJwtLoginEnabled from './isJwtLoginEnabled';
import parseJWTFromAccessToken from './parseJWTFromAccessToken';

const getAccessTokenSSR = (ctx: NextPageContext | null) => {
  if (!ctx) {
    throw Error('Context not defined');
  }

  if (isExternalJwtLoginEnabled()) {
    const accessToken = ctx.req?.headers.authorization?.split(' ')[1];

    if (!accessToken) {
      throw Error('Auth header not found or invalid in SSR');
    }

    const { exp, supported_features } = parseJWTFromAccessToken(accessToken);

    return Promise.resolve({
      accessToken,
      expiry: exp,
      supportedFeatures: supported_features,
    });
  }

  const cookies = parseCookie(ctx.req);

  const { refresh_token } = cookies;

  return getAccessToken(refresh_token);
};

const tokenEarlyRenewalWindowMs = 10 * 60 * 1000; // 10 minutes

export const shouldRenewToken = (token: AccessTokenData) => {
  const timeLeftMs = token.expiry - new Date().getTime();
  const isTokenExpiredOrToBeExpired = timeLeftMs < tokenEarlyRenewalWindowMs;

  return isTokenExpiredOrToBeExpired;
};

let accessTokenPromise: Promise<AccessTokenData> | null = null;

export function setAccessTokenPromise(
  promise: Promise<AccessTokenData> | null,
) {
  accessTokenPromise = promise;
}

const getAccessTokenCSR = async () => {
  const accessToken = AccessToken.getInstance().getAccessToken();

  const isTokenValid = accessToken && !shouldRenewToken(accessToken);

  if (isTokenValid) {
    return accessToken;
  }

  if (!accessTokenPromise) {
    // eslint-disable-next-line no-async-promise-executor
    accessTokenPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetchFromApi('access-token', {
          headers: { Authorization: `Bearer ${accessToken?.accessToken}` },
        });

        const newAccessToken = await response.json();

        AccessToken.getInstance().setAccessToken(newAccessToken);

        resolve(newAccessToken);
      } catch (e) {
        reject(new Error(getErrorMessage(e)));
      } finally {
        accessTokenPromise = null;
      }
    });
  }

  return accessTokenPromise;
};

const makeGetAccessToken = (): ((
  ctx: NextPageContext | null,
) => Promise<AccessTokenData>) => {
  if (getIsServerRendered()) {
    return getAccessTokenSSR;
  }

  return getAccessTokenCSR;
};

export default makeGetAccessToken;

import combineClasses from '@coral/utils/combineClasses';

import type { Color, Variant } from '../types';

export const colorMap = {
  primary: {
    containedBg: 'bg-primary',
    text: 'text-primary',
    hoverContainedBg: 'hover:bg-blue-800',
    hoverOutlinedBg: 'hover:bg-blue-200',
    border: 'border-primary',
    activeContainedBg: 'bg-blue-800',
    activeOutlinedBg: 'bg-blue-100',
  },
  secondary: {
    containedBg: 'bg-secondary',
    text: 'text-secondary',
    hoverContainedBg: 'hover:bg-purple-800',
    hoverOutlinedBg: 'hover:bg-purple-200',
    border: 'border-secondary',
    activeContainedBg: 'bg-purple-800',
    activeOutlinedBg: 'bg-purple-200',
  },
  default: {
    containedBg: 'bg-gray-700',
    text: 'text-gray-600',
    hoverContainedBg: 'hover:bg-gray-800',
    hoverOutlinedBg: 'hover:bg-gray-200',
    border: 'border-gray-400',
    activeContainedBg: 'bg-gray-800',
    activeOutlinedBg: 'bg-gray-200',
  },
};

const dropdownButtonStyles = {
  secondary: {
    contained: 'bg-purple-800 hover:bg-purple-900 border-purple-800 text-white',
    outlined:
      'bg-purple-100 hover:bg-purple-200 border-purple-700 text-purple-700',
    default: '',
  },
  primary: {
    contained: 'bg-blue-800 hover:bg-blue-900 border-blue-800 text-white',
    outlined: 'bg-blue-100 hover:bg-blue-200 border-blue-700 text-blue-700',
    default: '',
  },
  default: {
    contained: '',
    outlined: 'bg-gray-100 hover:bg-gray-200 border-gray-400 text-gray-700',
    default: '',
  },
};
const dropdownArrowColors = {
  secondary: {
    contained: 'bg-purple-800 hover:bg-purple-900 border-purple-800 text-white',
    outlined:
      'bg-transparent hover:bg-purple-200 border-purple-700 text-purple-700',
    default: '',
  },
  primary: {
    contained: 'bg-blue-800 hover:bg-blue-900 border-blue-800 text-white',
    outlined: 'bg-transparent hover:bg-blue-200 border-blue-700 text-blue-700',
    default: '',
  },
  default: {
    contained: '',
    outlined: 'bg-transparent hover:bg-gray-200 border-gray-400 text-gray-700',
    default: '',
  },
};
export const baseClasses =
  'duration-300 align-items px-4 whitespace-nowrap truncate';
export const dropdownBorderWidth = 'border-t border-r border-b';

export const getSizeClasses = (size?: 'small' | 'large') => {
  if (size === 'small') return 'h-6';

  return 'h-8';
};

const getDropdownPositionClass = (
  size: 'small' | 'large',
  position: 'bottom' | 'top',
) => {
  if (size === 'small' && position === 'bottom') {
    return 'top-28';
  }

  if (size === 'large' && position === 'bottom') {
    return 'top-32';
  }

  if (size === 'small' && position === 'top') {
    return 'bottom-28';
  }

  if (size === 'large' && position === 'top') {
    return 'bottom-32';
  }

  return 'top-32';
};

export const getDropdownClasses = (
  size: 'small' | 'large' = 'small',
  position: 'bottom' | 'top' = 'bottom',
) => {
  const roundedClasses = position === 'bottom' ? 'rounded-b' : 'rounded-t';

  return combineClasses(
    roundedClasses,
    getDropdownPositionClass(size, position),
  );
};

export const getDropdownArrowButtonColor = (
  disabled: boolean = false,
  variant: Variant,
  color: Color,
) => {
  if (disabled) return 'bg-gray-200 text-gray-100';

  return combineClasses(
    dropdownArrowColors[color][variant],
    dropdownBorderWidth,
  );
};

export const getDropdownButtonClasses = (
  variant: Variant,
  color: Color,
  dropdownPosition: 'top' | 'bottom' = 'top',
) => {
  const baseDropdownButtonClasses = combineClasses(
    'border-l border-r',
    dropdownPosition === 'top' ? 'first:rounded-t' : 'last:rounded-b',
    dropdownPosition === 'top' ? 'border-t' : 'border-b',
  );

  return combineClasses(
    baseDropdownButtonClasses,
    dropdownButtonStyles[color][variant],
  );
};

const getClasses = (
  colorType: Color,
  variant: Variant,
  disabled: boolean,
  active: boolean,
) => {
  const {
    containedBg,
    text,
    hoverContainedBg,
    hoverOutlinedBg,
    border,
    activeContainedBg,
    activeOutlinedBg,
  } = colorMap[colorType];

  if (disabled) {
    if (variant === 'outlined') {
      return 'bg-gray-200 text-gray-400 border border-solid border-gray-300';
    }

    return 'bg-gray-200 text-gray-400 ';
  }

  if (variant === 'contained') {
    return `${containedBg} ${hoverContainedBg} text-white ${
      active ? activeContainedBg : ''
    }`;
  }

  if (variant === 'outlined') {
    return `bg-transparent ${hoverOutlinedBg} transition ${text} border border-solid ${border} ${
      active ? activeOutlinedBg : ''
    }`;
  }

  return `bg-transparent ${hoverOutlinedBg} border border-solid border-transparent hover:border-gray-200 ${text}`;
};

export default getClasses;

import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_WORKSPACE_UID } from '@core/constants';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import useGetRequestParams from '@hooks/useGetRequestParams';

type UseGlobalPreferencesReturnType = {
  createdByMeFilter: boolean;
  updateCreatedByMeFilter: (createdByMeFilter?: boolean) => Promise<void>;
  savedWorkspace: number;
  updateSavedWorkspace: (workspace: number) => Promise<void>;
  enableNotifications: boolean;
  updateEnableNotifications: (enableNotifications: boolean) => Promise<void>;
};

const useGlobalPreferences = (): UseGlobalPreferencesReturnType => {
  const global_preferences = useSelector(
    selectors.userSettings.selectGlobalPreferences,
  );

  const dispatch = useDispatch();
  const requestParams = useGetRequestParams();

  const {
    created_by_me_filter: createdByMeFilter,
    saved_workspace: savedWorkspace,
    enable_notifications: enableNotifications,
  } = global_preferences;

  const updateCreatedByMeFilter = async (createdByMeArg?: boolean) => {
    const nextValue = isNil(createdByMeArg)
      ? !createdByMeFilter
      : createdByMeArg;

    dispatch(
      actions.userSettings.updateGlobalPreferences({
        globalPreferences: {
          created_by_me_filter: nextValue,
        },
        requestParams,
      }),
    );
  };

  const updateSavedWorkspace = async (workspace: number) => {
    dispatch(
      actions.userSettings.updateGlobalPreferences({
        globalPreferences: {
          saved_workspace: workspace,
        },
        requestParams,
      }),
    );
  };

  const updateEnableNotifications = async (isNotificationEnabled: boolean) => {
    dispatch(
      actions.userSettings.updateGlobalPreferences({
        globalPreferences: {
          enable_notifications: isNotificationEnabled,
        },
        requestParams,
      }),
    );
  };

  return {
    createdByMeFilter: Boolean(createdByMeFilter),
    updateCreatedByMeFilter,
    savedWorkspace: savedWorkspace ?? DEFAULT_WORKSPACE_UID,
    updateSavedWorkspace,
    enableNotifications: enableNotifications ?? false,
    updateEnableNotifications,
  };
};

export default useGlobalPreferences;

import { StudioViewModes } from '@app/StudioPage/types';
import pipe from '@utils/pipe';

import type { AtomsState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<AtomsState> = ({ atoms }: RootState) => atoms;

export const selectModeJobId: Selector<AtomsState['modelJobId']> = pipe(
  selectAll,
  ({ modelJobId }: AtomsState) => modelJobId,
);

export const selectSelectedLfId: Selector<AtomsState['selectedLfId']> = pipe(
  selectAll,
  ({ selectedLfId }: AtomsState) => selectedLfId,
);

export const selectLfsVotedInfoResponse: Selector<
  AtomsState['lfsVotedInfoResponse']
> = pipe(
  selectAll,
  ({ lfsVotedInfoResponse }: AtomsState) => lfsVotedInfoResponse,
);

export const selectNotifications: Selector<AtomsState['notifications']> = pipe(
  selectAll,
  ({ notifications }: AtomsState) => notifications,
);

export const selectSuggestField: Selector<AtomsState['suggestField']> = pipe(
  selectAll,
  ({ suggestField }: AtomsState) => suggestField,
);

export const selectStudioViewMode: Selector<AtomsState['studioViewMode']> =
  pipe(selectAll, ({ studioViewMode }: AtomsState) => studioViewMode);

export const selectIsSingleDatapointView: Selector<boolean> = pipe(
  selectStudioViewMode,
  studioViewMode => {
    return [
      StudioViewModes.DATA_VIEW,
      StudioViewModes.DOCUMENT_VIEW,
      StudioViewModes.IMAGE_VIEW,
      StudioViewModes.RAW,
    ].includes(studioViewMode);
  },
);

export const selectPDFData: Selector<Uint8Array | undefined> = pipe(
  selectAll,
  ({ pdfData }: AtomsState) => pdfData,
);

export const selectPDFDataLoading: Selector<boolean> = pipe(
  selectAll,
  ({ isPDFDataLoading }: AtomsState) => isPDFDataLoading,
);

export const selectPDFZoom: Selector<number> = pipe(
  selectAll,
  ({ pdfZoom }: AtomsState) => pdfZoom,
);

export const selectSelectedLabelSortType: Selector<
  AtomsState['selectedLabelSortType']
> = pipe(
  selectAll,
  ({ selectedLabelSortType }: AtomsState) => selectedLabelSortType,
);

export const selectLFTableFilters: Selector<AtomsState['lfTableFilters']> =
  pipe(selectAll, ({ lfTableFilters }: AtomsState) => lfTableFilters);

export const selectSuggestedLFTableFilters: Selector<
  AtomsState['suggestedLFTableFilters']
> = pipe(
  selectAll,
  ({ suggestedLFTableFilters }: AtomsState) => suggestedLFTableFilters,
);

export const selectUserChannelSocket: Selector<
  AtomsState['userChannelSocket']
> = pipe(selectAll, ({ userChannelSocket }: AtomsState) => userChannelSocket);

export const selectDataConnectorConfigList: Selector<
  AtomsState['dataConnectorConfigList']
> = pipe(
  selectAll,
  ({ dataConnectorConfigList }: AtomsState) => dataConnectorConfigList,
);

export const selectAnalysisSplit: Selector<AtomsState['analysisSplit']> = pipe(
  selectAll,
  ({ analysisSplit }: AtomsState) => analysisSplit,
);

export const selectDeleteDatasourceJobs: Selector<
  AtomsState['deleteDatasourceJobs']
> = pipe(
  selectAll,
  ({ deleteDatasourceJobs }: AtomsState) => deleteDatasourceJobs,
);

export const selectDatasetParams: Selector<AtomsState['datasetParams']> = pipe(
  selectAll,
  ({ datasetParams }: AtomsState) => datasetParams,
);

export default null;

import { Icons } from '@coral/components/Icon';

import { MultiLabelClass } from '../../types/types';

export enum MultiLabelStatusTypes {
  Present = 1,
  Absent = 0,
  Abstain = -1,
}

export const MultiLabelsStatusTypesList = [
  MultiLabelStatusTypes.Abstain,
  MultiLabelStatusTypes.Present,
  MultiLabelStatusTypes.Absent,
];

export const MultiLabelStateToStatusMap = {
  [MultiLabelClass.PRESENT]: MultiLabelStatusTypes.Present,
  [MultiLabelClass.ABSENT]: MultiLabelStatusTypes.Absent,
  [MultiLabelClass.ABSTAIN]: MultiLabelStatusTypes.Abstain,
};

export const MultiLabelStatusToStateMap = {
  [MultiLabelStatusTypes.Present]: MultiLabelClass.PRESENT,
  [MultiLabelStatusTypes.Absent]: MultiLabelClass.ABSENT,
  [MultiLabelStatusTypes.Abstain]: MultiLabelClass.ABSTAIN,
};

export const MultiLabelIconTypeMap = {
  [MultiLabelStatusTypes.Present]: Icons.P_FILLED,
  [MultiLabelStatusTypes.Absent]: Icons.A_OUTLINE,
  [MultiLabelStatusTypes.Abstain]: Icons.UNKNOWN__IN_CIRCLE,
};

export const LabelTextClass = {
  [MultiLabelStatusTypes.Present]: 'text-gray-800',
  [MultiLabelStatusTypes.Absent]: 'text-gray-800',
  [MultiLabelStatusTypes.Abstain]: 'text-coolGray-700',
};

export default null;

import Icons from '@coral/components/Icon/icons';
import { emptyList, emptyObj } from '@core/empty';
import { createReducerSlice } from '@utils/redux/reducer';

import type { NavigationMetaData, NavigationState, NavItem } from './types';

export const initialNavigation: NavItem[] = [
  {
    title: 'Applications',
    href: '/',
    hrefOverride: '/applications',
    leftIcon: Icons.OVERVIEW,
    children: {
      data: [
        {
          hiddenUnlessOn: true,
          title: 'New application',
          href: '/applications/new',
        },
        {
          hiddenUnlessOn: true,
          title: 'New preset application',
          href: '/applications/preset',
        },
      ],
    },
  },
  { title: 'Datasets', href: '/datasets', leftIcon: Icons.DATASET },
  { title: 'Deployments', href: '/deployments', leftIcon: Icons.DEPLOY },

  // Notebook button defined inside NavigationSidebarNav
];

export const initialState: NavigationState = {
  navigation: initialNavigation,
  currentApplicationNavigation: emptyList,
  meta: emptyObj,
};

const reducerSlice = createReducerSlice('navigation', initialState, {
  setNavigation(
    state: NavigationState,
    navigation: NavItem[],
  ): NavigationState {
    return navigation === state.navigation ? state : { ...state, navigation };
  },

  setCurrentApplicationNavigation(
    state: NavigationState,
    currentApplicationNavigation: NavItem[],
  ): NavigationState {
    return currentApplicationNavigation === state.currentApplicationNavigation
      ? state
      : { ...state, currentApplicationNavigation };
  },

  setMeta(state: NavigationState, meta: NavigationMetaData): NavigationState {
    return meta === state.meta ? state : { ...state, meta };
  },

  setMetaLastNodePage(
    state: NavigationState,
    lastNodePage: NavigationMetaData['lastNodePage'],
  ): NavigationState {
    return lastNodePage === state.meta.lastNodePage
      ? state
      : { ...state, meta: { ...state.meta, lastNodePage } };
  },

  setMetaObjectStorageUrl(
    state: NavigationState,
    objectStorageUrl: NavigationMetaData['objectStorageUrl'],
  ): NavigationState {
    return objectStorageUrl === state.meta.objectStorageUrl
      ? state
      : { ...state, meta: { ...state.meta, objectStorageUrl } };
  },

  setMetaBatchName(
    state: NavigationState,
    batchName: NavigationMetaData['batchName'],
  ): NavigationState {
    return batchName === state.meta.batchName
      ? state
      : { ...state, meta: { ...state.meta, batchName } };
  },

  setMetaDatasetName(
    state: NavigationState,
    datasetName: NavigationMetaData['datasetName'],
  ): NavigationState {
    return datasetName === state.meta.datasetName
      ? state
      : { ...state, meta: { ...state.meta, datasetName } };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import { useState } from 'react';

import type { User } from '@global/state/reducers/users/types';
import type useRequest from '@hooks/useRequestWithLogging';
import alphabeticalSort from '@utils/alphabeticalSort';
import { usersApi } from '@utils/api/serverRequests';

const asUser = (u: unknown): User => u as unknown as User;

const useFetchUsers = (request: ReturnType<typeof useRequest>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchUsers = async (
    existingUsers: User[] = [],
    workspace_uid?: number,
  ): Promise<User[]> => {
    setIsLoading(true);

    const usersList = await request(
      usersApi.getListUsersUsersGet,
      { includeInactive: true, workspaceUid: workspace_uid },
      { fallback: existingUsers },
    );
    const sortedUsersList: User[] = usersList
      .map(asUser)
      .toSorted((a, b) => alphabeticalSort(a.username, b.username));

    setIsLoading(false);

    return sortedUsersList;
  };

  return { isLoading, fetchUsers };
};

export default useFetchUsers;

import uniqueId from 'lodash/uniqueId';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_ZOOM } from '@coral/prebuilts/ZoomControl/constants';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';

import NavigationBreadcrumb from './NavigationBreadcrumb';
import NavigationLogo from './NavigationLogo';

import NavigationSidebarFooter from '../NavigationSidebarFooter';
import NavigationSidebarNav from '../NavigationSidebarNav';

const SidebarNav = () => (
  <aside
    className="flex h-full w-50 min-w-50 max-w-50 flex-col overflow-auto border-r bg-white pt-0 text-xs"
    data-cy="sidebar-navigation"
  >
    <div className="h-toolbar opacity-0 " />
    <div className="flex flex-1 flex-col px-2">
      <NavigationSidebarNav />
      <NavigationSidebarFooter />
    </div>
  </aside>
);

// need to push the nav 1px down to align with logo
const NavigationSidebar = () => {
  const dispatch = useDispatch();
  const isSidebarNavVisible = useSelector(
    selectors.userPrefs.selectIsSidebarNavVisible,
  );

  const toggleVisibility = () => {
    dispatch(actions.userPrefs.toggleIsSidebarNavVisible());

    // TODO: ENG-15945 -> Add back tests for zoom
    dispatch(actions.atoms.setPDFZoom(DEFAULT_ZOOM));
  };

  return (
    <>
      <div className="py-app absolute left-0 top-0 z-50 mb-4 flex h-toolbar items-center">
        <NavigationLogo
          isSidebarOpen={isSidebarNavVisible}
          toggleVisibility={toggleVisibility}
          // required for react to render things properly
          // due to sync issues with localStorage & hydration
          key={uniqueId()}
        />
        <div className="pl-app mt-px">
          <NavigationBreadcrumb />
        </div>
      </div>
      {isSidebarNavVisible ? <SidebarNav /> : null}
    </>
  );
};

export default NavigationSidebar;

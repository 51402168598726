import { createReducerSlice } from '@utils/redux/reducer';

import type { DocFiltersState } from './types';

import { initialFilterConfig } from '../coreFilters/data';

export const initialState: DocFiltersState = {
  config: initialFilterConfig,
  page: 0,
  limit: 10,
  showAnnotations: false,

  training_set_uid: undefined,
};

const reducerSlice = createReducerSlice('studioPage/docFilters', initialState, {
  update(
    state: DocFiltersState,
    delta: Partial<DocFiltersState>,
  ): DocFiltersState {
    return Object.keys(delta).length === 0 ? state : { ...state, ...delta };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import getUID from '@utils/getUID';
import { createReducerSlice } from '@utils/redux/reducer';

import type {
  ErrorAlertProps,
  InfoAlertProps,
  NotificationId,
  NotificationItem,
  NotificationsState,
  OptionalUid,
  ProgressNotificationProps,
  SuccessAlertProps,
  WarningAlertProps,
  WithoutVariant,
} from './types';
import { NotificationVariant } from './types';

export const initialState: NotificationsState = [];

export const kMaxNotificationsToRender = 1;

const calcDefaultNotificationId = (): NotificationId =>
  String(getUID()) as NotificationId;

const queue = (
  state: NotificationsState,
  { uid, ...notification }: OptionalUid<NotificationItem>,
): NotificationsState => {
  const item = {
    ...notification,
    uid: uid ?? calcDefaultNotificationId(),
  } as NotificationItem;

  const currentLength = state.length;

  return currentLength < kMaxNotificationsToRender
    ? [...state, item]
    : [...state.slice(currentLength - kMaxNotificationsToRender + 1), item];
};

const reducerSlice = createReducerSlice('notifications', initialState, {
  showInfoNotification(
    state: NotificationsState,
    notification: WithoutVariant<OptionalUid<InfoAlertProps>>,
  ): NotificationsState {
    return queue(state, { ...notification, variant: NotificationVariant.info });
  },

  showWarningNotification(
    state: NotificationsState,
    notification: WithoutVariant<OptionalUid<WarningAlertProps>>,
  ): NotificationsState {
    return queue(state, {
      ...notification,
      variant: NotificationVariant.warning,
    });
  },

  showSuccessNotification(
    state: NotificationsState,
    notification: WithoutVariant<OptionalUid<SuccessAlertProps>>,
  ): NotificationsState {
    return queue(state, {
      ...notification,
      variant: NotificationVariant.success,
    });
  },

  showErrorNotification(
    state: NotificationsState,
    notification: WithoutVariant<OptionalUid<ErrorAlertProps>>,
  ): NotificationsState {
    return queue(state, {
      ...notification,
      variant: NotificationVariant.error,
    });
  },

  showProgressBarNotification(
    state: NotificationsState,
    notification: WithoutVariant<ProgressNotificationProps>,
  ): NotificationsState {
    return queue(state, {
      ...notification,
      variant: NotificationVariant.progress,
    });
  },

  remove(state: NotificationsState, id: NotificationId): NotificationsState {
    return state.filter(({ uid }: NotificationItem) => uid !== id);
  },

  removeAll(): NotificationsState {
    return initialState;
  },

  updateProgressBarNotification(
    state: NotificationsState,
    notificationData: WithoutVariant<ProgressNotificationProps>,
  ): NotificationsState {
    const { uid } = notificationData;
    const notificationDataWithVariant = {
      ...notificationData,
      variant: NotificationVariant.progress,
    };

    return state.map(notification =>
      notification.uid === uid ? notificationDataWithVariant : notification,
    );
  },
});

export const { actionCreators, reducer } = reducerSlice;

export default reducerSlice;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '@global/state/actions';
import getWorkspaceFromFeaturesAndPath from '@global/state/reducers/workspaces/getWorkspaceFromFeaturesAndPath';
import type { WorkspaceType } from '@global/state/reducers/workspaces/types';
import * as selectors from '@global/state/selectors';
import useCurrentPath from '@hooks/useCurrentPath';
import useUserWorkspaceType from '@hooks/useUserWorkspaceType';

const WorkspaceInitializer = () => {
  const dispatch = useDispatch();
  const workspaceTypeFromState = useSelector(selectors.workspaces.selectType);
  const { workspaceType, updateWorkspaceType, isFetching } =
    useUserWorkspaceType();
  const userUid = useSelector(selectors.auth.selectUserUid);

  const supportedFeatures = useSelector(selectors.auth.selectSupportedFeatures);
  const stringifedSupportedFeatures = JSON.stringify(supportedFeatures);
  const currentPath = useCurrentPath();

  const setWorkspaceType = async (nextWorkspace: WorkspaceType) => {
    if (isFetching || !userUid) {
      // Don't call `updateRoute` until `workspaceType` returned via `user_settings`
      // `workspaceType` will be defined eventually, but delay to prevent a race condition on refresh
      return;
    }

    await updateWorkspaceType(nextWorkspace);
  };

  if (workspaceType && workspaceTypeFromState !== workspaceType) {
    dispatch(actions.workspaces.setWorkspaceType(workspaceType));
  }

  useEffect(() => {
    const nextWorkspace = getWorkspaceFromFeaturesAndPath(
      supportedFeatures,
      currentPath,
      workspaceType,
    );

    setWorkspaceType(nextWorkspace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userUid,
    currentPath,
    stringifedSupportedFeatures,
    workspaceType,
    isFetching,
  ]);

  return null;
};

export default WorkspaceInitializer;

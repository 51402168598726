export enum TaskTypes {
  TEXT_EXTRACTION = 'text_extraction',
  CLASSIFICATION = 'classification',
  ENTITY_CLASSIFICATION = 'text_entity_classification',
  SEQUENCE_TAGGING = 'sequence_tagging',
  CONVERSATION_CLASSIFICATION = 'conversation_classification',
  ENTITY_LINKING = 'entity_linking',
}

export enum NativePDFs {
  NATIVE_PDF_EXTRACTION = 'pdfe',
  PDF_CLASSIFICATION = 'pdf-clf',
}

export enum HocrPDFs {
  HOCR_EXTRACTION = 'hocre',
  HOCR_EXTRACTION_NO_OCR = 'hocre_no_ocr',
  HOCR_CLASSIFICATION = 'hocr-clf',
}

export default null;

import pipe from '@utils/pipe';

import type { WaveState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<WaveState> = ({ wave }: RootState) => wave;

export const selectActiveLFConfig: Selector<WaveState['activeLFConfig']> = pipe(
  selectAll,
  ({ activeLFConfig }: WaveState) => activeLFConfig,
);

export const selectActiveTool: Selector<WaveState['activeTool']> = pipe(
  selectAll,
  ({ activeTool }: WaveState) => activeTool,
);

export const selectCurrentLFPage: Selector<WaveState['currentLFPage']> = pipe(
  selectAll,
  ({ currentLFPage }: WaveState) => currentLFPage,
);

export const selectCurrentViewPage: Selector<WaveState['currentViewPage']> =
  pipe(selectAll, ({ currentViewPage }: WaveState) => currentViewPage);

export const selectTextQuery: Selector<WaveState['textQuery']> = pipe(
  selectAll,
  ({ textQuery }: WaveState) => textQuery,
);

export const selectQueryStatus: Selector<WaveState['queryStatus']> = pipe(
  selectAll,
  ({ queryStatus }: WaveState) => queryStatus,
);

export const selectBulkSelectedImages: Selector<
  WaveState['bulkSelectedImages']
> = pipe(selectAll, ({ bulkSelectedImages }: WaveState) => bulkSelectedImages);

export default null;

import type { SplitType } from '@core/types';
import pipe from '@utils/pipe';

import type { LFTableState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<LFTableState> = ({ lfTable }: RootState) =>
  lfTable;

export const selectSplit: Selector<SplitType> = pipe(
  selectAll,
  ({ split }: LFTableState) => split,
);

export const selectSortOptions: Selector<LFTableState['sortOptions']> = pipe(
  selectAll,
  ({ sortOptions }: LFTableState) => sortOptions,
);

export default null;

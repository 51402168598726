import type { BackendFeatureFlagNames } from '@api/tdm';
import type { Icons } from '@coral/components/Icon';
import type { Flags } from '@utils/getFlag';

export enum Tool {
  DEFAULT = 'default',

  // Text tools
  SEARCH = 'search',
  CLUSTER = 'cluster',
  FM = 'foundation-model',

  // Code tools
  CODE = 'code',

  // Image tools
  IMAGE_SIMILARITY = 'image-similarity',
  IMAGE_MODEL = 'image-model',
  IMAGE_PATTERN = 'image-pattern',
}

export type ToolInfoType = {
  tool: Tool;
  title: string;
  description: string;
  icon: Icons;
  disable?: boolean;
  flag?: BackendFeatureFlagNames | Flags;
};

export default null;

import { isNil } from 'lodash';

import Divider from '@coral/components/Divider';
import type { NavItem } from '@global/state/reducers/navigation/types';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { validateRequestParam } from '@utils/validateRequestParams';

import NavList from './NavList';
import NavTitle from './NavTitle';

interface CurrentAppProps {
  data: NavItem[];
  openPaths: string[];
}

/**
 * Renders a nested menu for the user's current application
 */
const CurrentApp = ({ data, openPaths }: CurrentAppProps) => {
  const { query: { appId } = {} } = useSnorkelRouter();

  if (isNil(validateRequestParam(appId))) {
    // Current application UI shouldn't render when user not within an app or node,
    // such as in apps page or DAG
    return null;
  }

  return (
    <>
      <Divider className="my-4" />
      <NavTitle title="Current App" />
      <NavList
        child={{ data, loading: false, tabbed: false }}
        openPaths={openPaths}
      />
    </>
  );
};

export default CurrentApp;

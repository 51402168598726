type FnProducer<TArg, TResult> = (arg: TArg) => TResult;

export const memoizeOne = <TArg, TResult>(
  fn: FnProducer<TArg, TResult>,
): FnProducer<TArg, TResult> => {
  let cachedArg: TArg;
  let cachedResult: TResult;

  const memoizedFn = (arg: TArg): TResult => {
    if (arg !== cachedArg) {
      cachedArg = arg;
      cachedResult = fn(arg);
    }

    return cachedResult;
  };

  return memoizedFn;
};

export default null;

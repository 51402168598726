/* eslint-disable local-rules/no-layering-violations */
import { BackendFeatureFlagNames } from '@api/tdm';
import useBackendFlags from '@hooks/useBackendFlags';
import useCachedRequest from '@hooks/useCachedRequest';
import useGetRequestParams from '@hooks/useGetRequestParams';
import { applicationsApi } from '@utils/api/serverRequests';

const useIsCurrentAppOnboardingEnabled = () => {
  const { application_uid } = useGetRequestParams();

  const { isLoading: isLoadingSetupProgress, data: setupData } =
    useCachedRequest(
      applicationsApi.getSetupProgressApplicationApplicationUidSetupGet,
      { applicationUid: Number(application_uid) },
      { suspendFetch: !application_uid, getSnackbarDataOnError: null },
    );

  const { getBackendFlag } = useBackendFlags();

  const isOnboardingFlagEnabled =
    getBackendFlag(BackendFeatureFlagNames.Onboarding) ?? false;

  return Boolean(
    isOnboardingFlagEnabled && !isLoadingSetupProgress && setupData,
  );
};

export default useIsCurrentAppOnboardingEnabled;

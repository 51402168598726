import type { Cluster, ClusterResponse } from '@api/studio/api';
import type { SearchSnippet } from '@core/types';

export { Cluster, ClusterResponse };

export const SNIPPETS_PER_DOC = 3;

export const MAX_SNIPPETS_PER_DOC = 10;

export enum SnippetType {
  UNKNOWN = 'unknown',
  SNIPPET = 'snippet',
  CONTEXTUAL_SNIPPET = 'contextual_snippet',
}

export type SearchSnippets = {
  [context_uid: string]: SearchSnippet;
};

export type ContextualSearchSnippet = {
  snippets: SearchSnippets;
  total_matches: number;
};

export type ContextualSearchSnippets = {
  [context_uid: string]: ContextualSearchSnippet;
};

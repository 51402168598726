import pipe from '@utils/pipe';

import type { UserPrefsState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<UserPrefsState> = ({ userPrefs }: RootState) =>
  userPrefs;

export const selectIsSidebarNavVisible: Selector<
  UserPrefsState['isSidebarNavVisible']
> = pipe(
  selectAll,
  ({ isSidebarNavVisible }: UserPrefsState) => isSidebarNavVisible,
);

export const selectApplyPostprocessors =
  (key: string) =>
  (state: RootState): NonNullable<UserPrefsState['applyPostprocessors'][0]> => {
    const { applyPostprocessors } = selectAll(state);

    return applyPostprocessors?.[key] ?? false;
  };

export const selectCandidateSelected =
  (key: string) =>
  (state: RootState): NonNullable<UserPrefsState['candidateSelected'][0]> => {
    const { candidateSelected } = selectAll(state);

    return candidateSelected?.[key] ?? [];
  };

export const selectCandidateContextIndex =
  (key: string) =>
  (
    state: RootState,
  ): NonNullable<UserPrefsState['candidateContextIndex'][0]> => {
    const { candidateContextIndex } = selectAll(state);

    return candidateContextIndex?.[key] ?? 0;
  };

export default null;

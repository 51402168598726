import type { ReactText } from 'react';
import React from 'react';

import type { TextSize } from '../types';
import { Variant } from '../types';

const Element =
  (variant: Variant, size: TextSize, href?: string) =>
  (children?: ReactText) => {
    const anchored = href ? <a href={href}>{children}</a> : children;

    if (variant === Variant.headline) {
      return <h1>{anchored}</h1>;
    }

    if (variant === Variant.subtitle) {
      return <h2>{anchored}</h2>;
    }

    return anchored;
  };

export default Element;

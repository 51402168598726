import { createReducerSlice } from '@utils/redux/reducer';

import type { HighlightsState } from './types';

export const initialState: HighlightsState = {
  characterHighlightsPerXUidPerField: {},
  fieldSpansMap: {},
  filteredHighlightsMap: {},
  focusedHighlight: null,
  highlightMetadata: null,
  highlightXUid: undefined,
  isLoadingHighlights: false,
};

const reducerSlice = createReducerSlice('highlights', initialState, {
  setCharacterHighlightsPerXUidPerField(
    state: HighlightsState,
    characterHighlightsPerXUidPerField: HighlightsState['characterHighlightsPerXUidPerField'],
  ): HighlightsState {
    return characterHighlightsPerXUidPerField ===
      state.characterHighlightsPerXUidPerField
      ? state
      : { ...state, characterHighlightsPerXUidPerField };
  },

  setFieldSpansMap(
    state: HighlightsState,
    fieldSpansMap: HighlightsState['fieldSpansMap'],
  ): HighlightsState {
    return fieldSpansMap === state.fieldSpansMap
      ? state
      : { ...state, fieldSpansMap };
  },

  setFilteredHighlightsMap(
    state: HighlightsState,
    filteredHighlightsMap: HighlightsState['filteredHighlightsMap'],
  ): HighlightsState {
    return filteredHighlightsMap === state.filteredHighlightsMap
      ? state
      : { ...state, filteredHighlightsMap };
  },

  setFocusedHighlight(
    state: HighlightsState,
    focusedHighlight: HighlightsState['focusedHighlight'],
  ): HighlightsState {
    return focusedHighlight === state.focusedHighlight
      ? state
      : { ...state, focusedHighlight };
  },

  setHighlightXUid(
    state: HighlightsState,
    highlightXUid: HighlightsState['highlightXUid'],
  ): HighlightsState {
    return highlightXUid === state.highlightXUid
      ? state
      : { ...state, highlightXUid };
  },

  setIsLoadingHighlights(
    state: HighlightsState,
    isLoadingHighlights: boolean,
  ): HighlightsState {
    return isLoadingHighlights === state.isLoadingHighlights
      ? state
      : { ...state, isLoadingHighlights };
  },

  setHighlightMetadata(
    state: HighlightsState,
    highlightMetadata: HighlightsState['highlightMetadata'],
  ): HighlightsState {
    return highlightMetadata === state.highlightMetadata
      ? state
      : { ...state, highlightMetadata };
  },

  // action(state: HighlightsState, payload: Payload): HighlightsState {
  //   return state;
  // },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

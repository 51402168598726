import pipe from '@utils/pipe';

import type { HighlightsState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<HighlightsState> = ({
  highlights,
}: RootState) => highlights;

export const selectCharacterHighlightsPerXUidPerField: Selector<
  HighlightsState['characterHighlightsPerXUidPerField']
> = pipe(
  selectAll,
  ({ characterHighlightsPerXUidPerField }: HighlightsState) =>
    characterHighlightsPerXUidPerField,
);
export const selectFieldSpansMap: Selector<HighlightsState['fieldSpansMap']> =
  pipe(selectAll, ({ fieldSpansMap }: HighlightsState) => fieldSpansMap);
export const selectFilteredHighlightsMap: Selector<
  HighlightsState['filteredHighlightsMap']
> = pipe(
  selectAll,
  ({ filteredHighlightsMap }: HighlightsState) => filteredHighlightsMap,
);
export const selectFocusedHighlight: Selector<
  HighlightsState['focusedHighlight']
> = pipe(
  selectAll,
  ({ focusedHighlight }: HighlightsState) => focusedHighlight,
);
export const selectHighlightMetadata: Selector<
  HighlightsState['highlightMetadata']
> = pipe(
  selectAll,
  ({ highlightMetadata }: HighlightsState) => highlightMetadata,
);
export const selectHighlightXUid: Selector<string | undefined> = pipe(
  selectAll,
  ({ highlightXUid }: HighlightsState) => highlightXUid,
);
export const selectIsLoadingHighlights: Selector<boolean> = pipe(
  selectAll,
  ({ isLoadingHighlights }: HighlightsState) => isLoadingHighlights,
);

export default null;

import type { Dispatch, KeyboardEvent, SetStateAction } from 'react';

import type { CloseDropdown, OpenDropdown } from './useDropdownVisibility';

import type { DropdownOptionType, OnSelectItem, TraverseGroup } from '../types';
import {
  RestrictedKeysForDropdown,
  RestrictedKeysForDropdownArr,
} from '../types';
import getDropdownElementId from '../utils/getElementId';

type UseDropdownKeyboardProps<ValueType> = {
  disabled?: boolean;
  closeDropdown: CloseDropdown;
  openDropdown: OpenDropdown;
  activeValueIndex: number;
  setActiveValueIndex: Dispatch<SetStateAction<number>>;
  filteredOptions: DropdownOptionType<ValueType>[];
  options: DropdownOptionType<ValueType>[];
  setValuesByIndex: (valueIndex: number) => void;
  isDropdownVisible: boolean;
  onSelectItem: OnSelectItem;
  focusEntry: VoidFunction;
  multiSelect: boolean;
  id: string;
  traverseGroup: TraverseGroup;
};

const useDropdownKeyboard = <ValueType>(
  props: UseDropdownKeyboardProps<ValueType>,
) => {
  const {
    disabled,
    closeDropdown,
    openDropdown,
    activeValueIndex,
    setActiveValueIndex,
    focusEntry,
    filteredOptions,
    traverseGroup,
    options,
    id,
    setValuesByIndex,
    isDropdownVisible,
    onSelectItem,
    multiSelect,
  } = props;

  const focusElement = (index: number) => {
    const el = document.getElementById(getDropdownElementId(id, index));

    if (el) {
      el.focus();
    }
  };

  const keyHandler = (event: KeyboardEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }

    const { key, nativeEvent } = event;

    if (nativeEvent && nativeEvent.stopPropagation) {
      nativeEvent.stopPropagation();

      // if the key is tab or the key is a character, we don't run the following commands
      if (RestrictedKeysForDropdownArr.includes(key)) {
        nativeEvent.preventDefault();
        nativeEvent.stopImmediatePropagation();
      }
    }

    switch (key) {
      case RestrictedKeysForDropdown.Escape: {
        focusEntry();

        closeDropdown();

        break;
      }
      case RestrictedKeysForDropdown.Right:
      case RestrictedKeysForDropdown.ArrowRight: {
        traverseGroup(1);
        break;
      }
      case RestrictedKeysForDropdown.Left:
      case RestrictedKeysForDropdown.ArrowLeft: {
        traverseGroup(-1);
        break;
      }
      case RestrictedKeysForDropdown.Down:
      case RestrictedKeysForDropdown.ArrowDown: {
        openDropdown();

        if (activeValueIndex + 1 < filteredOptions.length) {
          const nextIndex = activeValueIndex + 1;

          setActiveValueIndex(nextIndex);
          focusElement(nextIndex);
        }

        break;
      }
      case RestrictedKeysForDropdown.Up:
      case RestrictedKeysForDropdown.ArrowUp: {
        openDropdown();

        if (activeValueIndex === -1) {
          const nextIndex = filteredOptions.length - 1;
          setActiveValueIndex(nextIndex);
          focusElement(nextIndex);
          break;
        }

        if (activeValueIndex - 1 >= 0) {
          const nextIndex = activeValueIndex - 1;

          setActiveValueIndex(nextIndex);
          focusElement(nextIndex);
        }

        break;
      }
      case 'Home': {
        openDropdown();

        setValuesByIndex(0);
        focusElement(0);

        break;
      }
      case RestrictedKeysForDropdown.End: {
        openDropdown();

        const nextIndex = options.length - 1;

        setValuesByIndex(nextIndex);
        focusElement(nextIndex);

        break;
      }
      case RestrictedKeysForDropdown.Enter: {
        if (!filteredOptions[activeValueIndex]) {
          return;
        }

        if (!isDropdownVisible) {
          openDropdown();

          return;
        }

        onSelectItem(filteredOptions[activeValueIndex].value);

        if (!multiSelect) {
          closeDropdown(true);
        }

        break;
      }

      default: {
        break;
      }
    }
  };

  return { keyHandler };
};

export default useDropdownKeyboard;

import type { PropsWithChildren } from 'react';
import { useState } from 'react';

import parsePythonError from '@components/Notifications/utils/parsePythonError';
import Icon, { Icons } from '@coral/components/Icon';
import combineClasses from '@coral/utils/combineClasses';
import type { ErrorMetaData } from '@global/state/reducers/notifications/types';

import DetailAccordion from './DetailAccordion';

// Exclude details from the metadata as this data is available as errorAlertData.message
const META_DATA_KEYS_EXCLUDED = { details: true };

type ErrorAlertProps = PropsWithChildren<{
  isWrappingPopUp: boolean;
  onClose: VoidFunction;
}>;

const ErrorAlert = ({
  children,
  isWrappingPopUp,
  onClose,
}: ErrorAlertProps) => {
  const className = combineClasses(
    isWrappingPopUp ? '' : 'absolute',
    'top-0',
    'z-notifications',
    'flex',
    'w-full',
    'justify-center',
    'overflow-y-auto',
    'shadow-banner',
  );

  return (
    <div data-cy="server-error-component" className={className}>
      <div className="relative w-full bg-yellow-100 p-4">{children}</div>
      <div
        className="absolute right-0 top-0 mr-2 mt-2 cursor-pointer text-lg"
        onClick={onClose}
      >
        <Icon name={Icons.X} />
      </div>
    </div>
  );
};

type ErrorDetailItemProps = Readonly<{
  label: string;
  value: string;
}>;

const ErrorDetailItem = ({ label, value }: ErrorDetailItemProps) => (
  <div
    key={label}
    className="flex flex-col items-start border-gray-200 p-1 text-xxs"
  >
    <span className="font-bold capitalize">{`${label}: `}</span>
    <span className="break-all font-mono">{parsePythonError(value)}</span>
  </div>
);

type ViewMoreTextProps = {
  text: string;
};

const ViewMoreText = ({ text }: ViewMoreTextProps) => {
  const visibleLength = 20;
  const isLongText = text.length > visibleLength + 10;

  const [isFullTextVisible, setIsFullTextVisible] = useState<boolean>(
    !isLongText,
  );

  const toggleVisibility = () => setIsFullTextVisible(v => !v);

  return (
    <>
      <span>
        {isFullTextVisible ? text : `${text.slice(0, visibleLength)}...`}
      </span>
      <span
        data-cy={isFullTextVisible ? 'view-less-button' : 'view-more-button'}
        className="cursor-pointer p-0 font-bold"
        onClick={toggleVisibility}
      >
        {isFullTextVisible ? ' View less' : ' View more'}
      </span>
    </>
  );
};

export type PageRegionServerErrorAlertProps = {
  tag?: string;
  isWrappingPopUp: boolean;
  message?: string;
  metaData?: ErrorMetaData;
};

const PageRegionServerErrorAlert = ({
  tag,
  isWrappingPopUp,
  message,
  metaData,
}: PageRegionServerErrorAlertProps) => {
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true);

  const onClose = () => setIsAlertVisible(false);

  const metaDataEntries = Object.entries(metaData ?? {});

  return isAlertVisible ? (
    <ErrorAlert isWrappingPopUp={isWrappingPopUp} onClose={onClose}>
      <div className="relative mt-2 flex items-start">
        <Icon name={Icons.WARNING_SQUARE_BLACK} />
        <div className="ml-2">
          <span className="font-bold">Something might be wrong.</span>
          {tag ? (
            <span>{` There may be an error loading the ${tag}. `}</span>
          ) : null}
          {message ? <ViewMoreText text={message} /> : null}
        </div>
      </div>
      {metaData ? (
        <DetailAccordion>
          {metaDataEntries.map(([label, value]) =>
            META_DATA_KEYS_EXCLUDED[label] ? null : (
              <ErrorDetailItem label={label} value={value} key={label} />
            ),
          )}
        </DetailAccordion>
      ) : null}
    </ErrorAlert>
  ) : null;
};

export default PageRegionServerErrorAlert;

import useSnorkelRouter from '@hooks/useSnorkelRouter';

import useGetCurrentBatch from './useGetCurrentBatch';

const useCurrentBatchUid = () => {
  const { query } = useSnorkelRouter();
  const { currentBatch } = useGetCurrentBatch();
  const batchUid = query?.batchUid;

  return currentBatch?.uid || typeof batchUid === 'string'
    ? parseInt(batchUid as string, 10)
    : null;
};

export default useCurrentBatchUid;

import { emptyList } from '@core/empty';
import { createReducerSlice } from '@utils/redux/reducer';

import type { User, UsersState } from './types';

export const initialState: UsersState = {
  users: emptyList,
  workspaceUsers: emptyList,
};

const reducerSlice = createReducerSlice('users', initialState, {
  setUsers(state: UsersState, users: User[]): UsersState {
    return users === state.users ? state : { ...state, users };
  },

  setWorkspaceUsers(state: UsersState, workspaceUsers: User[]): UsersState {
    return workspaceUsers === state.workspaceUsers
      ? state
      : { ...state, workspaceUsers };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

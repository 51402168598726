import * as atoms from './atoms/selectors';
import * as coreFilters from './coreFilters/selectors';
import * as dataFilters from './dataFilters/selectors';
import * as docFilters from './docFilters/selectors';
import * as plot from './plot/selectors';
import * as searchInput from './searchInput/selectors';
import * as similarityLfBuilder from './similarityLfBuilder/selectors';
import * as studio from './studio/selectors';
import * as suggestedSearches from './suggestedSearches/selectors';

export const studioPage = {
  atoms,
  coreFilters,
  dataFilters,
  docFilters,
  plot,
  searchInput,
  studio,
  similarityLfBuilder,
  suggestedSearches,
};

export default null;

import pipe from '@utils/pipe';

import type { UxState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<UxState> = ({ ux }: RootState) => ux;

export const selectIsAnalysisModalOpen: Selector<boolean> = pipe(
  selectAll,
  ({ isAnalysisModalOpen }: UxState) => isAnalysisModalOpen,
);

export const selectIsJobsModalOpen: Selector<boolean> = pipe(
  selectAll,
  ({ isJobsModalOpen }: UxState) => isJobsModalOpen,
);

export const selectIsLFsModalOpen: Selector<boolean> = pipe(
  selectAll,
  ({ isLFsModalOpen }: UxState) => isLFsModalOpen,
);

export const selectIsModelsModalOpen: Selector<boolean> = pipe(
  selectAll,
  ({ isModelsModalOpen }: UxState) => isModelsModalOpen,
);

export const selectIsWindowResizing: Selector<boolean> = pipe(
  selectAll,
  ({ isWindowResizing }: UxState) => isWindowResizing,
);

export const selectScrollToHighlight: Selector<UxState['scrollToHighlight']> =
  pipe(selectAll, ({ scrollToHighlight }: UxState) => scrollToHighlight);

export default null;

import type { ReactNode } from 'react';
import React from 'react';

import type { OverlayProps } from '../../../layout/Overlay';
import Overlay from '../../../layout/Overlay';
import {
  OverlayElevation,
  OverlayPlacement,
} from '../../../layout/Overlay/types';
import generateRandomID from '../../../utils/generateRandomID';
import { TooltipArrowSizes, TooltipBaseColors } from '../types';

const DEFAULT_TOOLTIP_OFFSET = 5;

const baseStyles =
  'relative rounded p-3 text-white max-w-slim-modal break-words shadow-md';

const getStylesFromColor = (bgColor: TooltipBaseColors): string => {
  switch (bgColor) {
    case TooltipBaseColors.white:
      return `bg-white ${baseStyles}`;
    default:
      return `bg-black ${baseStyles}`;
  }
};

export type TooltipProps = Pick<
  OverlayProps,
  'children' | 'clickable' | 'id' | 'open' | 'placement' | 'inline'
> & {
  color?: TooltipBaseColors;
  disabled?: boolean;
  role?: string;
  title: ReactNode;
  shouldShowArrow?: boolean;
};

/** @deprecated Use TooltipV2 instead */
const Tooltip = (props: TooltipProps) => {
  const {
    title,
    children,
    id,
    disabled,
    color = TooltipBaseColors.black,
    role = 'tooltip',
    placement = OverlayPlacement.BOTTOM,
    shouldShowArrow = true,
    ...rest
  } = props;

  if (disabled) {
    return <>{children}</>;
  }

  const tooltipId = id || `tooltip-${generateRandomID()}`;

  const arrowOptions = shouldShowArrow
    ? { color, size: TooltipArrowSizes.small }
    : undefined;

  return (
    <Overlay
      {...rest}
      offset={DEFAULT_TOOLTIP_OFFSET}
      placement={placement}
      arrow={arrowOptions}
      id={tooltipId}
      elevation={OverlayElevation.TOOLTIP}
      content={
        <div className={getStylesFromColor(color)} role={role}>
          {title}
        </div>
      }
    >
      {children}
    </Overlay>
  );
};

export default Tooltip;

import { useDispatch, useSelector } from 'react-redux';

import * as actions from '@global/state/actions';
import type { WorkspaceType } from '@global/state/reducers/workspaces/types';
import * as selectors from '@global/state/selectors';
import useGetRequestParams from '@hooks/useGetRequestParams';

type UseUserWorkspaceTypeReturnType = {
  workspaceType: WorkspaceType;
  isFetching: boolean;
  updateWorkspaceType: (workspaceType: WorkspaceType) => Promise<void>;
};

const useUserWorkspaceType = (): UseUserWorkspaceTypeReturnType => {
  const workspaceType = useSelector(selectors.userSettings.selectWorkspaceType);
  const dispatch = useDispatch();
  const requestParams = useGetRequestParams();
  const isFetching = useSelector(
    selectors.userSettings.selectIsFetchingUserSettings,
  );

  const updateWorkspaceType = async (nextWorkspaceType: WorkspaceType) => {
    if (workspaceType === nextWorkspaceType) return;

    dispatch(
      actions.userSettings.updateWorkspaceType({
        workspaceType: nextWorkspaceType,
        requestParams,
      }),
    );
  };

  return {
    workspaceType: workspaceType as WorkspaceType,
    isFetching,
    updateWorkspaceType,
  };
};

export default useUserWorkspaceType;

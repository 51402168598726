import { BORDER_COLOR } from '@coral/constants';

import palette from '../palette';

export default {
  input: {
    padding: '10px',
    background: palette.background.paper,
    border: `1px solid ${BORDER_COLOR}`,
    borderRadius: '5px',
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
      content: '',
    },
    '&:after': {
      borderBottom: 'none',
    },
  },
};

import type { ChangeEvent, PropsWithChildren } from 'react';
import React from 'react';

import { InputSizes } from '../InputContainer/types';

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  indeterminate?: boolean;
  checked?: boolean;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  truncate?: boolean;
  size?: InputSizes;
}

const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
  const {
    children,
    id,
    indeterminate,
    className = '',
    truncate,
    onChange = () => {},
    size = InputSizes.large,
    ...rest
  } = props;
  const fontSize = size === InputSizes.large ? 'text-sm' : 'text-xs';
  const checkboxSize = size === InputSizes.large ? 'h-4 w-4' : 'h-3 w-3';

  return (
    <label
      className={`flex cursor-pointer items-center gap-1 ${
        truncate ? 'truncate' : ''
      } ${fontSize}`}
      htmlFor={id}
    >
      <input
        {...rest}
        onChange={onChange}
        id={id}
        ref={(el: any) => {
          if (el) {
            el.indeterminate = indeterminate;
          }
        }}
        className={`${checkboxSize} flex-none checked:bg-blue-600 active:bg-blue-600 disabled:border-0 disabled:bg-coolGray-200 checked:disabled:bg-coolGray-200 ${className}`}
        type="checkbox"
      />
      {children && (
        <span className={`${truncate ? 'truncate' : ''}`}>{children}</span>
      )}
    </label>
  );
};

export default Checkbox;

import { getItem, setItem } from '@coral/utils/localStorage';
import { emptyObj } from '@core/empty';

import { initialState as initialUserPrefsState } from '../reducers/userPrefs/slice';
import type { RootState } from '../types';

const key = 'user-prefs';

const merge = (
  initialState: RootState | undefined,
  persistedState: Partial<RootState> = emptyObj,
) => {
  return persistedState === emptyObj
    ? initialState
    : { ...initialState, ...persistedState };
};

const calcFinalInitialState = (initialState: RootState | undefined) => {
  const persistedState = getItem<Partial<RootState>>(key, {
    userPrefs: initialUserPrefsState,
  });

  return merge(initialState, persistedState);
};

const persistState =
  () => next => (reducer, initialState: RootState | undefined, enhancer) => {
    if (typeof initialState === 'function' && typeof enhancer === 'undefined') {
      enhancer = initialState;
      initialState = undefined;
    }

    const finalInitialState = calcFinalInitialState(initialState);
    const store = next(reducer, finalInitialState, enhancer);

    store.subscribe(() => {
      const { userPrefs } = store.getState();
      const persistedState = { userPrefs };

      try {
        setItem(key, persistedState);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('PERSIST: Unable to persist state to localStorage:', e);
      }
    });

    return store;
  };

export default persistState;

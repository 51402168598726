import { useSelector } from 'react-redux';

import useCachedStudioInitializer from '@app/StudioPage/hooks/useCachedStudioInitializer';
import { SplitType } from '@core/types';
import { WorkspaceType } from '@global/state/reducers/workspaces/types';
import * as selectors from '@global/state/selectors';
import useIsAnnotationMode from '@hooks/useIsAnnotationMode';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

const usePushNextNav = () => {
  const split = useSelector(selectors.task.selectSplit);
  const { setSplit } = useCachedStudioInitializer();
  const router = useSnorkelRouter();
  const isAnnotationMode = useIsAnnotationMode();

  const pushNextNav = async (
    setModeTo: WorkspaceType | undefined,
    href: string | undefined,
    query?: Record<string, string | string[]> | undefined,
  ): Promise<void> => {
    if (!href) {
      return;
    }

    /**
     * Edge case: We do this check to avoid attempting to
     * load a train split batch upon navigating from a train split batch
     * to development pages
     */
    const shouldChangeSplitToDev =
      (isAnnotationMode && split === SplitType.TRAIN) ||
      (setModeTo === WorkspaceType.DEVELOPMENT && split === SplitType.TRAIN);

    if (shouldChangeSplitToDev) {
      setSplit(SplitType.DEV);
    }

    const route = {
      pathname: href,
      query: {
        ...(shouldChangeSplitToDev ? { split: SplitType.DEV } : {}),
        ...query,
      },
    };

    router.push(route);
  };

  return { pushNextNav };
};

export default usePushNextNav;

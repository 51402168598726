import { useDispatch, useSelector } from 'react-redux';

import JobProgressNotification from '@components/JobProgressNotification';
import * as actions from '@global/state/actions';
import type { JobEntry } from '@global/state/reducers/jobs/types';
import * as selectors from '@global/state/selectors';

/**
 * This component handles all the work of displaying, updating, and closing
 * progress bar notification; users can simply dispatch actions.jobs.addJob
 * and then forget about the job.
 */
const JobProgressNotificationsList = () => {
  const dispatch = useDispatch();
  const jobsState = useSelector(selectors.jobs.selectAll);
  const jobs: JobEntry[] = Object.values(jobsState);

  return (
    <>
      {jobs.map(({ jobUid, runningJob, onCompleteMessage }: JobEntry) => (
        <JobProgressNotification
          key={jobUid}
          uuid={jobUid}
          job={runningJob}
          onComplete={() => dispatch(actions.jobs.removeJob(jobUid))}
          onCompleteMessage={onCompleteMessage}
        />
      ))}
    </>
  );
};

export default JobProgressNotificationsList;

import pipe from '@utils/pipe';

import type { OnboardingState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<OnboardingState> = ({
  onboarding,
}: RootState) => onboarding;

export const selectIsDataAndTaskTypeDataLoading: Selector<
  OnboardingState['isDataAndTaskTypeDataLoading']
> = pipe(
  selectAll,
  ({ isDataAndTaskTypeDataLoading }: OnboardingState) =>
    isDataAndTaskTypeDataLoading,
);

export const selectIsPreprocessingJobsAccordionOpen: Selector<
  OnboardingState['isPreprocessingJobsAccordionOpen']
> = pipe(
  selectAll,
  ({ isPreprocessingJobsAccordionOpen }: OnboardingState) =>
    isPreprocessingJobsAccordionOpen,
);

export const selectPreprocessingJobs: Selector<
  OnboardingState['preprocessingJobs']
> = pipe(
  selectAll,
  ({ preprocessingJobs }: OnboardingState) => preprocessingJobs,
);

export const selectIsSupportedMetricsFetchSuspended: Selector<
  OnboardingState['isSupportedMetricsFetchSuspended']
> = pipe(
  selectAll,
  ({ isSupportedMetricsFetchSuspended }: OnboardingState) =>
    isSupportedMetricsFetchSuspended,
);

export const selectUnknownLabelName: Selector<
  OnboardingState['unknownLabelName']
> = pipe(
  selectAll,
  ({ unknownLabelName }: OnboardingState) => unknownLabelName,
);

export const selectCurrentStepName: Selector<
  OnboardingState['currentStepName']
> = pipe(selectAll, ({ currentStepName }: OnboardingState) => currentStepName);

export const selectCurrentXUids: Selector<OnboardingState['currentXUids']> =
  pipe(selectAll, ({ currentXUids }: OnboardingState) => currentXUids);

export default null;

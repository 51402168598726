import { useEffect } from 'react';

import { getState } from '@global/state/store';

const ContextInspector = () => {
  useEffect(() => {
    (window as any).sf = (window as any).sf ?? {};
    (window as any).sf.getState = getState;

    return () => {
      (window as any).sf = undefined;
    };
  }, []);

  return null;
};

export default ContextInspector;

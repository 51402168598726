import type { SuggestedSearchTemplate } from '@app/StudioPage/components/SearchBar';
import { createReducerSlice } from '@utils/redux/reducer';

import type { SuggestedSearchesState } from './types';

export const initialState: SuggestedSearchesState = {
  showSearchSuggestionChips: false,
  suggestedSearches: undefined,
  isLoadingSuggestions: false,
};

const reducerSlice = createReducerSlice(
  'studioPage/suggestedSearches',
  initialState,
  {
    toggleSearchSuggestionChips(
      state: SuggestedSearchesState,
      showSearchSuggestionChips: boolean,
    ): SuggestedSearchesState {
      return { ...state, showSearchSuggestionChips };
    },
    setSuggestedSearches(
      state: SuggestedSearchesState,
      suggestedSearches: SuggestedSearchTemplate[],
    ): SuggestedSearchesState {
      return { ...state, suggestedSearches: [...suggestedSearches] };
    },
    setIsLoadingSuggestions(
      state: SuggestedSearchesState,
      isLoadingSuggestions: boolean,
    ): SuggestedSearchesState {
      return { ...state, isLoadingSuggestions };
    },
  },
);

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import type { Ref } from 'react';
import React, { forwardRef } from 'react';

import type { ArrowOptions, OverlayElevation } from './types';

import TooltipTriangle from '../../components/tooltips/common/TooltipTriangle';
import combineClasses from '../../utils/combineClasses';
import Portal from '../Portal';

type OverlayContentProps = {
  content: React.ReactNode;
  elevation: OverlayElevation;
  open?: boolean;
  clickable?: boolean;
  arrow?: ArrowOptions;
  arrowRef?: Ref<HTMLDivElement>;
  id?: string;
  shadow?: boolean;
  rounded?: boolean;
};

const OverlayContent = (
  props: OverlayContentProps,
  ref: React.Ref<HTMLSpanElement>,
) => {
  const {
    content,
    open,
    arrowRef,
    clickable,
    arrow,
    elevation,
    id,
    shadow,
    rounded,
  } = props;

  const getBoxShadow = (): string =>
    shadow ? '0 0 10px 10px rgb(0,0,0,0.1)' : '';

  const getOverlaycontentStyles = () =>
    combineClasses(
      open ? 'block' : 'hidden',
      'w-max',
      'tooltip-content',
      'fixed',
      rounded ? 'rounded' : '',
      elevation || '',
    );

  return (
    <Portal>
      <span
        data-cy="overlay-content"
        style={{ boxShadow: getBoxShadow() }}
        ref={ref}
        // @ts-ignore
        onClick={clickable ? (e: any) => e.stopPropagation() : undefined}
        className={getOverlaycontentStyles()}
        id={id}
      >
        {arrow && arrowRef ? (
          <TooltipTriangle
            className={
              arrow.size === 'large' ? `arrow-triangle-large` : `arrow-triangle`
            }
            color={arrow.color}
            ref={arrowRef}
            size={arrow.size}
          />
        ) : null}
        {content}
      </span>
    </Portal>
  );
};

export default forwardRef(OverlayContent);

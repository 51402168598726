import React from 'react';

import type { StackPrimitiveProps } from './__internal__/StackPrimative';
import StackPrimitive from './__internal__/StackPrimative';

export interface VStackProps extends StackPrimitiveProps {}

const VStack = ({ children, ...rest }: Partial<VStackProps>) => (
  <StackPrimitive className="flex-col" {...rest}>
    {children}
  </StackPrimitive>
);

export {
  LayoutAlignment as VStackAlignment,
  LayoutPosition as VStackPosition,
  LayoutSpacing as VStackSpacing,
  LayoutPadding as VStackPadding,
  LayoutTopPadding as VStackTopPadding,
  LayoutBottomPadding as VStackBottomPadding,
  LayoutLeftPadding as VStackLeftPadding,
  LayoutRightPadding as VStackRightPadding,
  LayoutWidth as VStackWidth,
  LayoutHeight as VStackHeight,
  LayoutColor as VStackColor,
  LayoutBorderColor as VStackBorderColor,
  LayoutTopBorderColor as VStackTopBorderColor,
  LayoutBottomBorderColor as VStackBottomBorderColor,
  LayoutLeftBorderColor as VStackLeftBorderColor,
  LayoutRightBorderColor as VStackRightBorderColor,
} from './types';

export default VStack;

import { isEqual } from 'lodash';

import { SnippetType } from '@app/StudioPage/components/StudioSearch/types';
import { GraphCombiner } from '@hooks/useGraph/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type {
  LastSearchConfig,
  SearchData,
  SearchMetrics,
  StudioState,
} from './types';
import { LFTableTab } from './types';

export const initialState: StudioState = {
  overrideShowDocMetrics: undefined,
  searchMetrics: {},
  lastSearchConfigClientId: 0,
  lastSearchConfig: {
    templates: [],
    graph: [GraphCombiner.AND],
    label: undefined,
  },
  searchData: {
    data: { snippet_type: SnippetType.UNKNOWN },
    selectedUid: '',
    isLoading: true,
  },
  isLabelDetailsVisible: false,
  selectedLFTableTab: LFTableTab.ACTIVE,
  isTextViewerLoading: false,
  initialized: false,
};

const reducerSlice = createReducerSlice('studioPage/studio', initialState, {
  setOverrideShowDocMetrics(
    state: StudioState,
    overrideShowDocMetrics: boolean,
  ): StudioState {
    return overrideShowDocMetrics === state.overrideShowDocMetrics
      ? state
      : { ...state, overrideShowDocMetrics };
  },

  updateSearchMetrics(
    state: StudioState,
    searchMetrics: SearchMetrics,
  ): StudioState {
    return searchMetrics === state.searchMetrics
      ? state
      : { ...state, searchMetrics };
  },

  resetSearchMetrics(state: StudioState): StudioState {
    return initialState.searchMetrics === state.searchMetrics
      ? state
      : { ...state, searchMetrics: initialState.searchMetrics };
  },

  updateSearchData(
    state: StudioState,
    searchData: Partial<SearchData>,
  ): StudioState {
    const newSearchData = { ...state.searchData, ...searchData };

    return isEqual(newSearchData, state.searchData)
      ? state
      : { ...state, searchData: newSearchData };
  },

  updateLabelDetailsVisibility(
    state: StudioState,
    isLabelDetailsVisible: boolean,
  ): StudioState {
    return isLabelDetailsVisible === state.isLabelDetailsVisible
      ? state
      : { ...state, isLabelDetailsVisible };
  },

  updateSelectedLfTableTab(
    state: StudioState,
    selectedLFTableTab: LFTableTab,
  ): StudioState {
    return selectedLFTableTab === state.selectedLFTableTab
      ? state
      : { ...state, selectedLFTableTab };
  },

  setLastSearchConfig(
    state: StudioState,
    newLastSearchConfig: Partial<LastSearchConfig>,
  ): StudioState {
    let { lastSearchConfigClientId, lastSearchConfig } = state;
    lastSearchConfigClientId += 1;
    lastSearchConfig = { ...lastSearchConfig, ...newLastSearchConfig };
    state = { ...state, lastSearchConfigClientId, lastSearchConfig };

    return state;
  },

  resetLastSearchConfig(state: StudioState): StudioState {
    let { lastSearchConfigClientId } = state;
    lastSearchConfigClientId += 1;
    const { lastSearchConfig } = initialState;
    state = { ...state, lastSearchConfigClientId, lastSearchConfig };

    return state;
  },

  updateOverrideShowDocMetrics(
    state: StudioState,
    overrideShowDocMetrics: boolean,
  ): StudioState {
    return overrideShowDocMetrics === state.overrideShowDocMetrics
      ? state
      : { ...state, overrideShowDocMetrics };
  },

  setIsTextViewerLoading(
    state: StudioState,
    isTextViewerLoading: boolean,
  ): StudioState {
    return isTextViewerLoading === state.isTextViewerLoading
      ? state
      : { ...state, isTextViewerLoading };
  },

  setIsInitialized(state: StudioState, initialized: boolean): StudioState {
    return initialized === state.initialized
      ? state
      : { ...state, initialized };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

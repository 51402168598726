import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { SuggestedSearchesState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<SuggestedSearchesState> = pipe(
  selectStudioPage,
  ({ suggestedSearches }: StudioPageState) => suggestedSearches,
);

export const selectShowSearchSuggestionChips: Selector<
  SuggestedSearchesState['showSearchSuggestionChips']
> = pipe(
  selectAll,
  ({ showSearchSuggestionChips }: SuggestedSearchesState) =>
    showSearchSuggestionChips,
);

export const selectSuggestedSearches: Selector<
  SuggestedSearchesState['suggestedSearches']
> = pipe(
  selectAll,
  ({ suggestedSearches }: SuggestedSearchesState) => suggestedSearches,
);

export const selectIsLoadingSuggestions: Selector<
  SuggestedSearchesState['isLoadingSuggestions']
> = pipe(
  selectAll,
  ({ isLoadingSuggestions }: SuggestedSearchesState) => isLoadingSuggestions,
);

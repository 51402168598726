import { isEqual } from 'lodash';

import type { PreprocessedDatasourceResponse } from '@api/tdm';
import { createReducerSlice } from '@utils/redux/reducer';

import type { DataState } from './types';

export const initialState: DataState = {
  labels: [],
  field_types: [],
  fieldsMap: {},
  selectedIndex: null,
  total_labeled_count: 0,
  total_dev_count: 0,
  expanded: true,
  initialLoad: false,
  config: {
    span_preview_offset_field: '',
    span_preview_field: '',
    span_text_field: '',
    span_field: '',
    char_start_field: '',
    char_end_field: '',
    context_x_uid_field: '',
  },
  activeDataSources: null,
};

const reducerSlice = createReducerSlice('data', initialState, {
  reset(state: DataState, shouldMerge: boolean): DataState {
    return shouldMerge ? { ...state, ...initialState } : initialState;
  },

  update(state: DataState, delta: Partial<DataState>): DataState {
    const newState = { ...state, ...delta };

    return isEqual(state, newState) ? state : newState;
  },

  setSelectedIndex(state: DataState, selectedIndex: number): DataState {
    return selectedIndex === state.selectedIndex
      ? state
      : { ...state, selectedIndex };
  },

  clearSelectedIndex(state: DataState): DataState {
    return state.selectedIndex === null
      ? state
      : { ...state, selectedIndex: null };
  },

  setExpandedView(state: DataState, expanded: boolean): DataState {
    return expanded === state.expanded ? state : { ...state, expanded };
  },

  setDataConfig(state: DataState, config: DataState['config']): DataState {
    const newState = { ...state, config };

    return isEqual(state, newState) ? state : newState;
  },

  setOffsetFromResponse(
    state: DataState,
    offsetFromResponse: number,
  ): DataState {
    return offsetFromResponse === state.offsetFromResponse
      ? state
      : { ...state, offsetFromResponse };
  },

  setPaginatedData(state: DataState, paginatedData: any): DataState {
    return paginatedData === state.paginatedData
      ? state
      : { ...state, paginatedData };
  },

  setTotalDevCount(state: DataState, newCount: number): DataState {
    return newCount === state.total_dev_count
      ? state
      : { ...state, total_dev_count: newCount };
  },

  setTotalLabeledCount(state: DataState, newCount: number): DataState {
    return newCount === state.total_labeled_count
      ? state
      : { ...state, total_labeled_count: newCount };
  },

  setActiveDataSources(
    state: DataState,
    newActiveDataSources: PreprocessedDatasourceResponse[],
  ): DataState {
    return newActiveDataSources === state.activeDataSources
      ? state
      : { ...state, activeDataSources: newActiveDataSources };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import useRequest from '@hooks/useRequestWithLogging';
import { objectStorageApi } from '@utils/api/serverRequests';

const useGetObjectStorageUrl = () => {
  const request = useRequest();

  const getObjectStorageUrl = async () => {
    const urlResp = await request(
      objectStorageApi.getObjectStorageUrlObjectStorageGet,
      {},
    );

    if (!urlResp) {
      return '';
    }

    const { protocol } = window.location;

    return `${protocol}//${urlResp.url[protocol]}`;
  };

  return { getObjectStorageUrl };
};

export default useGetObjectStorageUrl;

import type { Ref } from 'react';
import React, { forwardRef } from 'react';

import combineClasses from '../../../utils/combineClasses';
import type { TooltipBaseColors } from '../types';
import { TooltipArrowSizes } from '../types';

type TooltipTriangleProps = {
  color: TooltipBaseColors;
  size: TooltipArrowSizes;
  className: string;
};

const bgColors = {
  white: 'before:bg-white',
  black: 'before:bg-black',
};

const TooltipTriangle = (
  props: TooltipTriangleProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { color, size, className } = props;

  const getTooltipTriangleClasses = (): string =>
    combineClasses(
      'absolute',
      size === TooltipArrowSizes.large ? 'w-4.5 h-4.5' : 'w-2 h-2',
      'bg-transparent',
      'before:absolute',
      `before:content-['']`,
      'before:rotate-45',
      size === TooltipArrowSizes.large
        ? 'before:w-4.5 before:h-4.5'
        : 'before:w-2 before:h-2',
      bgColors[color],
      className,
    );

  return (
    <div data-popper-arrow className={getTooltipTriangleClasses()} ref={ref} />
  );
};

export default forwardRef(TooltipTriangle);

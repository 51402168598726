import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import type { DropdownOptionType, DropdownSearchableProps } from '../types';

type UseDropdownFilterProps<ValueType> = {
  combobox?: boolean;
  options: DropdownOptionType<ValueType>[];
  activeValueIndex: number;
  setActiveValueIndex: Dispatch<SetStateAction<number>>;
  searchValue: string;
  groups: string[];
  fallbackIndex: number;
  searchable: DropdownSearchableProps;
};

const useDropdownFilter = <ValueType>(
  props: UseDropdownFilterProps<ValueType>,
) => {
  const {
    options,
    combobox,
    searchable,
    setActiveValueIndex,
    activeValueIndex,
    searchValue,
    groups,
    fallbackIndex,
  } = props;

  const hasGroups = groups.length > 0;
  const [shouldFilter, setShouldFilter] = useState<boolean>(false);

  let filteredOptions =
    shouldFilter && (!!searchable || combobox)
      ? options.filter(({ title }) =>
          title.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : options;

  useEffect(() => {
    if (activeValueIndex >= filteredOptions.length) {
      setActiveValueIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOptions.length]);

  const [selectedGroup, setSelectedGroup] = useState<string>(
    hasGroups && fallbackIndex >= 0
      ? filteredOptions.find((v, index) => index === fallbackIndex)?.group ||
          groups[0]
      : groups[0],
  );

  const traverseGroup = (index: number) => {
    const nextIndex = groups.indexOf(selectedGroup) + index;

    if (!groups[nextIndex]) {
      return;
    }

    setSelectedGroup(groups[nextIndex]);
  };

  filteredOptions = hasGroups
    ? filteredOptions.filter(({ group }) => group === selectedGroup)
    : filteredOptions;

  return {
    setShouldFilter,
    filteredOptions,
    selectedGroup,
    setSelectedGroup,
    traverseGroup,
  };
};

export default useDropdownFilter;

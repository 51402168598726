import type { PopulateLFsArgs } from '@app/StudioPage/components/LFTable/LFTable.types';
import { emptyList } from '@core/empty';
import type { LF, LFVotesMap } from '@core/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { LFsState } from './types';

export const initialState: LFsState = {
  lfs: emptyList,
  votesMap: {},
  isLoading: true,
  isPopulatingLFs: false,
  populatingLFsConfig: undefined,
  populateLFJobId: '',
  restoreProgressMap: {},
};

const reducerSlice = createReducerSlice('lfs', initialState, {
  updateLFState(state: LFsState, delta: LFsState): LFsState {
    return delta || Object.keys(delta).length > 0
      ? { ...state, ...delta }
      : state;
  },

  clearLFs(state: LFsState): LFsState {
    return state.lfs.length === 0 ? state : { ...state, lfs: emptyList };
  },

  setLFs(state: LFsState, lfs: LF[]): LFsState {
    return lfs === state.lfs ? state : { ...state, lfs };
  },

  addLFs(state: LFsState, lfs: LF[]): LFsState {
    const isLFNotLoaded = (lf: LF) => {
      const lfAlreadyLoaded = (currentLF: LF) => lf.uid === currentLF.uid;

      return !state.lfs.some(lfAlreadyLoaded);
    };

    return { ...state, lfs: [...state.lfs, ...lfs.filter(isLFNotLoaded)] };
  },

  removeLFs(state: LFsState, uids: number[]): LFsState {
    return uids.length === 0
      ? state
      : { ...state, lfs: state.lfs.filter(lf => !uids.includes(lf.uid)) };
  },

  updateLFs(state: LFsState, lfs: LF[]): LFsState {
    return lfs.length === 0
      ? state
      : {
          ...state,
          lfs: state.lfs.map(lf => {
            const lfMatchedById = lfs.find(
              ({ uid }: Partial<LF>) => uid === lf.uid,
            );

            return lfMatchedById
              ? {
                  ...lf,
                  metrics: lfMatchedById.metrics ?? {},
                  isMetricsLoading: lfMatchedById.isMetricsLoading,
                }
              : lf;
          }),
        };
  },

  addLF(state: LFsState, lf: LF): LFsState {
    return { ...state, lfs: [lf, ...state.lfs] };
  },

  updateLF(
    state: LFsState,
    { lf, id }: { id: number; lf: Partial<LF> },
  ): LFsState {
    let { lfs } = state;
    lfs = lfs.map(lfCurrent =>
      lfCurrent.id === id ? { ...lfCurrent, ...lf } : lfCurrent,
    );
    state = { ...state, lfs };

    return state;
  },

  setIsLoading(state: LFsState, isLoading: boolean): LFsState {
    return isLoading === state.isLoading ? state : { ...state, isLoading };
  },

  setAutosuggestLFsPopulating(
    state: LFsState,
    populatingLFsConfig: PopulateLFsArgs | undefined,
  ): LFsState {
    return populatingLFsConfig === state.populatingLFsConfig
      ? state
      : {
          ...state,
          populatingLFsConfig,
          isPopulatingLFs: !!populatingLFsConfig,
        };
  },

  setAutosuggestLFsJobId(state: LFsState, populateLFJobId: string): LFsState {
    return populateLFJobId === state.populateLFJobId
      ? state
      : { ...state, populateLFJobId };
  },

  setLFVotes(state: LFsState, votesMap: LFVotesMap): LFsState {
    return votesMap === state.votesMap ? state : { ...state, votesMap };
  },

  resetLFMetrics(state: LFsState): LFsState {
    return state.lfs.length === 0
      ? state
      : { ...state, lfs: state.lfs.map((lf: LF) => ({ ...lf, metrics: {} })) };
  },

  setRestoreProgress(
    state: LFsState,
    { lfUid, percent }: { lfUid: number; percent: number },
  ): LFsState {
    return {
      ...state,
      restoreProgressMap: { ...state.restoreProgressMap, [lfUid]: percent },
    };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import type { JobState } from '@api/tdm';
import useCachedRequest from '@hooks/useCachedRequest';
import useGlobalPreferences from '@hooks/useGlobalPreferences';
import useNodeId from '@hooks/useNodeId';
import { jobsApi } from '@utils/api/serverRequests';

type UsePollAllJobsProps = {
  jobState?: JobState;
  limit?: number;
  shouldPoll?: boolean;
};

const POLLING_INTERVAL = 3_000;

const usePollAllJobs = ({
  jobState,
  limit,
  shouldPoll = true,
}: UsePollAllJobsProps) => {
  const { savedWorkspace } = useGlobalPreferences();
  const nodeId = useNodeId();

  const { data, isLoading, isValidating } = useCachedRequest(
    jobsApi.listJobsJobsGet,
    {
      taskId: nodeId,
      workspaceId: savedWorkspace,
      state: jobState,
      limit,
    },
    {
      suspendFetch: !nodeId || !shouldPoll,
      useSWROptions: { refreshInterval: shouldPoll ? POLLING_INTERVAL : 0 },
    },
  );

  return { jobs: data?.jobs, isLoading: isLoading || !!isValidating };
};

export default usePollAllJobs;

import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { PreprocessedDataState } from './types';

const selectAll: Selector<PreprocessedDataState> = state =>
  state.preprocessedData;

export const selectPreprocessedData: Selector<
  PreprocessedDataState['preprocessedData']
> = pipe(selectAll, ({ preprocessedData }) => preprocessedData);

export const selectIsLoading: Selector<boolean> = pipe(
  selectAll,
  ({ isLoading }) => isLoading,
);

export const selectError: Selector<string | null> = pipe(
  selectAll,
  ({ error }) => error,
);

export default null;

import type { MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';

import AccordionControl from './AccordionControl';

import HStack, { HStackAlignment, HStackSpacing } from '../../../layout/HStack';
import combineClasses from '../../../utils/combineClasses';
import Icon, { Icons } from '../../Icon';
import Textv2, { TextColor, TextSize, Variant } from '../../Textv2';
import type { Studio2AccordionProps } from '../types';

const Studio2Accordion = ({
  summary,
  children,
  control,
  onClick,
  subtext,
  open,
  className,
  ...props
}: Studio2AccordionProps) => {
  /**
   * Done to control state of icon rotation
   */
  const [boolHack, setBoolHack] = useState<boolean>(open || false);

  useEffect(() => {
    if (typeof open === 'boolean') {
      setBoolHack(open);
    }
  }, [open]);

  const onAccordionClick: MouseEventHandler<HTMLDetailsElement> = e => {
    setBoolHack(v => !v);

    if (onClick) {
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      onClick();
    }
  };

  return (
    <details open={open} {...props}>
      <summary
        onClick={onAccordionClick}
        className={combineClasses(
          'flex cursor-pointer items-center justify-between border-b border-t p-3 focus:outline-none',
          open ? 'border-transparent bg-coolGray-200' : 'bg-coolGray-100',
          className,
        )}
        data-cy={`${props['data-cy']}-summary`}
      >
        <HStack alignment={HStackAlignment.CENTER} spacing={HStackSpacing.s}>
          <Icon
            name={Icons.CHEVRON__RIGHT}
            className={boolHack ? 'rotate-90' : ''}
          />
          <Textv2 variant={Variant.subtitle} size={TextSize.medium}>
            {summary}
          </Textv2>
        </HStack>
        <div>
          <Textv2
            variant={Variant.mono}
            size={TextSize.medium}
            color={TextColor.POSITIVE}
          >
            {subtext}
          </Textv2>
        </div>
      </summary>
      {control ? <AccordionControl>{control}</AccordionControl> : null}
      <div className="box-border bg-coolGray-100">{children}</div>
      <div className="box-border h-2 bg-coolGray-200" />
    </details>
  );
};

export default Studio2Accordion;

import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import type { TextSize } from '@coral/components/Text';

export type AccordionDetailHTMLProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDetailsElement>,
  HTMLDetailsElement
>;

export enum AccordionTheme {
  Default = 'Default',
  Studio2 = 'Studio2',
  Onboarding = 'Onboarding',
  OnboardingInner = 'OnboardingInner',
}

type BaseAccordionProps = {
  children: ReactNode;
  divideSummary?: boolean;
  open?: boolean;
  'data-cy'?: string;
  onClick?: VoidFunction;
  className?: string;
};

export type Studio2AccordionProps = BaseAccordionProps & {
  subtext?: string;
  control?: ReactNode;
  summary: string;
};

export type OnboardingAccordionProps = Omit<BaseAccordionProps, 'open'> & {
  isLoading?: boolean;
  tooltipTitle?: string;
  withHeaderSpinner?: boolean;
  isDisabled?: boolean;
  summary: string;
  summaryStatus?: ReactNode;
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dataCy?: string;
};

export type OnboardingInnerAccordionProps = BaseAccordionProps & {
  summary: string;
  isDisabled?: boolean;
  hasBorder?: boolean;
  summaryTextSize?: TextSize.s | TextSize.l;
};

export type DefaultAccordionProps = BaseAccordionProps & {
  summary: ReactNode;
  enablePadding?: boolean;
  iconStart?: boolean;
};

export type AccordionProps =
  | (DefaultAccordionProps & {
      theme?: AccordionTheme.Default;
    })
  | (Studio2AccordionProps & {
      theme?: AccordionTheme.Studio2;
      subtext?: string;
    })
  | (OnboardingAccordionProps & {
      theme?: AccordionTheme.Onboarding;
    })
  | (OnboardingInnerAccordionProps & {
      theme?: AccordionTheme.OnboardingInner;
    });

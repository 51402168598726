import pipe from '@utils/pipe';

import type { WorkspacesState, WorkspaceType } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<WorkspacesState> = ({
  workspaces,
}: RootState) => workspaces;

export const selectType: Selector<WorkspaceType> = pipe(
  selectAll,
  ({ type }: WorkspacesState) => type,
);

export const selectAdminWorkspaces: Selector<
  WorkspacesState['adminWorkspaces']
> = pipe(selectAll, ({ adminWorkspaces }: WorkspacesState) => adminWorkspaces);

export const selectWorkspaces: Selector<WorkspacesState['workspaces']> = pipe(
  selectAll,
  ({ workspaces }: WorkspacesState) => workspaces,
);

export const selectSelectedWorkspace: Selector<
  WorkspacesState['selectedWorkspace']
> = pipe(
  selectAll,
  ({ selectedWorkspace }: WorkspacesState) => selectedWorkspace,
);

export default null;

import type { ReactNode } from 'react';

import combineClasses from '@coral/utils/combineClasses';

type DialogFooterProps = {
  children: ReactNode;
  hideBorder?: boolean;
  justifyBetween?: boolean;
};

const DialogFooter = ({
  children,
  hideBorder,
  justifyBetween,
}: DialogFooterProps) => {
  const classNames = combineClasses(
    'mx-4 flex pt-4 pb-6',
    hideBorder ? '' : 'border-t',
    justifyBetween ? 'justify-between' : 'justify-end',
  );

  return <div className={classNames}>{children}</div>;
};

export default DialogFooter;

import type { AnnotationState } from '@global/state/reducers/annotation/types';
import { createReducerSlice } from '@utils/redux/reducer';

export const initialState: AnnotationState = {
  annotationDataviewerCurrAnnotationUid: null,
  isSplitViewOpen: false,
  selectedAnnotatorUid: null,
};

const reducerSlice = createReducerSlice('annotation', initialState, {
  setAnnotationDataviewerCurrAnnotationUid(
    state: AnnotationState,
    annotationDataviewerCurrAnnotationUid: number | null,
  ) {
    return { ...state, annotationDataviewerCurrAnnotationUid };
  },
  setIsSplitViewOpen(state: AnnotationState, isSplitViewOpen: boolean) {
    return { ...state, isSplitViewOpen };
  },
  setSelectedAnnotatorUid(
    state: AnnotationState,
    selectedAnnotatorUid: number | null,
  ) {
    return { ...state, selectedAnnotatorUid };
  },
});

export const { reducer, actionCreators } = reducerSlice;

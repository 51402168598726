import type { PropsWithChildren } from 'react';

import OptionalTooltip from './tooltips/OptionalTooltip';

import { OverlayPlacement } from '../layout/Overlay/types';
import combineClasses from '../utils/combineClasses';

export enum SpinnerColor {
  WHITE = 'white',
  BLACK = 'black',
}

const spinnerColorVariants = {
  white: 'border-white',
  black: 'border-black',
};

type SpinnerProps = PropsWithChildren<{
  title?: string;
  color?: SpinnerColor;
  dataCy?: string;
}>;

const Spinner = ({
  children,
  title,
  color = SpinnerColor.BLACK,
  dataCy = 'loading-spinner',
}: SpinnerProps) => {
  const spinnerClasses = combineClasses(
    'h-3',
    'w-3',
    'animate-spin',
    'rounded-full',
    'border',
    spinnerColorVariants[color],
    'border-r-transparent',
  );

  return (
    <OptionalTooltip placement={OverlayPlacement.TOP} title={title}>
      <div className="flex items-center gap-2" data-cy={dataCy}>
        <div className={spinnerClasses} />
        {children || null}
      </div>
    </OptionalTooltip>
  );
};

export default Spinner;

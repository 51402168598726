import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import type { UserSettingsJson } from '@api/tdm';
import type { UserSettings } from '@global/state/reducers/userSettings/slice';
import getWorkspaceFromFeaturesAndPath from '@global/state/reducers/workspaces/getWorkspaceFromFeaturesAndPath';
import type { WorkspaceType } from '@global/state/reducers/workspaces/types';
import * as selectors from '@global/state/selectors';
import useCurrentPath from '@hooks/useCurrentPath';
import useGetRequestParams from '@hooks/useGetRequestParams';
import { getCustomColorsForFE } from '@utils/getCustomColors';

const useSettingsResolver = () => {
  const requestParams = useGetRequestParams();
  const supportedFeatures = useSelector(selectors.auth.selectSupportedFeatures);
  const currentPath = useCurrentPath();

  const settingsResolver = useCallback(
    (result: UserSettingsJson) => {
      const {
        custom_colors: customColors,
        workspace_type: workspaceType,
        studio_preferences: studioPreferences,
        ...settings
      } = result || {};

      // Do a snake to camel case transforming here, if necessary
      const nextSettings: UserSettings = {
        ...settings,
        ...(customColors
          ? { custom_colors: getCustomColorsForFE(customColors) }
          : {}),
        ...(requestParams.application_uid
          ? {
              workspace_type: getWorkspaceFromFeaturesAndPath(
                supportedFeatures,
                currentPath,
                workspaceType as WorkspaceType | undefined,
              ),
            }
          : {}),
        // Trim studio_preferences if request does not contain application_uid
        // or node_uid, since we are not in Studio
        ...(studioPreferences &&
        requestParams.application_uid &&
        requestParams.node_uid
          ? { studio_preferences: studioPreferences }
          : {}),
      };

      return nextSettings;
    },
    [
      currentPath,
      requestParams.application_uid,
      requestParams.node_uid,
      supportedFeatures,
    ],
  );

  return { settingsResolver };
};

export default useSettingsResolver;

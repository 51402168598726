import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import useGetLocalStorageKey from '@hooks/useGetLocalStorageKey';

const useCandidateStateSelected = () => {
  const dispatch = useDispatch();
  const stateKey = useGetLocalStorageKey('selected-context-spans');

  const selected = useSelector(
    selectors.userPrefs.selectCandidateSelected(stateKey),
  );

  const setSelected = useCallback(
    stateUpdater => {
      dispatch(
        actions.userPrefs.setCandidateSelected({
          stateKey,
          value:
            stateUpdater instanceof Function
              ? stateUpdater(selected)
              : stateUpdater,
        }),
      );
    },
    [dispatch, stateKey, selected],
  );

  return { selected, setSelected };
};

export default useCandidateStateSelected;

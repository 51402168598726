import type { GetPreviewResponse } from '@api/studio';
import type { PreprocessedDataState } from '@global/state/reducers/preprocessedData/types';
import { createReducerSlice } from '@utils/redux/reducer';

export const initialState: PreprocessedDataState = {
  preprocessedData: null,
  isLoading: false,
  error: null,
};

const reducerSlice = createReducerSlice('preprocessedData', initialState, {
  setPreprocessedData: (
    state: PreprocessedDataState,
    preprocessedData: GetPreviewResponse | null,
  ) => {
    return { ...state, preprocessedData };
  },
  setIsLoading: (state: PreprocessedDataState, isLoading: boolean) => {
    return { ...state, isLoading };
  },
  setError: (state: PreprocessedDataState, error: string | null) => {
    return { ...state, error };
  },
});

export const { reducer, actionCreators } = reducerSlice;

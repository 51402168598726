import { emptyList, emptyObj } from '@core/empty';
import type { RunningJobs } from '@core/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { DatasetsState, PayloadSetDatasetJob } from './types';

export const initialState: DatasetsState = {
  jobs: emptyObj,
  datasources: emptyList,
};

const reducerSlice = createReducerSlice('datasets', initialState, {
  setJobs(state: DatasetsState, jobs: DatasetsState['jobs']): DatasetsState {
    return {
      ...state,
      jobs,
    };
  },

  updateJobs(
    state: DatasetsState,
    payload: Record<string, RunningJobs>,
  ): DatasetsState {
    return {
      ...state,
      jobs: {
        ...state.jobs,
        ...payload,
      },
    };
  },

  clearJobs(state: DatasetsState): DatasetsState {
    return {
      ...state,
      jobs: emptyObj,
    };
  },

  addOrUpdateDatasetJob(
    state: DatasetsState,
    { datasetUid, jobUid, job }: PayloadSetDatasetJob,
  ): DatasetsState {
    return {
      ...state,
      jobs: {
        ...state.jobs,
        [datasetUid]: {
          ...state.jobs[datasetUid],
          [jobUid]: {
            ...state.jobs[datasetUid]?.[jobUid],
            ...job,
          },
        },
      },
    };
  },

  clearDatasetJobs(state: DatasetsState, datasetUid: string): DatasetsState {
    const filteredJobs = Object.keys(state.jobs)
      .filter(key => key !== datasetUid)
      .reduce<Record<string, RunningJobs>>(
        (acc, key) => ({ ...acc, [key]: state.jobs[key] }),
        emptyObj,
      );

    return { ...state, jobs: filteredJobs };
  },

  setDatasources(
    state: DatasetsState,
    datasources: DatasetsState['datasources'],
  ): DatasetsState {
    return {
      ...state,
      datasources,
    };
  },
});
export const { actionTypes, actionCreators, reducer } = reducerSlice;

export default null;

import type { ReactNode } from 'react';
import React from 'react';

import Icon, { Icons } from '../Icon';

type DialogHeaderProps = {
  children: ReactNode;
  onClose: VoidFunction;
  id?: string;
  className?: string;
};

const DialogHeader = ({
  children,
  onClose,
  id,
  className,
}: DialogHeaderProps) => {
  return (
    <div
      id={id}
      className={`flex items-center justify-between px-6 py-8 ${
        className || ''
      }`}
    >
      <span className="flex items-center text-base text-gray-800">
        {children}
      </span>
      <button
        type="button"
        onClick={onClose}
        data-cy="close-dialog"
        title="Close dialog"
      >
        <Icon name={Icons.X} />
      </button>
    </div>
  );
};

export default DialogHeader;

import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { StudioState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<StudioState> = pipe(
  selectStudioPage,
  ({ studio }: StudioPageState) => studio,
);

export const selectOverrideShowDocMetrics: Selector<
  StudioState['overrideShowDocMetrics']
> = pipe(
  selectAll,
  ({ overrideShowDocMetrics }: StudioState) => overrideShowDocMetrics,
);

export const selectSearchData: Selector<StudioState['searchData']> = pipe(
  selectAll,
  ({ searchData }: StudioState) => searchData,
);
export const selectSearchDataData: Selector<StudioState['searchData']['data']> =
  pipe(selectSearchData, ({ data }: StudioState['searchData']) => data);
export const selectSearchDataIsLoading: Selector<
  StudioState['searchData']['isLoading']
> = pipe(
  selectSearchData,
  ({ isLoading }: StudioState['searchData']) => isLoading,
);
export const selectSearchDataSelectedUid: Selector<
  StudioState['searchData']['selectedUid']
> = pipe(
  selectSearchData,
  ({ selectedUid }: StudioState['searchData']) => selectedUid,
);

export const selectSearchMetrics: Selector<StudioState['searchMetrics']> = pipe(
  selectAll,
  ({ searchMetrics }: StudioState) => searchMetrics,
);
export const selectSearchMetricsClassDistribution: Selector<
  StudioState['searchMetrics']['class_distribution']
> = pipe(
  selectSearchMetrics,
  ({ class_distribution }: StudioState['searchMetrics']) => class_distribution,
);

export const selectLastSearchConfigClientId: Selector<
  StudioState['lastSearchConfigClientId']
> = pipe(
  selectAll,
  ({ lastSearchConfigClientId }: StudioState) => lastSearchConfigClientId,
);
export const selectLastSearchConfig: Selector<StudioState['lastSearchConfig']> =
  pipe(selectAll, ({ lastSearchConfig }: StudioState) => lastSearchConfig);
export const selectLastSearchConfigTemplates: Selector<
  StudioState['lastSearchConfig']['templates']
> = pipe(
  selectLastSearchConfig,
  ({ templates }: StudioState['lastSearchConfig']) => templates,
);
export const selectLastSearchConfigGraph: Selector<
  StudioState['lastSearchConfig']['graph']
> = pipe(
  selectLastSearchConfig,
  ({ graph }: StudioState['lastSearchConfig']) => graph,
);
export const selectLastSearchConfigLabel: Selector<
  StudioState['lastSearchConfig']['label']
> = pipe(
  selectLastSearchConfig,
  ({ label }: StudioState['lastSearchConfig']) => label,
);

export const selectIsLabelDetailsVisible: Selector<
  StudioState['isLabelDetailsVisible']
> = pipe(
  selectAll,
  ({ isLabelDetailsVisible }: StudioState) => isLabelDetailsVisible,
);

export const selectSelectedLFTableTab: Selector<
  StudioState['selectedLFTableTab']
> = pipe(
  selectAll,
  ({ selectedLFTableTab }: StudioState) => selectedLFTableTab,
);

export const selectIsTextViewerLoading: Selector<
  StudioState['isTextViewerLoading']
> = pipe(
  selectAll,
  ({ isTextViewerLoading }: StudioState) => isTextViewerLoading,
);

export const selectIsInitialized: Selector<StudioState['initialized']> = pipe(
  selectAll,
  ({ initialized }: StudioState) => initialized,
);

export default null;

import type { LabelColorObject as LabelColorForDatabase } from '@api/tdm';
import type { LabelColor } from '@coral/utils/styles/labelColors';

export const getCustomColorsForFE = (customColors: LabelColorForDatabase[]) =>
  customColors.reduce(
    (
      acc: LabelColor[],
      {
        label_text_color: labelTextColor,
        label_background_color: labelBackgroundColor,
      }: LabelColorForDatabase,
    ) => {
      if (!(labelTextColor && labelBackgroundColor)) {
        return acc;
      }

      return [
        ...acc,
        {
          labelTextColor,
          labelBackgroundColor,
        },
      ];
    },
    [],
  );

export const getCustomColorsForDatabase = (
  customColorArray: LabelColor[] = [],
) =>
  customColorArray.reduce(
    (
      acc: LabelColorForDatabase[],
      {
        labelTextColor: label_text_color,
        labelBackgroundColor: label_background_color,
      }: LabelColor,
    ) => {
      if (!(label_text_color && label_background_color)) {
        return acc;
      }

      return [
        ...acc,
        {
          label_text_color,
          label_background_color,
        },
      ];
    },
    [],
  );

import * as Portal from '@radix-ui/react-portal';
import type { CSSProperties, PropsWithChildren } from 'react';
import React, { useState } from 'react';

import ConfirmCloseModal from './ConfirmCloseModal';

import useOnEscape from '../../hooks/useOnEscape';

// eslint-disable-next-line import/no-cycle

const DEFAULT_MODAL_WIDTH = 600;

type ModalItemProps = {
  width: number;
  height?: CSSProperties['height'];
};

type ModalProps = {
  onClose: VoidFunction;
  children: React.ReactNode;
  confirmClose?: { text: string };
  isOpen?: boolean;
  flexes?: boolean;
  hasBackdrop?: boolean;
  dataCy?: string;
  shouldKeepModalMounted?: boolean; // Keep modal mounted when open is false
  shouldUsePortal?: boolean;
} & Partial<ModalItemProps>;

const PortalWrapper = ({
  shouldUsePortal,
  children,
}: PropsWithChildren<{ shouldUsePortal: boolean }>) => {
  return shouldUsePortal ? (
    <Portal.Root>{children}</Portal.Root>
  ) : (
    <>{children}</>
  );
};

/**
 * Renders a modal with an overlay. Closes upon clicking on cancel button, the overlay, or escape key.
 *
 * Caller is responsible for clearing state after submit. When the open prop is passed, <Modal/> will persist state
 * when closed by toggling CSS to avoid rerenders that would clear the state.
 * @param props
 * @returns <Modal>
 */
const Modal = ({
  onClose,
  confirmClose,
  children,
  width,
  height,
  isOpen = true,
  flexes,
  hasBackdrop = true,
  dataCy,
  shouldKeepModalMounted = false,
  shouldUsePortal = false,
}: ModalProps) => {
  const [confirmCloseModalVisible, setConfirmCloseModalVisible] =
    useState<boolean>(false);

  const handleClose = () => {
    if (confirmClose) {
      setConfirmCloseModalVisible(true);

      return;
    }

    onClose();
  };

  const handleConfirmClose = () => {
    setConfirmCloseModalVisible(false);
  };

  useOnEscape(handleClose);

  const modalItemStyles: CSSProperties = {
    width: width || DEFAULT_MODAL_WIDTH,
    maxWidth: width || DEFAULT_MODAL_WIDTH,
    height: height || '',
    maxHeight: '95%',
  };

  return (
    <PortalWrapper shouldUsePortal={shouldUsePortal}>
      {shouldKeepModalMounted || isOpen ? (
        <>
          {confirmClose && confirmCloseModalVisible && (
            <ConfirmCloseModal
              onClose={handleConfirmClose}
              onConfirm={onClose}
              text={confirmClose.text}
            />
          )}
          <div
            className={`fixed left-0 top-0 z-backdrop flex h-full w-full items-center justify-center overflow-y-auto ${
              isOpen ? '' : 'hidden'
            }`}
            data-cy={dataCy ?? 'modal'}
            role="dialog"
          >
            <div
              style={modalItemStyles}
              className={`relative z-dialog overflow-y-auto rounded bg-white shadow-lg ${
                flexes ? 'flex flex-col' : ''
              }`}
              data-cy="modal-item"
            >
              {children}
            </div>
            <div
              className={`fixed left-0 top-0 z-backdrop h-full w-full opacity-70 ${
                hasBackdrop ? 'bg-black' : ''
              }`}
              onClick={handleClose}
              data-cy="modal-backdrop"
            />
          </div>
        </>
      ) : null}
    </PortalWrapper>
  );
};

export default Modal;

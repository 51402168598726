import { SplitType } from '@core/types';

const shouldDisableSplit = ({
  currentSplit,
  isAnnotationMode,
  splits,
}: {
  currentSplit: string | string[] | undefined;
  splits: string[];
  isAnnotationMode: boolean;
}) => {
  if (typeof currentSplit !== 'string') return true;

  if (currentSplit === SplitType.TRAIN && isAnnotationMode) return true;

  if (currentSplit === SplitType.DEV && splits.includes(SplitType.TRAIN)) {
    return false;
  }

  return !splits.includes(currentSplit);
};

export default shouldDisableSplit;

import type { ThemeOverrides } from '../types';
import { ButtonThemes } from '../types';

type FilledButtonStyleProps = {
  theme: ButtonThemes;
  isFilled?: boolean;
  isDisabled?: boolean;
  overrides?: ThemeOverrides;
};

export const getBgColorOnHoverFromTheme = ({
  theme,
  isFilled = false,
  overrides,
}: FilledButtonStyleProps): string => {
  if (!isFilled) return 'hover:bg-gray-100';

  if (overrides?.filled?.hoverBackground)
    return overrides.filled.hoverBackground;

  switch (theme) {
    case ButtonThemes.primary:
      return 'hover:bg-blue-600';
    case ButtonThemes.secondary:
      return 'hover:bg-purple-600';
    default:
      return 'hover:bg-gray-100';
  }
};

const getBgColorFromTheme = ({
  theme,
  isFilled = false,
  overrides,
}: FilledButtonStyleProps): string => {
  if (!isFilled) return '';

  if (overrides?.filled?.background) return overrides.filled.background;

  switch (theme) {
    case ButtonThemes.primary:
      return 'bg-primary';
    case ButtonThemes.secondary:
      return 'bg-secondary';
    case ButtonThemes.default:
      return 'bg-gray-100';
    default:
      return 'bg-white';
  }
};

const getTextColorFromTheme = ({
  theme,
  isFilled = false,
}: FilledButtonStyleProps): string => {
  switch (theme) {
    case ButtonThemes.primary:
      return isFilled ? 'text-white' : 'text-primary';
    case ButtonThemes.secondary:
      return isFilled ? 'text-white' : 'text-secondary';
    default:
      return 'text-gray-900';
  }
};

const getFilled = ({
  isFilled,
  theme,
  isDisabled,
  overrides,
}: FilledButtonStyleProps): string => {
  if (isDisabled) {
    return 'bg-coolGray-100 text-gray-300';
  }

  return [
    getBgColorOnHoverFromTheme({ theme, isFilled, overrides }),
    getBgColorFromTheme({ theme, isFilled, overrides }),
    getTextColorFromTheme({ theme, isFilled, overrides }),
  ].join(' ');
};

export default getFilled;

import { createReducerSlice } from '@utils/redux/reducer';

import type { SimilarityLfBuilderState, SimilarityLfState } from './types';

export const initialState: SimilarityLfBuilderState = {
  lastSearchConfigProperties: {
    hasSimilarityLfTemplate: false,
    hasUnconfiguredSimilarityLf: false,
    isNegated: false,
  },

  similarityLfState: null,
};

const reducerSlice = createReducerSlice(
  'studioPage/similarityLfBuilder',
  initialState,
  {
    updateSimilarityLfState(
      state: SimilarityLfBuilderState,
      similarityLfState: SimilarityLfState | null,
    ): SimilarityLfBuilderState {
      return similarityLfState === state.similarityLfState
        ? state
        : { ...state, similarityLfState };
    },
  },
);

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

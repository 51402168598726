import type { Dispatch } from 'redux';

import type { EmbeddingNodeInfo, ViewConfig } from '@api/tdm';
import updateUserSettingsToBackend from '@global/state/controllers/updateUserSettingsToBackend';
import type { RootState } from '@global/state/types';
import type { UserSettingsRequestParams } from '@hooks/useGetRequestParams';
import { createControllerSlice } from '@utils/redux/controller';
import { createReducerSlice } from '@utils/redux/reducer';
import type { GetState } from '@utils/redux/types';

import type {
  UserSettings,
  UserSettingsState,
} from '../reducers/userSettings/slice';
import { initialState } from '../reducers/userSettings/slice';

const reducerSlice = createReducerSlice('userSettings', initialState, {
  setUserSettings(
    state: UserSettingsState,
    updatedState: Partial<UserSettings>,
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: updatedState,
    };
  },

  setGlobalPreferences(
    state: UserSettingsState,
    globalPreferences: Partial<UserSettings['global_preferences']>,
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        global_preferences: {
          ...state.settings.global_preferences,
          ...globalPreferences,
        },
      },
    };
  },

  setDagManipulationEnabled(
    state: UserSettingsState,
    dagManipulationEnabled: boolean,
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        dag_manipulation_enabled: dagManipulationEnabled,
      },
    };
  },

  setLFPreferences(
    state: UserSettingsState,
    lfPreferences: UserSettings['lf_preferences'],
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        lf_preferences: {
          ...state.settings.lf_preferences,
          ...lfPreferences,
        },
      },
    };
  },

  setModelPreferences(
    state: UserSettingsState,
    modelPreferences: UserSettings['model_preferences'],
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        model_preferences: {
          ...state.settings.model_preferences,
          ...modelPreferences,
        },
      },
    };
  },

  setPlotGroundTruth(
    state: UserSettingsState,
    plotGroundTruth: UserSettings['plot_ground_truth'],
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        plot_ground_truth: plotGroundTruth,
      },
    };
  },

  setStudioPreferences(
    state: UserSettingsState,
    studioPreferences: UserSettings['studio_preferences'],
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        studio_preferences: {
          ...state.settings.studio_preferences,
          ...studioPreferences,
        },
      },
    };
  },

  setStudioViewConfig(
    state: UserSettingsState,
    viewConfig: ViewConfig,
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        studio_preferences: {
          ...state.settings.studio_preferences,
          view_config: {
            ...state.settings?.studio_preferences?.view_config,
            ...viewConfig,
          },
        },
      },
    };
  },

  setWorkspaceType(
    state: UserSettingsState,
    workspaceType: UserSettings['workspace_type'],
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        workspace_type: workspaceType,
      },
    };
  },

  setMTAPreferences(
    state: UserSettingsState,
    mtaPreferences: Partial<UserSettings['mta_preferences']>,
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        mta_preferences: {
          ...state.settings.mta_preferences,
          ...mtaPreferences,
        },
      },
    };
  },

  setIsFetchingUserSettings(
    state: UserSettingsState,
    isFetching: boolean,
  ): UserSettingsState {
    return {
      isFetching,
      settings: state.settings,
    };
  },

  setCustomColors(
    state: UserSettingsState,
    customColors: UserSettings['custom_colors'],
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        custom_colors: customColors,
      },
    };
  },

  setLabelColorScheme(
    state: UserSettingsState,
    labelColorScheme: UserSettings['label_color_scheme'],
  ): UserSettingsState {
    return {
      isFetching: state.isFetching,
      settings: {
        ...state.settings,
        label_color_scheme: labelColorScheme,
      },
    };
  },
});

const { actionCreators: reducerActions } = reducerSlice;

const controllerSlice = createControllerSlice('userSettings', {
  async updateUserSettings(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      updatedState,
      requestParams,
    }: {
      updatedState: Partial<UserSettings>;
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setUserSettings(updatedState));

    // send POST request
    updateUserSettingsToBackend(updatedState, requestParams);
  },
  async updateDagManipulationEnabled(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      dagManipulationEnabled,
      requestParams,
    }: {
      dagManipulationEnabled: boolean;
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setDagManipulationEnabled(dagManipulationEnabled));

    // send POST request
    updateUserSettingsToBackend(
      {
        dag_manipulation_enabled: dagManipulationEnabled,
      },
      requestParams,
    );
  },
  async updateGlobalPreferences(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      globalPreferences,
      requestParams,
    }: {
      globalPreferences: Partial<UserSettings['global_preferences']>;
      requestParams?: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setGlobalPreferences(globalPreferences));

    if (requestParams) {
      // send POST request
      updateUserSettingsToBackend(
        { global_preferences: globalPreferences },
        requestParams,
      );
    }
  },
  async updateLFPreferences(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      lfPreferences,
      requestParams,
    }: {
      lfPreferences: UserSettings['lf_preferences'];
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setLFPreferences(lfPreferences));

    // send POST request
    updateUserSettingsToBackend(
      { lf_preferences: lfPreferences },
      requestParams,
    );
  },
  async updateModelPreferences(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      modelPreferences,
      requestParams,
    }: {
      modelPreferences: UserSettings['model_preferences'];
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setModelPreferences(modelPreferences));

    // send POST request
    updateUserSettingsToBackend(
      { model_preferences: modelPreferences },
      requestParams,
    );
  },
  async updatePlotGroundTruth(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      plotGroundTruth,
      requestParams,
    }: {
      plotGroundTruth: UserSettings['plot_ground_truth'];
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setPlotGroundTruth(plotGroundTruth));

    // send POST request
    updateUserSettingsToBackend(
      { plot_ground_truth: plotGroundTruth },
      requestParams,
    );
  },
  async updateStudioPreferences(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      studioPreferences,
      requestParams,
    }: {
      studioPreferences: UserSettings['studio_preferences'];
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setStudioPreferences(studioPreferences));

    // send POST request
    updateUserSettingsToBackend(
      { studio_preferences: studioPreferences },
      requestParams,
    );
  },
  async updateStudioViewConfig(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      viewConfig,
      requestParams,
    }: {
      viewConfig: ViewConfig;
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setStudioViewConfig(viewConfig));

    // send POST request
    updateUserSettingsToBackend(
      { studio_preferences: { view_config: viewConfig } },
      requestParams,
    );
  },
  async updateWorkspaceType(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      workspaceType,
      requestParams,
    }: {
      workspaceType: UserSettings['workspace_type'];
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setWorkspaceType(workspaceType));

    // send POST request
    updateUserSettingsToBackend(
      { workspace_type: workspaceType },
      requestParams,
    );
  },
  async updateMTAPreferences(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      mtaPreferences,
      requestParams,
    }: {
      mtaPreferences: Partial<UserSettings['mta_preferences']>;
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setMTAPreferences(mtaPreferences));

    // send POST request
    updateUserSettingsToBackend(
      { mta_preferences: mtaPreferences },
      requestParams,
    );
  },
  async updateIsFetchingUserSettings(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    isFetching: boolean,
  ) {
    dispatch(reducerActions.setIsFetchingUserSettings(isFetching));
  },
  async updateCustomColors(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      customColors,
      requestParams,
    }: {
      customColors: UserSettings['custom_colors'];
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setCustomColors(customColors));

    // send POST request
    updateUserSettingsToBackend({ custom_colors: customColors }, requestParams);
  },
  async updateLabelColorScheme(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      labelColorScheme,
      requestParams,
    }: {
      labelColorScheme: UserSettings['label_color_scheme'];
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(reducerActions.setLabelColorScheme(labelColorScheme));

    // send POST request
    updateUserSettingsToBackend(
      { label_color_scheme: labelColorScheme },
      requestParams,
    );
  },

  async updateSelectedEmbeddingInfo(
    dispatch: Dispatch,
    _getState: GetState<RootState>,
    {
      selectedEmbeddingInfo,
      requestParams,
    }: {
      selectedEmbeddingInfo: EmbeddingNodeInfo;
      requestParams: Partial<UserSettingsRequestParams>;
    },
  ) {
    // update state
    dispatch(
      reducerActions.setStudioViewConfig({
        selected_embedding_info: selectedEmbeddingInfo,
      }),
    );

    // send POST request
    updateUserSettingsToBackend(
      {
        studio_preferences: {
          view_config: { selected_embedding_info: selectedEmbeddingInfo },
        },
      },
      requestParams,
    );
  },
});

export const { controller, actionCreators } = controllerSlice;
export const { reducer } = reducerSlice;

export default controllerSlice;

import type { CommentsPopupProps } from '@coral/components/CommentsButton/CommentsPopup';
import type { ModularModelPredictionIndicatorProps } from '@coral/components/Labels/types';
import type { FileData } from '@coral/components/ModularDataView/types';
import type * as CellTypes from '@coral/components/ModularDataView/types/cellTypes';
import type * as PropTypes from '@coral/components/ModularDataView/types/propTypes';
import type { LabelMap, SimplifiedLF, TagProps } from '@coral/types/types';

export const colTypeBoolean = 'col-type/boolean';
export const colTypeCandidateContext = 'col-type/candidate-context';
export const colTypeComments = 'col-type/comments';
export const colTypeEmbeddedTable = 'col-type/embedded-table';
export const colTypeEnrichedPdf = 'col-type/enriched-pdf';
export const colTypeFile = 'col-type/file';
export const colTypeGroundTruth = 'col-type/ground-truth';
export const colTypeHighlighted = 'col-type/highlighted';
export const colTypeHtml = 'col-type/html';
export const colTypeImage = 'col-type/image';
export const colTypeLFVotes = 'col-type/lf-votes';
export const colTypeModelPredictions = 'col-type/model-predictions';
export const colTypeNumber = 'col-type/number';
export const colTypePdf = 'col-type/pdf';
export const colTypeTags = 'col-type/tags';
export const colTypeText = 'col-type/text';
export const colTypeTrainingSetLabels = 'col-type/training-set-labels';
export const colTypeUrl = 'col-type/url';
export const colTypeJson = 'col-type/json';

export type ColType =
  | typeof colTypeBoolean
  | typeof colTypeCandidateContext
  | typeof colTypeComments
  | typeof colTypeEmbeddedTable
  | typeof colTypeEnrichedPdf
  | typeof colTypeFile
  | typeof colTypeGroundTruth
  | typeof colTypeHighlighted
  | typeof colTypeHtml
  | typeof colTypeImage
  | typeof colTypeLFVotes
  | typeof colTypeModelPredictions
  | typeof colTypeNumber
  | typeof colTypePdf
  | typeof colTypeTags
  | typeof colTypeText
  | typeof colTypeTrainingSetLabels
  | typeof colTypeUrl
  | typeof colTypeJson;

export type CoreColSpec = Readonly<{
  type: ColType;
  key: string;
  label: string;
  isSortable: boolean;
  isPrimaryKey: boolean;
  isRendered?: boolean;
}>;

export type NumberColSpec = CoreColSpec &
  Readonly<{ type: typeof colTypeNumber }>;

export type BooleanColSpec = CoreColSpec &
  Readonly<{ type: typeof colTypeBoolean }>;

export type ImageColSpec = CoreColSpec &
  Readonly<{ type: typeof colTypeImage }>;

export type JsonColSpec = CoreColSpec & Readonly<{ type: typeof colTypeJson }>;

export type PdfColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypePdf;
  }>;

export type EnrichedPdfColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeEnrichedPdf;
    uidField: string;
    gtField: string;
    spanTextsModel: PropTypes.SpanTextsModel;
    currLfVotes: PropTypes.CurrentLfVotes;
    labelMap: LabelMap;
  }>;

export type UrlColSpec = CoreColSpec & Readonly<{ type: typeof colTypeUrl }>;

export type GetOnSelectPopupConstructor = (params: {
  uid: string;
  field: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}) => PropTypes.GetOnSelectPopupFnType;

export type TextColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeText;
    getOnSelectPopupConstructor?: GetOnSelectPopupConstructor;
  }>;

export type HtmlColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeHtml;
  }>;

export type FileColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeFile;
    loadFile: (path: string) => Promise<FileData>;
  }>;

export type HighlightedColSpec = CoreColSpec &
  Pick<CellTypes.AnnotationProps, 'labelMap' | 'labelToColorMap'> &
  Readonly<{
    type: typeof colTypeHighlighted;
    getOnSelectPopupConstructor?: GetOnSelectPopupConstructor;
    onHighlightClick: (uid: string) => void;
  }>;

export type GroundTruthColSpec = CoreColSpec &
  CellTypes.AnnotationProps &
  Readonly<{ type: typeof colTypeGroundTruth }>;

export type ModelPredictionsColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeModelPredictions;
    modelPredictionProps?: ModularModelPredictionIndicatorProps;
  }> &
  Pick<CellTypes.AnnotationProps, 'labelMap' | 'labelToColorMap'>;

export type TrainingSetColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeTrainingSetLabels;
    tsUid?: number;
  }> &
  Pick<CellTypes.AnnotationProps, 'labelMap' | 'labelToColorMap'>;

export type LFColSpec = CoreColSpec &
  Readonly<{ type: typeof colTypeLFVotes; lfs: SimplifiedLF[] }>;

export type TagsColSpec = CoreColSpec &
  Readonly<{ type: typeof colTypeTags; tagsProps: TagProps }>;

export type CommentsColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeComments;
    commentsProps: CommentsPopupProps;
  }>;

export type CandidateContextColSpec = CoreColSpec &
  Pick<CellTypes.AnnotationProps, 'labelMap' | 'labelToColorMap'> &
  Omit<EnrichedPdfColSpec, 'type'> &
  Omit<HighlightedColSpec, 'type'> &
  Readonly<{
    type: typeof colTypeCandidateContext;
    selectedSpans: string[];
    showRichDoc: boolean;
  }>;

export type EmbeddedTableColSpec = CoreColSpec &
  Readonly<{
    type: typeof colTypeEmbeddedTable;
    persistentStorageKey: string;
  }>;

export type ColSpec =
  | BooleanColSpec
  | CandidateContextColSpec
  | CommentsColSpec
  | EmbeddedTableColSpec
  | EnrichedPdfColSpec
  | FileColSpec
  | GroundTruthColSpec
  | HighlightedColSpec
  | HtmlColSpec
  | ImageColSpec
  | LFColSpec
  | ModelPredictionsColSpec
  | NumberColSpec
  | PdfColSpec
  | TagsColSpec
  | TextColSpec
  | TrainingSetColSpec
  | UrlColSpec
  | JsonColSpec;

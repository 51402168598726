import { flatten } from 'lodash';
import type { ReactElement, ReactNode } from 'react';
import { Children, isValidElement } from 'react';

import type { DropdownOptionProps, DropdownOptionType } from '../types';

const getOptions = <ValueType>(children: React.ReactNode) => {
  const groups: string[] = [];

  const options: DropdownOptionType<ValueType>[] = Children.toArray(
    children,
  ).reduce((prev: DropdownOptionType<ValueType>[], curr: ReactNode) => {
    if (!isValidElement(curr)) return prev;

    // // @ts-ignore
    // if (!['DropdownOption', 'DropdownGroup'].includes(curr.type.name)) {
    //   if (process.env.NODE_ENV !== 'production') {
    //     console.warn(
    //       'Children of "Dropdown" must be of type "DropdownOption" or "DropdownGroup"',
    //     ); // eslint-disable-line no-console
    //   }
    //
    //   return prev;
    // }

    // @ts-ignore
    if (typeof curr.props.title === 'string') {
      return [
        ...prev,
        ...flatten(
          Children.toArray(curr).map((groupItem: ReactNode) => {
            if (!isValidElement(groupItem)) {
              return [];
            }

            groups.push(curr.props.title);

            return groupItem.props.children.map(
              ({
                props: childProps,
              }: ReactElement<DropdownOptionProps<ValueType>>) => ({
                value: childProps.value,
                title: childProps.children,
                group: curr.props.title,
                isDisabled: childProps?.isDisabled,
                style: childProps?.style,
                className: childProps?.className,
              }),
            );
          }),
        ),
      ];
    }

    const {
      props: { value, children: title, isDisabled, className, style },
    } = curr as ReactElement<DropdownOptionProps<ValueType>>;

    return [...prev, { value, title, isDisabled, className, style }];
  }, []);

  return {
    options,
    groups,
  };
};

export default getOptions;

import type { NextPageContext } from 'next';
import type { CSSProperties, ReactElement, ReactNode, RefObject } from 'react';

import type {
  APIKey as APIKeyType,
  TagType as ApiTagType,
  ApplicationConfig,
  ApplicationType,
  ConfusionMatrixResponse,
  CreateModelNodeHierarchyPayload,
  DataModality,
  GetClarityMatrixResponse,
  GetCurrentUserResponse,
  Industry,
  InputFieldSpec,
  JobInfo,
  LicenseConfig,
  ModelInfo,
  NodeConfig as NodeConfigType,
  PreprocessedDatasourceResponse,
  UserSettingsJson,
} from '@api/tdm';
import type { DataConnector, DataConnectorConfig } from '@api/tdm/api';
import type { AnalysisTabs } from '@app/AnalysisPage/types';
import type { HighlightConfig } from '@coral/components/Highlight/types';
import {
  HighlightStatus,
  HighlightVariant,
} from '@coral/components/Highlight/types';
import type { InputSizes } from '@coral/components/InputContainer/types';
import type {
  CustomOptionsInputProps,
  FilterInfo,
  ProcessorInput,
  ProcessorInputs,
  VoteType,
} from '@coral/components/ModularDataView/types';
import { FilterTypes } from '@coral/components/ModularDataView/types';
import type { TextObject } from '@coral/layout/VirtualizedText/types';
import type { Nominal } from '@coral/types/nominal';
import type { MultiLabelClass, RawMultiLabelMap } from '@coral/types/types';
import { VoteStatus } from '@coral/types/types';
import type { GraphStructure } from '@hooks/useGraph/types';
import type { SupportedFeatures } from '@utils/supportedFeatures';
import type { TaskTypes } from '@utils/task_types';

export { FilterTypes, HighlightStatus, HighlightVariant, VoteStatus };

export type { TextObject };

export type {
  CustomOptionsInputProps,
  FilterInfo,
  ProcessorInput,
  ProcessorInputs,
};

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum LabelSpace {
  SINGLE = 'SingleLabelSpace',
  MULTI_LABEL = 'MultiLabelSpace',
  ANOMALY_DETECTION = 'AnomalyDetectionLabelSpace',
  SEQUENCE = 'SequenceLabelSpace',
}

// Overwrite the BE-generated NodeConfig type because Dict[str, Any] is generated as `object`
// Re: misc_node_info --> The BE uses `Any` because it wants a generic "misc node information" field
// Re: label_space_config --> The BE uses `Any` because it doesn't have validation for the label space config
export type NodeConfig = Omit<
  NodeConfigType,
  'misc_node_info' | 'label_space_config'
> & {
  misc_node_info?: {
    deprecation_reason?: string;
    from_node_uid?: number;
  };
  label_space_config?: {
    cls_name?: LabelSpace;
    kwargs?: {
      label_map?: LabelMap;
      field?: string;
    };
  };
  settings?: {
    default_settings_cls?: string;
  };
};

export type NodeDetails = {
  id: number;
  name: string;
  created_at: string;
  committedModelUid?: number;
  node_config: NodeConfig;
};

export type ClassMetrics = {
  uid?: number | null;
  precision?: number | null;
  est_precision?: number | null;
  recall?: number | null;
  est_recall?: number | null;
  coverage?: number;
  accuracy?: number;
  emp_support?: number;
  count?: number;
  voted?: string;
  class_votes?: { [classLabel: string]: number };
  per_class_metrics?: LFMetrics | null;
};

export type LFMetrics = Record<string, number | ClassMetrics | null>;

export type AppliedLFStats = {
  uid: number;
  name: string;
  metrics: ClassMetrics | null;
  label?: Label;
};

export enum SourceTypes {
  user = 'user',
  aggregation = 'aggregation',
}

export type AggregationSourceMetadata = {
  author_uid: number;
  created_by?: string;
  sources: number[];
};

export type DataSourceConfig = {
  col_types?: {};
  ds_schema_version?: number;
  label_col?: string | null;
  parent_datasource_uid?: number | null;
  path?: string;
  reader_kwargs?: {
    sample?: boolean;
  };
  references?: [];
  uid_col?: string;
};

export type DatasourceMetadata = {
  columns?: Array<string>;
  n_docs?: number;
  n_datapoints?: number;
  size_bytes?: number;
};

export type DataSource = PreprocessedDatasourceResponse;

export interface ReaderKwargs {
  sample: boolean;
}

export type MultiLabelMap = {
  [label: string]: MultiLabelClass;
};

export type LabelStr = string | string[] | undefined | MultiLabelMap;
/** @deprecated This type is not specific. Use the new RawMultiLabelMap. */
export type LabelInt = Record<string | number, number>;
export type Label = number | LabelInt | RawMultiLabelMap;

export type LF = AppliedLFStats & {
  user_uid?: null | number;
  description?: null | number;
  comments?: string[] | null;
  parent_lf_uid?: number | null;
  templates?: Array<TemplateConfig>;
  multipolar_template?: TemplateConfig;
  sequence_multipolar_template?: TemplateConfig;
  id?: number;
  existing?: boolean;
  template_config?: any;
  graph?: GraphStructure | null;
  created?: Date | string | null; // TODO: validate API-returned datestring, then type more strongly
  is_trusted?: boolean;
  needs_manual_recompile?: boolean;
  hasSuggestion?: boolean;
  precision?: number | null;
  est_precision?: number | null;
  recall?: number | null;
  est_recall?: number | null;
  coverage?: number;
  emp_support?: number;
  count?: number;
  voted?: string;
  class_votes?: { [classLabel: string]: number };
  // Client-side only.
  // This is used to determine whether metrics are loading for an LF.
  isMetricsLoading?: boolean;
  per_class_metrics?: LFMetrics | null;
  suggestion_metadata?: {
    algorithm?: string;
    job_id?: string;
    origin?: string;
    version?: string;
  };
};

export type LFs = Array<LF>;

export type LFFilterQuery = {
  pattern: string;
  name: string;
  authors: number[]; // user_uids of authors
  labels: number[]; // label ints
};

export type LFVotes = {
  [lf_uid: number]: VoteStatus | SpanPositions;
};

// The format returned by the server
export type ServerLFVote = {
  x_uid: string;
  lf_uid: number;
  label: Label | SpanPosition;
};

export type LFVotesMap = {
  [x_uid: string]: LFVotes;
};

export type VotedHighlightSpanType = {
  voted: string;
  label: string;
  lfName: string;
};

export type ActiveLF = {
  lf_uid: number;
  node_uid: number;
  parent_lf_uid?: number | null;
  user_uid?: null | number;
  needs_manual_recompile?: boolean;
  description?: null | number;
  config: {
    name?: string;
    uid: number;
    comments?: string[] | null;
    label: Label;
    templates?: Array<TemplateConfig>;
    graph?: GraphStructure | null;
    created?: Date | string | null;
  };
};

export type ActiveLFs = ActiveLF[];

interface GroundTruth {
  label_str: string;
  x_uid: string;
}

export type GroundTruths = Array<GroundTruth>;

export type ActionType = Nominal<string, 'Action'>;
export type Action = {
  type: string | ActionType;
  [x: string]: any;
};

export enum FieldType {
  TrimmedText = 'TrimmedText',
  FreeChoices = 'FreeChoices',
  TextArea = 'TextArea',
  SimpleKeywordField = 'SimpleKeywordField',
  Radio = 'Radio',
  FileToConditionInput = 'FileToConditionInput',
  ChipInput = 'ChipInput',
  PlainText = 'PlainText',
  CustomTimeSeriesInput = 'CustomTimeSeriesInput',
  JSONInput = 'JSONInput',
  MultiSelect = 'MultiSelect',
  FreeMultiChoices = 'FreeMultiChoices',
  Button = 'Button',
  Code = 'Code',
  Checkbox = 'Checkbox',
  File = 'File',
  Choices = 'Choices',
  CustomChoices = 'CustomChoices',
  Number = 'Number',
  Text = 'Text',
  ReactComponent = 'ReactComponent',
  Render = 'Render',
  Boolean = 'Boolean',
  RenderText = 'RenderText',
  CustomMultiChoices = 'CustomMultiChoices',
  DefaultNumberRadio = 'DefaultNumberRadio',
  NumberWithAutotune = 'NumberWithAutotune',
  RefreshableText = 'RefreshableText',
  Regex = 'Regex',
}

export enum Formats {
  CSV = 'csv',
}

export interface SelectField {
  name: string;
  label?: string;
  initial?: any;
  choices?: Array<any>;
  src?: string;
  placeholder?: string;
  size?: 4 | 6 | 12;
  maxValues?: number;
  modalField?: boolean;
  advancedField?: boolean;
  advancedSetting?: boolean;
  tooltip?: string;
  disabled?: boolean;
  marker?: () => ReactElement;
  inputStyle?: CSSProperties;
  betaTag?: boolean;
}

export interface ModalProps {
  modalProps?: boolean;
  modalTitle?: ReactNode;
  modalMessage?: ReactNode;
  modalPrimaryBtnText?: ReactNode;
}

export interface Field extends SelectField, ModalProps {
  type: FieldType;
  defaultValue?: string;
  element?: ReactElement;
  rememberLastValue?: boolean;
  inputSize?: InputSizes;
  error?: string;
  inputProps?: Record<string, any>;
}

export type Operator =
  | '='
  | '!='
  | '<'
  | '<='
  | '>'
  | '>='
  | 'IN'
  | 'NOT IN'
  | 'CONTAINS'
  | 'NOT CONTAINS';

export type SpanOperator = 'MATCHES' | 'CONTAINS' | 'LEFT' | 'RIGHT';

export interface Rule {
  field: string;
  operator: Operator;
  value: string;
}

export interface SpanRule {
  operator: SpanOperator;
  value: string;
}

export type Fields = Array<Field>;

export type TaskCoverageByVersion = [number, number, Date];

export type TaskCoverageByTime = [Date, number];

export type LFPackage = [number, number, Date, string, boolean];

export type LFPackages = Array<LFPackage>;

export type TrainingSet = {
  ts_uid: number;
  pkg_uid: number;
  split: SplitType;
  ts: Date;
  c: number;
  name: string;
  active_status: boolean;
  is_sampled: boolean;
};

export type ClassDistribution = {
  [x: string]: number;
};

export interface LabelDistributions {
  ground_truth: ClassDistribution;
  lfs: ClassDistribution;
  model?: ClassDistribution;
}

export type Metrics = {
  [key: string]: any;
};
export type SplitMetrics = Partial<Record<SplitType, Metrics>>;

export type ModelMetrics = {
  [modelID: string]: SplitMetrics;
};

export interface RunningJob extends Partial<JobInfo> {
  failed?: boolean;
  detail?: any;
  uid: string;
  uuid?: string;
  sub_status?: boolean;
  step?: number;
  framework?: string;
  task_uid?: null | string;
  complete?: boolean;
  message?: string;
}

export interface RunningJobs {
  [jobId: string]: RunningJob;
}

export type Candidate = {
  x_uid?: string;
  context_uid?: number;
  span_field?: string;
  char_start?: number;
  char_end?: number;
  span_text?: string;
  initial_label?: string;
  span_entity?: string;
};

export type Span = {
  char_start: number;
  char_end: number;
  rich_doc_span_start_char_offset?: number;
  rich_doc_span_end_char_offset?: number;
  rich_doc_span_end_word_id?: string;
  span_text?: string;
} & {
  [field: string]: any;
};

export type ContextBody = {
  context_uid: number;
  text?: string;
  criterion?: string;
  ground_truth?: LabelMap | string[] | null;
  __DATAPOINT_UID: string;
} & {
  [field: string]: any;
};

export type EntityMap = {
  span_entity: string;
  __DATAPOINT_UID: string;
};

export type Spans = Span[];

export type Context = {
  context_uid: number;
  context: ContextBody;
  spans: Spans;
  entity?: EntityMap[];
};

export type Flag = Record<string, boolean>;

export type SpanMapObject = {
  span: [number, number];
  classes: Array<string>;
};

export type SpanPosition = number[];

export type SpanPositions = SpanPosition[];

export type SpanPositionsMap = {
  [span: string]: SpanPositions;
};

export enum TransformedAnnotationSpanDetailsType {
  VOTE = 'vote',
}

export type TransformedAnnotatedSpanDetailsType<T = Record<string, string>> = {
  type: TransformedAnnotationSpanDetailsType;
  data: T[];
};

export type TransformedAnnotatedSpan = {
  char_start: number;
  char_end: number;
  class: HighlightVariant;
  field?: string;
  details?: TransformedAnnotatedSpanDetailsType;
};

export type HighlightMetadata = [number, number, Partial<HighlightConfig>];

export type SearchSnippet = {
  field: string;
  // If the application is multi-label, the server returns a MultiLabelMap.
  label: string | MultiLabelMap;
  snippet: string;
  num_matches: number;
  search_matches: Array<[number, number]>;
  is_start: boolean;
  is_end: boolean;
  snippet_start_index: number;
  field_char_length?: number;
  span_start?: number;
  candidate_span?: [number, number];
  sequence_spans?: Array<[number, number, string]>;
  document_uid?: string;
  focus_span?: any;
  span_filter_highlights?: HighlightMetadata[];
};

export type SpanMap = Record<number, TransformedAnnotatedSpan>;

export type UrlMap = Record<number, string>;

export type HighlightedSpansInfo = {
  spanPositions: SpanPosition[];
  spanMap: SpanMap;
  urlMap: UrlMap;
  spanHighlightMap?: HighlightMetadata[];
};

export type Contexts = Array<Context>;

export type ContextResponse = {
  context_fields: string[];
  count: number;
  data: Context[];
};

export enum ExtractionState {
  UNKNOWN = 'Unknown',
  NONE = 'None',
  EMPTY = '',
}

export type ContextGTState = {
  extractions: string[];
  hasExtractions: boolean;
  currentExtractionState: ExtractionState;
};

export type WidgetFields = {
  widget_config: {
    regex_pattern: string;
    field: string;
    keywords: string[];
    case_sensitive: boolean;
    dot_matches_newline: boolean;
    start_end_match_line: boolean;
  };
  widget_type: string;
};

export type TemplateConfig = Record<string, any>;

export type TemplateConfigUpdateOptions = {
  saveLF?: boolean;
  templateTypeChange?: boolean;
};

export type ProcessorKwargs = {
  [key: string]: any;
};
export type Processor = {
  cls: string;
  kwargs?: ProcessorKwargs;
  name?: string;
  op_uid?: number;
  category?: string;
};

export type CustomProcessorConfig = {
  code: {
    _f: string;
  };
  updated_at: string;
  op_uid?: number;
  cls?: string;
};

export type ProcessorFieldsConfig = {
  inputs?: ProcessorInputs;
  fit_inputs?: ProcessorInputs | null;
  description?: string;
  config?: CustomProcessorConfig;
};

export type ProcessorsFieldsConfigMap = {
  [processorType: string]: ProcessorFieldsConfig;
};

export type JobResponse = {
  job_id: string;
};

export type DatasourceAnalysisResponse = {
  allow_generate_uid_col: boolean;
  load_configs: Array<Record<string, any>>;
  split: SplitType;
  datestamp: string;
  column_map: Record<string, string>;
  potential_uid_columns: Array<string>;
};

export enum LabelMode {
  HAND_LABEL = 'hand-label',
  DEFAULT = 'default',
}

export type TagType = ApiTagType;

export type Map<T = any> = {
  [key: string]: T;
};

export type Chunk = {
  start: number;
  end: number;
  splitEnd?: boolean;
  splitStart?: boolean;
  class?: string;
  spans: string[];
};

export type UidObject = { uid: string };

export type LabelColorScheme = UserSettingsJson['label_color_scheme'];

export type LabelMap = {
  [label: string]: number;
};

export type InverseLabelMap = {
  [index: number]: string;
};

export type DataPointToLabelMap = {
  [x_uid: string]: LabelMap;
};

export type ID = number | string;

export type LFBuilderSettings = {
  caseSensitive?: boolean;
  dotMatchesNewline?: boolean;
  regex?: boolean;
  splitKeywords?: boolean;
  startEndMatchLine?: boolean;
  strictLocationMatch?: boolean;
  tokenize?: string; // radio button uses 'true' and 'false' strings
};

export type LFMenuInfo = {
  name: string;
  value: string;
  category: string[];
};
export type LFInfo = {
  template_name: string;
  menu_order_key?: number;
  order_key: number;
  abbreviation: string;
  description: string;
  docs_link: string;
  menu_type: LFMenuInfo;
};

export const ALL_GT = 'all classes';
export const ANY_GT = 'any class';

export enum CommonLFFilterOptions {
  ALL = 'all',
  NONE = 'none',
  ANY = 'any',
  THIS = 'this',
}

export enum FilterLabels {
  GROUND_TRUTH = 'Ground Truth',
  ALL_LFS = 'all',
  ASSIGNEES = 'Assignees',
  NO_LFS = 'none',
  TRAINING_SET = 'Training Set',
  NO_LFS_VOTE = 'No LFs Vote',
  MODEL = 'Model',
  TAG = 'Tag',
  LF = 'Labeling Function',
  ANNOTATIONS = 'Annotations',
}

export type FilterTemplate = {
  filter_type: FilterTypes;
  lf?: CommonLFFilterOptions | string | number;
  voted?: VoteType | string;
  vote_type?: VoteType | string | number;
  annotator?: number;
  operator?: string;
  model_uid?: number;
  ts_uid?: number;
  annotator_uid?: number;
  user_uids?: string | (string | number)[];
  body?: string;
  tag_type_uid?: number;
  cluster_x_uid_hash?: string;
  // lf_config is used to generate a filter for a previewed LF, via vote type THIS
  lf_config?: LF;
};

export type LFsInfo = LFInfo[];
export type FiltersInfo = FilterInfo[];

export type Reference =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | RefObject<HTMLInputElement>
  | null
  | undefined;

export type CustomChoicesOption = {
  value: any;
  label: string;
};
export type QueryParam = string | string[] | number | number[] | undefined;

export type ModelDataSource = {
  datasource_uid: number;
  type: number;
  config: Record<string, any>;
  split: SplitType;
  ds: string;
  is_active: boolean;
  supports_dev: boolean;
  is_stale?: boolean;
};

export type DataSources = DataSource[];

export interface TaskConfig {
  label_map: LabelMap;
  inverse_label_map: InverseLabelMap;

  label_col?: string;
  type?: TaskTypes;
  settings?: {};

  postprocessor_config?: string;
  preprocessor_config?: string;
  has_rich_doc?: boolean;
  seq_task_field?: string;
}

export type Task = {
  name: string;
  task_uid: number;
  user_uid: null | number;
  task_config: TaskConfig;
};

export type TaskDescription = {
  description: string;
};

export type CustomSummary = {
  total_lfs: number;
  total_models: number;
  total_active_dataSources?: number;
};

export interface TaskWithSummary extends Task {
  summary: CustomSummary;
  description?: string;
  models?: Array<ModelInfo>;
  active_dataSources?: DataSource[];
}

export type Dataset = {
  dataset_uid: string;
  name: string;
};

export type SamplerConfig = { strategy: string | null } | null | { SEARCH: [] };

export type Workflow = {
  name: string;
  workflow_uid: number;
  data_schema: Record<string, any>;
};

export type ProcessorConfig = {
  [task_type: string]: ProcessorsFieldsConfigMap;
};

export type ContextTag = {
  x_uid: string;
  entity?: string;
  tag_type_uids: number[];
};

export type ClarityMatrixArray = GetClarityMatrixResponse['matrix'];

export type ConfusionMatrixHistograms = {
  histogram_labels: Array<string>;
  fps: number[];
  fns: number[];
};

export type ConfusionMatrixSortedResponse = {
  gt_labels: Array<string>;
  preds_labels: Array<string>;
  error_counts: number[];
};

export type ConfusionMatrixArray =
  | ConfusionMatrixResponse
  | ConfusionMatrixHistograms
  | ConfusionMatrixSortedResponse;

export type SuggestionMessage = {
  type: string;
  message: string;
};
export type SuggestionMessages = Array<SuggestionMessage>;

export type SuggestionType = {
  msg: string;
  lvl: number;
  lf_uids?: number[];
  page?: string;
  page_options?: null;
  section?: AnalysisTabs;
};

export type SuggestionsMap = {
  [modelId: number]: SuggestionType[];
};

export type CanvasCtx = any;

export enum ChangeValueType {
  integer = 'integer',
  percentage = 'percentage',
}

export enum LFMetricNames {
  VOTED = 'voted',
  LABEL = 'label',
  PRECISION = 'precision',
  EST_PRECISION = 'est_precision',
  EMP_SUPPORT = 'emp_support',
  RECALL = 'recall',
  EST_RECALL = 'est_recall',
  COVERAGE = 'coverage',
  COUNT = 'count',
  DATE = 'created',
  AUTHOR = 'author',
}

export enum LFMetricsText {
  NO_LF_COVERAGE = 'No LF coverage',
  NO_GT = 'No GT',
}

export enum ScreenSizes {
  mobileS = '320px',
  mobileM = '375px',
  mobileL = '425px',
  tablet = '768px',
  laptop = '1024px',
  laptopL = '1440px',
  desktop = '2560px',
}

export const ALL_LF_METRICS_OPTIONS: LFMetricNames[] =
  Object.values(LFMetricNames);

export type DataInstance = any;

export type BaseDataResponseType = {
  data: DataInstance[];
  compressed_data?: Record<string, Array<any>>;
  total_count: number;
  count: number;
  offset: number;
};
export interface DataStateResponse extends BaseDataResponseType {
  context_x_uid_field?: string;
  context_uid_field?: string;
  field_types: string[];
  fields: string[];
  gt_field: string;
  rich_doc_field: string;
  uid_field: string;
  total_index: string[];
  suggest_field?: string;
}

export interface ContextStateResponse extends BaseDataResponseType {
  context_fields: string[];
  entity_fields: string[];
  span_fields: string[];
  span_gt_field: string;
  span_uid_field: string;
}

export type APIKey = APIKeyType;

export interface RichDocBase {
  id: string;
  bbox: string;
}
export interface RichDocWord extends RichDocBase {
  text: string;
}

export interface RichDocLine extends RichDocBase {
  words: RichDocWord[];
}

export interface RichDocPara extends RichDocBase {
  lines: RichDocLine[];
}

export interface RichDocArea extends RichDocBase {
  pars: RichDocPara[];
}

export interface RichDocPage extends RichDocBase {
  areas: RichDocArea[];
  rows: RichDocBase[];
}

export interface RichDoc extends RichDocBase {
  pages: RichDocPage[];
}

export type AccessTokenData = {
  accessToken: string;
  expiry: number;
  user?: GetCurrentUserResponse;
  supportedFeatures: SupportedFeatures;
};

export type SnorkelAppContext = Readonly<
  NextPageContext & {
    accessToken?: string;
  }
>;

export type ApplicationDetails = Readonly<{
  id: number;
  name: string;
  applicationConfig: ApplicationConfig;
  createdAt: string;
  dag: any;
  datasetUid: number;
  workspaceUid: number;
  licenseConfig: LicenseConfig;
}>;

export type ClassLevelMetric = {
  classLabel: string;
  percentage: number;
};

export type ClassLevelMetrics = ClassLevelMetric[];

export enum SplitType {
  DEV = 'dev',
  VALID = 'valid',
  TRAIN = 'train',
  TEST = 'test',
}

export type PerClassMetrics = {
  [label: string]: {
    [metricName: string]: number;
  };
};

export type HierarchyClassificationConfig =
  CreateModelNodeHierarchyPayload['hierarchy_config'];

export enum ComponentViewLayout {
  DEFAULT = 0,
  STUDIO = 1,
}

export enum StudioVersionTheme {
  STUDIO_V1 = 'STUDIO_V1',
  STUDIO_V2 = 'STUDIO_V2',
}

// Keep this consistent with src/python/templates/template.py
export enum TemplateCategory {
  DOCUMENT_BASED = 'document_based',
  SPAN_BASED = 'span_based',
  EXTERNAL_RESOURCES = 'external_resources',
  NUMERIC_BASED = 'numeric_based',
  TABLE_BASED = 'table_based',
  RICH_DOC_BASED = 'rich_doc_based',
  PATTERN_BASED = 'pattern_based',
  SEQUENCE_PATTERN_BASED = 'sequence_pattern_based',
  SQL_BASED = 'sql_based',
  GENERATORS = 'generators',
  CONVERSATION_BASED = 'conversation_based',
  TIMESERIES_BASED = 'timeseries_based',
  NETWORK_BASED = 'network_based',
  CUSTOM_SVM_BASED = 'custom_svm_based',
  RELATIONSHIP_BASED = 'relationship_based',
  IMAGE_BASED = 'image_based',
  OTHER = 'other',

  // Use the CUSTOM category only for custom LF templates added by SDK
  // CUSTOM should not be used by any predefined LF templates
  CUSTOM = 'custom',

  // FE-exclusive categories
  ALL = 'all',
  RECENT = 'recent',
}

export enum DataToUseOption {
  CURRENT_DEV_FILTER = 'Current dev + current filter',
  CURRENT_DEV = 'Current dev',
}

export type ApplicationTemplateMetaData = {
  applicationType?: ApplicationType;
  industryType?: Industry;
  modalities?: DataModality[];
};

export type ApplicationTemplatePartial = {
  id: string;
  name: string;
  imageUrl?: string;
  description: string;
  meta: ApplicationTemplateMetaData;
  inputs?: InputFieldSpec[];
  is_compatible_with_sf_version?: boolean;
};

export interface ApplicationTemplate extends ApplicationTemplatePartial {
  overviewDetails: string;
  operators: string[];
}

export type DataConnectorConfigDictionary = Record<
  DataConnector,
  DataConnectorConfig[] | null
>;

import type { NotificationItem } from '@global/state/reducers/notifications/types';

class NotificationError extends Error {
  notification: NotificationItem;

  constructor(notification: NotificationItem) {
    super(notification.message);
    this.notification = notification;
  }
}

export default NotificationError;

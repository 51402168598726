import * as FullStory from '@fullstory/browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';
import getFlag, { Flags } from '@utils/getFlag';

const UserAnalyticsInitializer = () => {
  const user = useSelector(selectors.auth.selectUser);
  const isFullStoryEnabled = getFlag(Flags.FULL_STORY);

  useEffect(() => {
    if (!isFullStoryEnabled) return;

    const { user_uid, username } = user;

    if (user_uid) {
      FullStory.identify(user_uid.toString(), { displayName: username });
    } else {
      FullStory.identify('');
    }
  }, [isFullStoryEnabled, user]);

  return null;
};

export default UserAnalyticsInitializer;

import type { ReactElement } from 'react';

import type { LabelSchema } from '@api/tdm';
import type { GTConflictIndicatorProps } from '@coral/components/ModularDataView/ModularComponents/GTConflictIndicator';
import type {
  DataRecord,
  PaginationProps,
} from '@coral/components/ModularDataView/types';
import type * as ColumnTypes from '@coral/components/ModularDataView/types/columnTypes';
import type * as FilterTypes from '@coral/components/ModularDataView/types/filterTypes';
import type * as PropTypes from '@coral/components/ModularDataView/types/propTypes';
import type { SortInfo } from '@coral/table/hooks/useTableSorting';
import type * as CoralTypes from '@coral/types/types';

export enum ViewType {
  TABLE = 'table',
  RECORD = 'record',
  SNIPPET = 'snippet',
  DOCUMENT = 'document',
  SINGLE_RESPONSE = 'single_llm_response_view',
  PROMPT = 'prompt',
  RANKING_RESPONSE = 'ranking_llm_response_view',
}

export type BaseView = {
  viewId: string;
  name: string;
  type: ViewType;
};

export type TableView = BaseView & {
  type: ViewType.TABLE;
};

export type RecordView = BaseView & {
  type: ViewType.RECORD;
};

export type SnippetView = BaseView & {
  type: ViewType.SNIPPET;
};

export type DocumentView = BaseView & {
  type: ViewType.DOCUMENT;
};

export type PromptView = BaseView & {
  type: ViewType.PROMPT;
};

export type SingleResponseView = BaseView & {
  type: ViewType.SINGLE_RESPONSE;
  config: {
    columnMapping?: { [key: string]: string };
  };
};

export type RankingResponseView = BaseView & {
  type: ViewType.RANKING_RESPONSE;
  config: {
    columnMapping?: { [key: string]: string };
    rankingLabelSchema: number;
  };
};

export type View =
  | TableView
  | RecordView
  | DocumentView
  | SnippetView
  | SingleResponseView
  | RankingResponseView
  | PromptView;

export type CoreViewProps = Readonly<{
  view: View;
  setView?: (viewId: string) => void;
  filterProps?: {
    filtersInfo?: FilterTypes.FiltersInfo;
    filterConfig?: FilterTypes.FilterConfig;
    onFilterConfigChange?: (newFilterConfig: FilterTypes.FilterConfig) => void;
  };
  toolbarChild?: ReactElement;
  loadingMessage?: string;
  noDataMessage?: string;
  columnControl?: PropTypes.ColumnControlProps;
  cols: ColumnTypes.ColSpec[];
  showSnippetFieldDropdown?: boolean;
  showReviewerModeToggle?: boolean;
  totalMatches?: number;
}>;

export type CollectionViewProps = Readonly<{
  rows: DataRecord[];
  handleRowClick: (row: DataRecord) => void;
  shouldVirtualize?: boolean;
}>;

export type SingleDatapointViewProps = Readonly<{
  row: DataRecord;
  textDirection?: PropTypes.Direction;
  toggleTextDirection?: VoidFunction;
  withAutoAdvance?: boolean;
  toggleWithAutoAdvance?: VoidFunction;
  gtConflictProps?: GTConflictIndicatorProps;
}>;

export type DefaultMultiLabelProps = {
  defaultMultiLabelClass: CoralTypes.MultiLabelClass | undefined;
  onLabelChange: (label: CoralTypes.MultiLabelClass | undefined) => void;
};

export type ReviewerModeProps = Readonly<{
  isReviewerMode?: boolean;
  setIsReviewerMode?: (isReviewerMode: boolean) => void;
}>;

export type AnnotationOptionsProps = {
  defaultMultiLabelProps?: DefaultMultiLabelProps;
  goToFirstUnlabeledDocProps?: {
    goToFirstUnlabeledDoc: VoidFunction;
    isLoadingFirstUnlabeledDoc: boolean;
  };
  reviewerModeProps?: ReviewerModeProps;
};

export type TableViewProps = CoreViewProps &
  CollectionViewProps &
  Readonly<{
    view: TableView;
    onSort?: (sort: SortInfo) => void;
    sortInfo?: SortInfo;
  }>;

export type PaginatedTableViewProps = TableViewProps &
  Readonly<{ pagination: PaginationProps }>;

export type RecordViewProps = CoreViewProps &
  SingleDatapointViewProps &
  AnnotationOptionsProps &
  Readonly<{
    view: RecordView;
    multiHighlightControlProps?: PropTypes.ModularMultiHighlightControlsProps;
    recordViewToolbar?: ReactElement;
    shouldEnableScrollToHighlight?: boolean;
  }>;

export type PaginatedRecordViewProps = RecordViewProps &
  Readonly<{ pagination: PaginationProps }>;

export type SnippetViewProps = CoreViewProps &
  CollectionViewProps &
  Readonly<{
    view: SnippetView;
    snippetField: string;
    setSnippetField: (snippetField: string) => void;
    multiHighlightControlProps?: PropTypes.ModularMultiHighlightControlsProps;
  }>;

export type PaginatedSnippetViewProps = SnippetViewProps &
  Readonly<{ pagination: PaginationProps }>;

export type DocumentViewProps = CoreViewProps &
  SingleDatapointViewProps & {
    view: DocumentView;
    hasRichDoc?: boolean;
    colorSpanDropdownProps?: PropTypes.ColorSpanDropdownProps;
    spanTextsModel: PropTypes.SpanTextsModel;
    gtConflictMap: PropTypes.GTConflictMap;
    updateLabel: (label: string, uids: string[]) => void;
    spanDrawerProps: PropTypes.SpanDrawerControlProps;
  };

export type PaginatedDocumentViewProps = DocumentViewProps &
  Readonly<{ pagination: PaginationProps }>;

export type SingleResponseViewProps = CoreViewProps &
  SingleDatapointViewProps &
  Readonly<{
    view: SingleResponseView;
    recordViewToolbar?: ReactElement;
  }>;

export type PaginatedSingleResponseViewProps = SingleResponseViewProps &
  Readonly<{ pagination: PaginationProps }>;

export type RankingResponseViewProps = CoreViewProps &
  Readonly<{
    textDirection?: PropTypes.Direction;
    toggleTextDirection?: VoidFunction;
    view: RankingResponseView;
    rankingLabelSchema: LabelSchema;
    recordViewToolbar?: ReactElement;
    row: any[];
    annotations: {
      [docId: string]: {
        [labelSchemaId: number]: string;
      };
    };
    onRank: ({
      uid,
      label,
      labelSchemaId,
    }: {
      uid: string;
      labelSchemaId: number;
      label: string;
    }) => void;
  }>;

export type PaginatedRankingResponseViewProps = RankingResponseViewProps &
  Readonly<{ pagination: PaginationProps }>;

export type PromptViewProps = CoreViewProps &
  CollectionViewProps & {
    view: PromptView;
    snippetField: string;
    promptResponseField: string;
    setSnippetField: (snippetField: string) => void;
  };

export type PaginatedPromptViewProps = PromptViewProps &
  Readonly<{ pagination: PaginationProps }>;

export type DataViewProps =
  | PaginatedSnippetViewProps
  | PaginatedTableViewProps
  | PaginatedRecordViewProps
  | PaginatedDocumentViewProps
  | PaginatedSingleResponseViewProps
  | PaginatedRankingResponseViewProps
  | PaginatedPromptViewProps;

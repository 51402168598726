import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';
import useGlobalPreferences from '@hooks/useGlobalPreferences';
import type { WebSocketChannel } from '@hooks/useWebSocketNotfications/types';
import useWebSocketNotifications from '@hooks/useWebSocketNotfications/useWebSocketNotifications';

type NotificationWebSocketHandlerProps = {
  children: ReactNode;
};

/**
 * Top level wrapper that initializes a websocket connection
 * to persist throughout the user's session
 *
 * @returns
 */
const NotificationWebSocketHandler = ({
  children,
}: NotificationWebSocketHandlerProps) => {
  const { initializeNotificationWebSocket } = useWebSocketNotifications();
  const userChannelSocket = useSelector(
    selectors.atoms.selectUserChannelSocket,
  );
  const { enableNotifications } = useGlobalPreferences();

  const userUid = useSelector(selectors.auth.selectUserUid);
  const channel: WebSocketChannel = `user:${userUid}`;

  /**
   * @effect Initialize the websocket connection if one for that channel
   * does not already exist
   * @deps None, runs on mount
   */
  useEffect(() => {
    if (!userChannelSocket && enableNotifications) {
      initializeNotificationWebSocket(channel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableNotifications]);

  return <>{children}</>;
};

export default NotificationWebSocketHandler;

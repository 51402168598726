import React, { Fragment } from 'react';

import type { CustomTitleRender, DropdownOptionType } from './types';

type DropdownEntryButtonText<ValueType> = {
  customTitleRender?: CustomTitleRender<ValueType>;
  selectedOptions: DropdownOptionType<ValueType>[];
  placeholderValue?: string;
};

function DropdownSelectedValues<ValueType>(
  props: DropdownEntryButtonText<ValueType>,
) {
  const { customTitleRender, selectedOptions, placeholderValue } = props;

  if (selectedOptions.length === 0) {
    return <span className="text-gray-400">{placeholderValue || ''}</span>;
  }

  if (customTitleRender) {
    return (
      <>
        {selectedOptions.map(({ title, value }) => (
          <Fragment key={((value as unknown as string) || '').toString()}>
            {customTitleRender(title, value, { selected: false })}
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <span data-cy="selected-dropdown-option">
      {selectedOptions.map(({ title }) => title).join(', ')}
    </span>
  );
}

export default DropdownSelectedValues;

import { PromptTool } from '@api/tdm';
import { PromptDataState } from '@app/StudioPage/components/PromptZone/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { AtomsState } from './types';

export const initialState: AtomsState = {
  promptModelName: null,
  promptTool: PromptTool.Freeform,
  promptState: PromptDataState.NOT_STARTED,
};

const reducerSlice = createReducerSlice('studioPage/atoms', initialState, {
  setPromptModelName(
    state: AtomsState,
    promptModelName: AtomsState['promptModelName'],
  ): AtomsState {
    return promptModelName === state.promptModelName
      ? state
      : { ...state, promptModelName };
  },

  setPromptTool(
    state: AtomsState,
    promptTool: AtomsState['promptTool'],
  ): AtomsState {
    return promptTool === state.promptTool ? state : { ...state, promptTool };
  },

  setPromptState(
    state: AtomsState,
    promptState: AtomsState['promptState'],
  ): AtomsState {
    return promptState === state.promptState
      ? state
      : { ...state, promptState };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import { useDispatch, useSelector } from 'react-redux';

import { PageRegion } from '@components/PageRegion';
import Modal from '@coral/components/Modal';
import DialogContent from '@coral/components/Modal/DialogContent';
import DialogHeader from '@coral/components/Modal/DialogHeader';
import JobsView from '@global/NavigationContainer/NavigationSidebarNav/JobsDialog/JobsView';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';

const JobsDialog = () => {
  const dispatch = useDispatch();

  const isJobsModalOpen = useSelector(selectors.ux.selectIsJobsModalOpen);
  const handleJobModalClose = () =>
    dispatch(actions.ux.setIsJobsModalOpen(false));

  return (
    <>
      <Modal
        height={700}
        width={1200}
        isOpen={isJobsModalOpen}
        onClose={handleJobModalClose}
      >
        <DialogHeader onClose={handleJobModalClose}>
          <span className="text-lg">Jobs Dashboard</span>
        </DialogHeader>
        <DialogContent className="h-full">
          <PageRegion tag="in-app-jobs/dashboard">
            <JobsView isOpen={isJobsModalOpen} />
          </PageRegion>
        </DialogContent>
      </Modal>
    </>
  );
};

export default JobsDialog;

import type { ChartPoint } from 'chart.js';

import { emptyList, emptyObj } from '@core/empty';
import { createReducerSlice } from '@utils/redux/reducer';

import type { PlotState } from './types';

export const initialState: PlotState = {
  drawingMode: false,
  plotFields: [emptyList, emptyList],
  chartPoints: emptyList,
  templateFields: emptyObj,
  plotData: emptyList,
  filteredPlotData: emptyList,
  numericFields: emptyList,
  truncatedNumericFields: emptyList,
  fetchingDatasetOffset: 0,
  fetchComplete: false,
  filteredFetchComplete: true,
  plotMode: null,
  autoConfigurationOptions: emptyList,
  plotLFVotes: emptyObj,
  selectedDataPoints: emptyList,
};

const reducerSlice = createReducerSlice('studioPage/plot', initialState, {
  update(state: PlotState, patch: Partial<PlotState>): PlotState {
    return Object.keys(patch).length === 0 ? state : { ...state, ...patch };
  },

  updateDrawingMode(
    state: PlotState,
    drawingMode: PlotState['drawingMode'],
  ): PlotState {
    return drawingMode === state.drawingMode
      ? state
      : { ...state, drawingMode };
  },

  updatePlotMode(state: PlotState, plotMode: PlotState['plotMode']): PlotState {
    return plotMode === state.plotMode ? state : { ...state, plotMode };
  },

  updateChartFields(
    state: PlotState,
    selectedField: Record<number, string[]> | {},
  ): PlotState {
    const newPlotFields = [...state.plotFields]; // make a copy

    // transform { 0: 'field name', 2: 'another field } to array [['field name'], ['existing field'], ['another field']]
    Object.keys(selectedField).forEach(key => {
      newPlotFields[key] = [...selectedField[key]];
    });

    return { ...state, plotFields: newPlotFields };
  },

  resetChartPoints(state: PlotState): PlotState {
    return state.chartPoints.length === 0
      ? state
      : { ...state, chartPoints: emptyList };
  },

  updateChartPoints(state: PlotState, chartPoints: ChartPoint[]): PlotState {
    // filter real points out from the junk that is chart reference
    return {
      ...state,
      chartPoints: chartPoints.filter(
        (coordinate: ChartPoint) =>
          coordinate &&
          typeof coordinate.x === 'number' &&
          typeof coordinate.y === 'number',
      ),
    };
  },

  resetTemplateFields(state: PlotState): PlotState {
    return emptyObj === state.templateFields
      ? state
      : { ...state, templateFields: emptyObj };
  },

  updateTemplateFields(
    state: PlotState,
    templateFields: PlotState['templateFields'],
  ): PlotState {
    return templateFields === state.templateFields
      ? state
      : { ...state, templateFields };
  },

  updatePlotData(state: PlotState, plotData: PlotState['plotData']): PlotState {
    return plotData === state.plotData ? state : { ...state, plotData };
  },

  updateFilteredPlotData(
    state: PlotState,
    filteredPlotData: PlotState['filteredPlotData'],
  ): PlotState {
    return filteredPlotData === state.filteredPlotData
      ? state
      : { ...state, filteredPlotData };
  },

  updateNumericFields(
    state: PlotState,
    {
      numericFields,
      truncatedNumericFields,
    }: Pick<PlotState, 'numericFields' | 'truncatedNumericFields'>,
  ): PlotState {
    return { ...state, numericFields, truncatedNumericFields };
  },

  updateFetchingDatasetOffset(
    state: PlotState,
    fetchingDatasetOffset: PlotState['fetchingDatasetOffset'],
  ): PlotState {
    return fetchingDatasetOffset === state.fetchingDatasetOffset
      ? state
      : { ...state, fetchingDatasetOffset };
  },

  updateFetchComplete(
    state: PlotState,
    fetchComplete: PlotState['fetchComplete'],
  ): PlotState {
    return fetchComplete === state.fetchComplete
      ? state
      : { ...state, fetchComplete };
  },

  updateFilteredFetchComplete(
    state: PlotState,
    filteredFetchComplete: PlotState['filteredFetchComplete'],
  ): PlotState {
    return filteredFetchComplete === state.filteredFetchComplete
      ? state
      : { ...state, filteredFetchComplete };
  },

  updateAutoConfigurationOptions(
    state: PlotState,
    autoConfigurationOptions: PlotState['autoConfigurationOptions'],
  ): PlotState {
    return autoConfigurationOptions === state.autoConfigurationOptions
      ? state
      : { ...state, autoConfigurationOptions };
  },

  updatePlotLFVotes(
    state: PlotState,
    plotLFVotes: PlotState['plotLFVotes'],
  ): PlotState {
    return plotLFVotes === state.plotLFVotes
      ? state
      : { ...state, plotLFVotes };
  },

  resetSelectedDataPoints(state: PlotState): PlotState {
    return state.selectedDataPoints.length === 0
      ? state
      : { ...state, selectedDataPoints: emptyList };
  },

  updateSelectedDataPoints(
    state: PlotState,
    selectedDataPoints: PlotState['selectedDataPoints'],
  ): PlotState {
    if (selectedDataPoints.length === 0) {
      selectedDataPoints = emptyList;
    }

    return selectedDataPoints === state.selectedDataPoints
      ? state
      : { ...state, selectedDataPoints };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import { useSelector } from 'react-redux';

import MenuButton from '@components/Form/MenuButton';
import Icon, { Icons } from '@coral/components/Icon';
import { VERSION } from '@core/constants';
import SidebarMenu from '@global/NavigationContainer/NavigationSidebarFooter/common/SidebarMenu';
import SidebarMenuItem from '@global/NavigationContainer/NavigationSidebarFooter/common/SidebarMenuItem';
import * as selectors from '@global/state/selectors';
import useUserLogout from '@hooks/useUserLogout';

import ResourcesMenu from './ResourcesMenu';

const UserMenu = () => {
  const isLoggingOut = useSelector(selectors.auth.selectIsLoggingOut);
  const { admin_settings, workspace_admin_settings } = useSelector(
    selectors.auth.selectSupportedFeatures,
  );
  const logout = useUserLogout();

  const shouldShowAdminSettings =
    admin_settings || workspace_admin_settings || false;

  return (
    <SidebarMenu>
      <MenuButton
        menuProps={{
          elevation: 0,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
        }}
        menu={<ResourcesMenu />}
        button={
          <SidebarMenuItem
            data-cy="resources-menu-selector"
            className="flex items-center justify-between gap-4 border-b py-2"
          >
            Resources
            <Icon name={Icons.CHEVRON__RIGHT} />
          </SidebarMenuItem>
        }
      />
      <SidebarMenuItem href="/user/settings" openInNewTab={false}>
        User settings
      </SidebarMenuItem>
      {shouldShowAdminSettings ? (
        <SidebarMenuItem
          className="border-b"
          href="/admin/settings"
          openInNewTab={false}
        >
          Admin settings
        </SidebarMenuItem>
      ) : null}
      {isLoggingOut ? (
        <SidebarMenuItem data-cy="user-menu-logging-out" disabled>
          <div className="text-gray-500">Logging out, please wait...</div>
        </SidebarMenuItem>
      ) : (
        <SidebarMenuItem onClick={logout} data-cy="user-menu-logout">
          Log out
        </SidebarMenuItem>
      )}
      <li className="truncate px-4 py-2 text-xs text-gray-500 ">
        Version: {VERSION}
      </li>
    </SidebarMenu>
  );
};

export default UserMenu;

import { createReducerSlice } from '@utils/redux/reducer';

import type { DeploymentState } from './types';

export const initialState = null as any;

const reducerSlice = createReducerSlice('deployment', initialState, {
  setDeployment(
    _: DeploymentState,
    deployment: DeploymentState,
  ): DeploymentState {
    return deployment;
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

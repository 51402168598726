import capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';

import { AnnotationRoutes, DevelopmentRoutes } from '@core/constants';
import * as selectors from '@global/state/selectors';
import useIsAnnotationMode from '@hooks/useIsAnnotationMode';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

type Route = {
  title: string;
  href: string;
  notificationIndicator?: boolean;
};
const removeUrlParams = (name: string) =>
  name.indexOf('?') !== -1 ? name.split('?')[0] : name;

export type UseBreadcrumbProps = {
  isOnboardingEnabled: boolean;
};

const useBreadcrumb = ({
  isOnboardingEnabled,
}: UseBreadcrumbProps): Route[] => {
  const navigation = useSelector(selectors.navigation.selectNavigation);
  const currentApplicationNavigation = useSelector(
    selectors.navigation.selectCurrentApplicationNavigation,
  );
  const meta = useSelector(selectors.navigation.selectMeta);
  const { asPath } = useSnorkelRouter();
  const isAnnotationMode = useIsAnnotationMode();

  const route = asPath.slice(1);
  const isEvaluateRoute = route.includes('/evaluate');

  if (route === '') return [];
  if (route.match(/applications\/[0-9]+$/)) return [];
  if (route.match(/(user\/settings|admin\/settings)(\?.*)?$/)) return [];
  if (route.match(/nodes\/[0-9]+$/) && isAnnotationMode) return [];
  if (route.match(/nodes\/[0-9]+$/) && isEvaluateRoute) return [];

  const splitRoute = removeUrlParams(route).split('/');

  const routes = splitRoute.map((name, index) => {
    const href = splitRoute.slice(0, index + 1).join('/');
    const title = capitalize(name).replace(/([-_])/g, ' ');

    // we don't show urls that end with applications or overview
    if (href.match(/(applications|overview|annotate|annotation|develop)$/)) {
      return null;
    }

    if (href.match(/^applications\/new\/app$/)) {
      return { href: '', title: 'Applications' };
    }

    if (href.match(/^applications\/new\/app\/[0-9]+$/)) {
      return { href, title: currentApplicationNavigation[0]?.title };
    }

    // we don't show urls that end with applications or overview
    if (href.match(/^applications\/new$/)) {
      return route.match(/^applications\/new\/app/)
        ? null
        : { href, title: 'New application' };
    }

    // example: annotate/231 should return batch name
    if (href.match(/annotate\/[0-9]+$/)) {
      return { href, title: meta.batchName || '' };
    }

    // example: deployments/231 should return deployment name
    if (href.match(/deployments\/[0-9]+$/)) {
      // The deployment page is deprecated, so link back to the current route instead of the deployment page
      return { href: route, title: meta.deploymentName || '' };
    }

    // example: applications/231
    if (href.match(/applications\/[0-9]+$/)) {
      const applicationTitle = currentApplicationNavigation[0]?.title;

      return isOnboardingEnabled
        ? { href: `applications/new/app/${name}`, title: applicationTitle }
        : { href, title: applicationTitle };
    }

    if (href.match(/nodes$/)) return null;

    if (href.match(/evaluate\/[0-9]+$/)) {
      return {
        href,
        title: `Report ID: ${title}`,
      };
    }

    if (href.match(/nodes\/[0-9]+$/)) {
      if (isAnnotationMode) {
        return {
          href: `${href}/${AnnotationRoutes.ANNOTATION}`,
          title: 'Annotate',
        };
      }

      if (isEvaluateRoute) {
        return null;
      }

      return { href: `${href}/${DevelopmentRoutes.DEVELOP}`, title: 'Develop' };
    }

    if (title === 'Lf packages') return { href, title: 'LF Packages' };

    if (title === 'Train') {
      // Do a lazy check to see if flag was passed
      const hasUncommittedModel = JSON.stringify(navigation).includes(
        '"notificationIndicator":true',
      );

      return { href, title, notificationIndicator: hasUncommittedModel };
    }

    /** MTA specific routes start */
    if (href.match(/datasets\/[0-9]+$/)) {
      return {
        href: `datasets?datasetId=${title}&selectedTab=sources`,
        title: meta.datasetName ?? '',
      };
    }

    if (href.match(/datasets\/[0-9]+\/batches$/)) {
      const datasetId = splitRoute[1];

      return {
        href: `datasets?datasetId=${datasetId}&selectedTab=batches`,
        title: 'Batches',
      };
    }

    if (href.match(/datasets\/[0-9]+\/batches\/[0-9]+$/)) {
      const datasetId = splitRoute[1];

      return {
        href: `datasets?datasetId=${datasetId}&selectedTab=batches`,
        title: meta.batchName ?? '',
      };
    }
    /** MTA specific routes end */

    return { href, title };
  });

  return routes.filter(v => !!v) as Route[];
};

export default useBreadcrumb;

import type { Dispatch } from 'redux';

import { controller as userSettingsController } from '@global/state/controllers/userSettingsController';
import { combineControllers } from '@utils/redux/controller';
import type {
  Controller,
  ControllerAction,
  GetState,
} from '@utils/redux/types';

import type { RootState } from './types';

let gControllerMap: Record<string, Controller<RootState>> = {
  userSettings: userSettingsController,
};
let gCombinedController: Controller<RootState> = combineControllers(
  Object.values(gControllerMap),
);

export const addController = (
  key: string,
  controller: Controller<RootState>,
) => {
  // sanity check:
  if (gControllerMap[key]) {
    // eslint-disable-next-line no-console
    console.error('addController: duplicate key =>', key);
  }

  gControllerMap = { ...gControllerMap, [key]: controller };
  gCombinedController = combineControllers(Object.values(gControllerMap));
};

export const rootController = (
  dispatch: Dispatch,
  getState: GetState<RootState>,
  action: ControllerAction<any, any>,
) => {
  gCombinedController(dispatch, getState, action);
};

import type { MouseEvent, ReactElement } from 'react';
import React from 'react';

import type { HorizontalNavItemProps } from './HorizontalFilterItem';

type HorizontalNavProps<T> = {
  title: string;
  value: T;
  onChange: (id: T) => void;
  children: ReactElement<HorizontalNavItemProps<T>>[];
  stopPropagation?: boolean;
};

function HorizontalFilter<T = string>(props: HorizontalNavProps<T>) {
  const { title, value, children, onChange, stopPropagation } = props;

  return (
    <nav className="flex">
      <div className="text-gray-text">{title}</div>
      <div className="flex-1">
        {React.Children.map(
          children,
          (el: ReactElement<HorizontalNavItemProps<T>>) => {
            if (!React.isValidElement(el)) {
              return null;
            }

            const { id, children: content } = el.props;

            if (!content) {
              return null;
            }

            const pId = id as unknown as T;

            const selected = pId === value;
            const className = selected
              ? 'border-b-2 border-primary'
              : 'text-gray-text';

            const onClick = (e: MouseEvent<HTMLButtonElement>) => {
              if (stopPropagation) {
                e.stopPropagation();
                e.nativeEvent.stopPropagation();
                e.preventDefault();
              }

              onChange(pId);
            };

            return (
              <button
                id={`navigation_${id}`}
                type="button"
                aria-current={selected}
                className={`${className} ml-4`}
                onClick={onClick}
              >
                {content}
              </button>
            );
          },
        )}
      </div>
    </nav>
  );
}

export default HorizontalFilter;

import { isEqual } from 'lodash';

import { MIN_DATA_TABLE_ENTRIES } from '@core/constants';
import { createReducerSlice } from '@utils/redux/reducer';

import type { DataFiltersState } from './types';

import { initialFilterConfig } from '../coreFilters/data';

export const initialState: DataFiltersState = {
  sortOptions: {
    column: '',
    order: 'asc',
    random: false,
  },
  filterBasedSortOptions: {},

  config: initialFilterConfig,
  page: 0,
  limit: MIN_DATA_TABLE_ENTRIES,
  showAnnotations: false,

  training_set_uid: undefined,
};

const reducerSlice = createReducerSlice(
  'studioPage/dataFilters',
  initialState,
  {
    update(
      state: DataFiltersState,
      delta: Partial<DataFiltersState>,
    ): DataFiltersState {
      return Object.keys(delta).length === 0 ? state : { ...state, ...delta };
    },

    updateSortOptions(
      state: DataFiltersState,
      sortOptions: DataFiltersState['sortOptions'],
    ): DataFiltersState {
      return isEqual(sortOptions, state.sortOptions)
        ? state
        : { ...state, sortOptions: { ...state.sortOptions, ...sortOptions } };
    },

    updateLimit(state: DataFiltersState, limit: number): DataFiltersState {
      return limit === state.limit ? state : { ...state, limit };
    },

    toggleShowAnnotations(state: DataFiltersState): DataFiltersState {
      let { showAnnotations } = state;
      showAnnotations = !showAnnotations;
      state = { ...state, showAnnotations };

      return state;
    },
  },
);

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import type { SetStateAction } from 'react';

import { SpanTextsModelOptions } from '@coral/components/ModularDataView/types';
import type { Candidate } from '@core/types';
import type { CandidateState } from '@global/state/reducers/candidate/types';
import { createReducerSlice } from '@utils/redux/reducer';

export const initialCandidate: Candidate = {
  x_uid: undefined,
  context_uid: undefined,
  span_field: undefined,
  char_start: undefined,
  char_end: undefined,
  span_text: undefined,
};

export const calcInitialState = (): CandidateState => {
  return {
    candidate: initialCandidate,
    contextFields: [],
    contexts: [],
    currentPage: 1,
    currLfVotes: {},
    lfVotesLoading: false,
    loading: false,
    message: null,
    model: SpanTextsModelOptions.GT,
    spanFields: [],
    totalCount: Infinity,
    uidField: undefined,
  };
};

export const initialState = calcInitialState();

const reducerSlice = createReducerSlice('candidate', initialState, {
  setCandidate: (
    state: CandidateState,
    candidateUpdater: SetStateAction<CandidateState['candidate']>,
  ) => {
    return {
      ...state,
      candidate:
        candidateUpdater instanceof Function
          ? candidateUpdater({ ...state.candidate })
          : candidateUpdater,
    };
  },

  setContextFields: (
    state: CandidateState,
    contextFields: CandidateState['contextFields'],
  ) => {
    return { ...state, contextFields };
  },

  setContexts: (
    state: CandidateState,
    contextsUpdater: SetStateAction<CandidateState['contexts']>,
  ) => {
    return {
      ...state,
      contexts:
        contextsUpdater instanceof Function
          ? contextsUpdater([...state.contexts])
          : contextsUpdater,
    };
  },

  setCurrentPage: (
    state: CandidateState,
    currentPage: CandidateState['currentPage'],
  ) => {
    return { ...state, currentPage };
  },

  setCurrLfVotes: (
    state: CandidateState,
    currLfVotes: CandidateState['currLfVotes'],
  ) => {
    return { ...state, currLfVotes };
  },

  setLfVotesLoading: (
    state: CandidateState,
    lfVotesLoading: CandidateState['lfVotesLoading'],
  ) => {
    return { ...state, lfVotesLoading };
  },

  setLoading: (state: CandidateState, loading: CandidateState['loading']) => {
    return { ...state, loading };
  },

  setMessage: (state: CandidateState, message: CandidateState['message']) => {
    return { ...state, message };
  },

  setModel: (state: CandidateState, model: CandidateState['model']) => {
    return { ...state, model };
  },

  setSpanFields: (
    state: CandidateState,
    spanFields: CandidateState['spanFields'],
  ) => {
    return { ...state, spanFields };
  },

  setTotalCount: (
    state: CandidateState,
    totalCount: CandidateState['totalCount'],
  ) => {
    return { ...state, totalCount };
  },

  setUidField: (
    state: CandidateState,
    uidField: CandidateState['uidField'],
  ) => {
    return { ...state, uidField };
  },

  resetState: () => calcInitialState(),
});

export const { reducer, actionCreators } = reducerSlice;

import { initialState as initialAtomsState } from './atoms/slice';
import { initialState as initialDataFiltersState } from './dataFilters/slice';
import { initialState as initialDocFiltersState } from './docFilters/slice';
import { initialState as initialPlotState } from './plot/slice';
import { initialState as initialSearchInputState } from './searchInput/slice';
import { initialState as initialSimilarityLfBuilderState } from './similarityLfBuilder/slice';
import { initialState as initialStudioState } from './studio/slice';
import { initialState as initialSuggestedSearchesState } from './suggestedSearches/slice';
import type { StudioPageState } from './types';

export const initialState: StudioPageState = {
  atoms: initialAtomsState,
  dataFilters: initialDataFiltersState,
  docFilters: initialDocFiltersState,
  plot: initialPlotState,
  searchInput: initialSearchInputState,
  studio: initialStudioState,
  similarityLfBuilder: initialSimilarityLfBuilderState,
  suggestedSearches: initialSuggestedSearchesState,
};

export default null;

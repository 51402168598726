import { createReducerSlice } from '@utils/redux/reducer';

import type { SearchInputState } from './types';

export const initialState: SearchInputState = {
  selectedLabel: undefined,
};

const reducerSlice = createReducerSlice(
  'studioPage/searchInput',
  initialState,
  {
    update(state: SearchInputState, delta: SearchInputState): SearchInputState {
      return { ...state, ...delta };
    },
  },
);

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import pipe from '@utils/pipe';

import type { AuthState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<AuthState> = ({ auth }: RootState) => auth;

export const selectIsLoggingOut: Selector<AuthState['isLoggingOut']> = pipe(
  selectAll,
  ({ isLoggingOut }: AuthState) => isLoggingOut,
);
export const selectUser: Selector<AuthState['user']> = pipe(
  selectAll,
  ({ user }: AuthState) => user,
);
export const selectUserUid: Selector<AuthState['user']['user_uid']> = pipe(
  selectUser,
  ({ user_uid }: AuthState['user']) => user_uid,
);
export const selectUserName: Selector<AuthState['user']['username']> = pipe(
  selectUser,
  ({ username }: AuthState['user']) => username,
);
export const selectUserRole: Selector<AuthState['user']['role']> = pipe(
  selectUser,
  ({ role }: AuthState['user']) => role,
);
export const selectUserRoles: Selector<AuthState['user']['user_roles']> = pipe(
  selectUser,
  ({ user_roles }: AuthState['user']) => user_roles,
);
export const selectSupportedFeatures: Selector<AuthState['supportedFeatures']> =
  pipe(selectAll, ({ supportedFeatures }: AuthState) => supportedFeatures);
export const selectUserTimezone: Selector<AuthState['user']['timezone']> = pipe(
  selectUser,
  ({ timezone }: AuthState['user']) => timezone,
);

export default null;

import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { DataFiltersState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<DataFiltersState> = pipe(
  selectStudioPage,
  ({ dataFilters }: StudioPageState) => dataFilters,
);

export const selectSortOptions: Selector<DataFiltersState['sortOptions']> =
  pipe(selectAll, ({ sortOptions }: DataFiltersState) => sortOptions);

export const selectSortedColumn: Selector<
  DataFiltersState['sortOptions']['column']
> = pipe(
  selectSortOptions,
  ({ column }: DataFiltersState['sortOptions']) => column,
);

export const selectSortedOrder: Selector<
  DataFiltersState['sortOptions']['order']
> = pipe(
  selectSortOptions,
  ({ order }: DataFiltersState['sortOptions']) => order,
);

export const selectMultiSortedColumn: Selector<
  DataFiltersState['sortOptions']['multiColumns']
> = pipe(
  selectSortOptions,
  ({ multiColumns }: DataFiltersState['sortOptions']) => multiColumns,
);

export const selectMultiSortedOrder: Selector<
  DataFiltersState['sortOptions']['multiOrder']
> = pipe(
  selectSortOptions,
  ({ multiOrder }: DataFiltersState['sortOptions']) => multiOrder,
);

export const selectFilterBasedSortOptions: Selector<
  DataFiltersState['filterBasedSortOptions']
> = pipe(
  selectAll,
  ({ filterBasedSortOptions }: DataFiltersState) => filterBasedSortOptions,
);

export const selectConfig: Selector<DataFiltersState['config']> = pipe(
  selectAll,
  ({ config }: DataFiltersState) => config,
);
export const selectConfigFilters: Selector<
  DataFiltersState['config']['filters']
> = pipe(selectConfig, ({ filters }: DataFiltersState['config']) => filters);

export const selectPage: Selector<DataFiltersState['page']> = pipe(
  selectAll,
  ({ page }: DataFiltersState) => page,
);

export const selectLimit: Selector<DataFiltersState['limit']> = pipe(
  selectAll,
  ({ limit }: DataFiltersState) => limit,
);

export const selectOffset: Selector<number> = pipe(
  selectAll,
  ({ limit, page }: DataFiltersState) => limit * page,
);

export const selectTrainingSetUid: Selector<
  DataFiltersState['training_set_uid']
> = pipe(
  selectAll,
  ({ training_set_uid }: DataFiltersState) => training_set_uid,
);

export const selectShowAnnotations: Selector<
  DataFiltersState['showAnnotations']
> = pipe(selectAll, ({ showAnnotations }: DataFiltersState) => showAnnotations);

export default null;

import type { NotificationType } from '@api/tdm';
import { Icons } from '@coral/components/Icon';
import NoDataMessage from '@coral/components/NoDataMessage';
import IconButton, { IconButtonSizes } from '@coral/prebuilts/IconButton';

import NotificationItem from './NotificationItem';
import type { NotificationAssetUri } from './utils/getNotificationRedirectProps';
import getNotificationRedirectProps from './utils/getNotificationRedirectProps';

import useNotifications from '../../hooks/useNotifications';
import usePushNextNav from '../../hooks/usePushNextNav';

const NotificationsMenu = () => {
  const { pushNextNav } = usePushNextNav();
  const { notifications, resolveNotification, getNotifications } =
    useNotifications();

  const goToPageFromNodeMetadata =
    (notificationType: NotificationType, assetUri: NotificationAssetUri) =>
    () => {
      const { url, workspace } = getNotificationRedirectProps({
        notificationType,
        assetUri,
      });

      pushNextNav(workspace, url);
    };

  return (
    <div className="mt-2 max-h-35h w-100 overflow-y-auto p-4 px-6">
      <div className="flex justify-end">
        <IconButton
          tooltip="Click the refresh icon in order to see if you have new notifications."
          icon={Icons.RESET}
          data-cy="refresh-notifications-button"
          onClick={getNotifications}
          size={IconButtonSizes.Xsmall}
        />
      </div>

      {notifications && notifications.length === 0 && (
        <NoDataMessage
          header="You're caught up"
          subheader="You'll be notified here when someone mentions you or takes an action on work you're involved in."
        />
      )}
      {notifications.map(notification => (
        <NotificationItem
          key={notification.notification_uid}
          notification={notification}
          onClick={goToPageFromNodeMetadata(
            notification.notification_type,
            notification.body.asset_uri,
          )}
          onDismiss={() => resolveNotification(notification.notification_uid)}
        />
      ))}
    </div>
  );
};

export default NotificationsMenu;

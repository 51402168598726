import type { Dispatch, RefObject, SetStateAction } from 'react';
import React, { useEffect } from 'react';

import EmptyDropdownMessage from './EmptyDropdownMessage';

import HorizontalFilter from '../../HorizontalFilter';
import HorizontalFilterItem from '../../HorizontalFilter/HorizontalFilterItem';
import DropdownItem from '../DropdownItem';
import type {
  CustomTitleRender,
  DropdownOptionType,
  DropdownSearchableProps,
  OnChangeSearchValue,
  OnSelectItem,
} from '../types';
import getElementId from '../utils/getElementId';

type DropdownListProps<ValueType> = {
  width?: number;
  id: string;
  activeValueIndex: number;
  filteredOptions: DropdownOptionType<ValueType>[];
  onSelectItem: OnSelectItem;
  customTitleRender?: CustomTitleRender<ValueType>;
  allSelectedValues: Set<ValueType>;
  multiSelect: boolean;
  groups: string[];
  selectedGroup: string;
  containerRef: React.RefObject<HTMLDivElement>;
  setSelectedGroup: Dispatch<SetStateAction<string>>;
  childRef: RefObject<HTMLDivElement>;
  dataCy?: string;
  emptyMessage?: string;
  searchable: DropdownSearchableProps;
  searchValue: string;
  onChangeSearchValue: OnChangeSearchValue;
  disableUnselectedOptions?: boolean;
  maxHeightClass: string;
  itemClassName?: string;
  itemStyle?: React.CSSProperties;
};

function DropdownList<ValueType>(props: DropdownListProps<ValueType>) {
  const {
    id,
    childRef: ref,
    width,
    activeValueIndex,
    filteredOptions,
    multiSelect,
    onSelectItem,
    allSelectedValues,
    dataCy,
    selectedGroup,
    setSelectedGroup,
    customTitleRender,
    emptyMessage,
    containerRef,
    groups,
    searchable,
    searchValue,
    onChangeSearchValue,
    disableUnselectedOptions,
    maxHeightClass,
  } = props;

  const hasGroups = groups.length > 0;
  useEffect(() => {
    // This timeout is here because the component might not have loaded on first render
    // which causes autofocus to not work properly
    setTimeout(async () => {
      if (!ref.current || !containerRef.current) {
        return;
      }

      const { width: parentWidth } =
        containerRef.current.getBoundingClientRect();

      if (!ref.current) {
        return;
      }

      ref.current.style.width = `${width || parentWidth}px`;
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const dropdownProps = {
    ref,
    'data-cy': `dropdown-list-${dataCy || id}`,
    className:
      'bg-white w-full rounded border border-primary z-notifications flex flex-col',
  };

  return (
    // eslint-disable-next-line jsx-a11y/aria-activedescendant-has-tabindex
    <div
      role="listbox"
      tabIndex={-1}
      aria-activedescendant={getElementId(id, activeValueIndex)}
      {...dropdownProps}
    >
      {searchable && (
        <div className="w-full border-b text-xs">
          <input
            data-cy="dropdown-search"
            className="h-full w-full rounded px-3 py-3"
            placeholder={
              (typeof searchable !== 'boolean' && searchable.placeholder) ||
              'Search'
            }
            value={searchValue}
            onChange={onChangeSearchValue}
            // eslint-disable-next-line jsx-a11y/no-autofocus -- needed for plot UX
            autoFocus
          />
        </div>
      )}
      {hasGroups && (
        <div className="w-full border-b py-2 text-xs">
          {/* fix: ch8160 */}
          <HorizontalFilter<string>
            value={selectedGroup}
            stopPropagation
            onChange={setSelectedGroup}
            title=""
          >
            {groups.map(label => (
              <HorizontalFilterItem key={label} id={label}>
                {label}
              </HorizontalFilterItem>
            ))}
          </HorizontalFilter>
        </div>
      )}
      {filteredOptions.length > 0 ? (
        <ul className={`flex-1 overflow-auto ${maxHeightClass}`}>
          {filteredOptions.map(
            ({ value, title, isDisabled, className, style }, index) => {
              const selected = allSelectedValues.has(value);

              return (
                <DropdownItem
                  active={activeValueIndex === index}
                  key={index.toString()}
                  id={getElementId(id, index)}
                  dataCy={getElementId(id, index)}
                  multiSelect={multiSelect}
                  onSelect={() => onSelectItem(value)}
                  selected={selected}
                  hasCustomRender={!!customTitleRender}
                  title={title}
                  disableUnselectedOptions={disableUnselectedOptions}
                  isDisabled={isDisabled}
                  className={className}
                  style={style}
                >
                  {customTitleRender
                    ? customTitleRender(title, value, { selected })
                    : title}
                </DropdownItem>
              );
            },
          )}
        </ul>
      ) : (
        <EmptyDropdownMessage
          hasGroup={hasGroups}
          emptyMessage={emptyMessage}
        />
      )}
    </div>
  );
}

export default DropdownList;

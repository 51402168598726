import * as Select from '@radix-ui/react-select';
import type { ReactNode } from 'react';

type SelectMenuLabelProps = {
  label: string | ReactNode;
};

const SelectMenuLabel = ({ label }: SelectMenuLabelProps) => {
  return (
    <Select.Label className="pl-3 pt-2 text-gray-600">{label}</Select.Label>
  );
};

export default SelectMenuLabel;

import type { AnyAction, Reducer, ReducersMapObject } from 'redux';
import { combineReducers } from 'redux';

import { studioPageReducer } from '@app/StudioPage/state/reducer';
import type { Action } from '@utils/redux/types';

import * as actions from './actions';
import { reducer as userSettingsReducer } from './controllers/userSettingsController';
import { reducer as annotationReducer } from './reducers/annotation/slice';
import { reducer as appDetailsReducer } from './reducers/appDetails/slice';
import { reducer as atomsReducer } from './reducers/atoms/slice';
import { reducer as authReducer } from './reducers/auth/slice';
import { reducer as candidateReducer } from './reducers/candidate/slice';
import { reducer as dataReducer } from './reducers/data/slice';
import { reducer as datasetsReducer } from './reducers/datasets/slice';
import { reducer as deploymentReducer } from './reducers/deployment/slice';
import { reducer as highlightsReducer } from './reducers/highlights/slice';
import { reducer as jobsReducer } from './reducers/jobs/slice';
import { reducer as lfComposerReducer } from './reducers/lfComposer/slice';
import { reducer as lfsReducer } from './reducers/lfs/slice';
import { reducer as lfTableReducer } from './reducers/lfTable/slice';
import { reducer as managerPageReducer } from './reducers/managerPage/slice';
import * as metaActionTypes from './reducers/meta/actionTypes';
import { reducer as navigationReducer } from './reducers/navigation/slice';
import { reducer as nodeDetailsReducer } from './reducers/nodeDetails/slice';
import { reducer as notificationsReducer } from './reducers/notifications/slice';
import { reducer as onboardingReducer } from './reducers/onboarding/slice';
import { reducer as preprocessedDataReducer } from './reducers/preprocessedData/slice';
import { reducer as sandboxReducer } from './reducers/sandbox/slice';
import { reducer as tagsReducer } from './reducers/tags/slice';
import { reducer as taskReducer } from './reducers/task/slice';
import { reducer as userPrefsReducer } from './reducers/userPrefs/slice';
import { reducer as usersReducer } from './reducers/users/slice';
import { reducer as uxReducer } from './reducers/ux/slice';
import { reducer as waveReducer } from './reducers/wave/slice';
import { reducer as workspacesReducer } from './reducers/workspaces/slice';
import type { RootState } from './types';

// --------------------------------------------------------------------
const initialReducersMap: ReducersMapObject<RootState, AnyAction> = {
  appDetails: appDetailsReducer,
  atoms: atomsReducer,
  annotation: annotationReducer,
  auth: authReducer,
  candidate: candidateReducer,
  data: dataReducer,
  datasets: datasetsReducer,
  deployment: deploymentReducer,
  highlights: highlightsReducer,
  jobs: jobsReducer,
  lfComposer: lfComposerReducer,
  lfs: lfsReducer,
  lfTable: lfTableReducer,
  navigation: navigationReducer,
  nodeDetails: nodeDetailsReducer,
  notifications: notificationsReducer,
  onboarding: onboardingReducer,
  preprocessedData: preprocessedDataReducer,
  sandbox: sandboxReducer,
  studioPage: studioPageReducer,
  managerPage: managerPageReducer,
  tags: tagsReducer,
  task: taskReducer,
  userPrefs: userPrefsReducer,
  users: usersReducer,
  userSettings: userSettingsReducer,
  ux: uxReducer,
  wave: waveReducer,
  workspaces: workspacesReducer,
} as ReducersMapObject<RootState, AnyAction>;

let gReducersMap: ReducersMapObject<RootState, AnyAction> = initialReducersMap;
let gCombinedReducers: Reducer<RootState> =
  combineReducers<RootState>(gReducersMap);

// --------------------------------------------------------------------
const handleAddReducers = (
  state: RootState,
  reducersMap: ReducersMapObject<RootState, AnyAction>,
): RootState => {
  state = Object.keys(reducersMap).reduce((s: any, key: string): RootState => {
    if (s[key]) return s;

    const reducerForKey = reducersMap[key];
    const stateForKey = reducerForKey(undefined, actions.meta.init());

    return { ...s, [key]: stateForKey };
  }, state);
  gReducersMap = { ...gReducersMap, ...reducersMap };
  gCombinedReducers = combineReducers(gReducersMap);

  return state;
};

// --------------------------------------------------------------------
export const rootReducer = (
  state: RootState,
  action: Action<any>,
): RootState => {
  if (action.type === metaActionTypes.addReducers) {
    state = handleAddReducers(state, action.payload);
  }

  return gCombinedReducers(state, action);
};

export default rootReducer;

import { NotificationVariant } from '@global/state/reducers/notifications/types';

type NotificationConfig = {
  title: string;
  borderClass: string;
  textClass: string;
};

const configs: Record<NotificationVariant, NotificationConfig> = {
  [NotificationVariant.info]: {
    title: 'Info',
    borderClass: 'border-gray-500',
    textClass: 'text-gray-600',
  },
  [NotificationVariant.warning]: {
    title: 'Warning',
    borderClass: 'border-orange-500',
    textClass: 'text-orange-600',
  },
  [NotificationVariant.success]: {
    title: 'Success',
    borderClass: 'border-green-500',
    textClass: 'text-green-600',
  },
  [NotificationVariant.error]: {
    title: 'Error',
    borderClass: 'border-red-500',
    textClass: 'text-red-600',
  },
  [NotificationVariant.progress]: {
    title: '',
    borderClass: '',
    textClass: '',
  },
};

const getNotificationConfig = (
  variant: NotificationVariant,
): NotificationConfig => {
  return configs[variant] ?? configs[NotificationVariant.info];
};

export default getNotificationConfig;

import { useSelector } from 'react-redux';

import { WorkspaceType } from '@global/state/reducers/workspaces/types';
import * as selectors from '@global/state/selectors';

import useSnorkelRouter from './useSnorkelRouter';

const useIsAnnotationMode = (mode?: WorkspaceType): boolean => {
  const workspaceType = useSelector(selectors.workspaces.selectType);
  const { pathname } = useSnorkelRouter();

  return (
    (mode ?? workspaceType) === WorkspaceType.ANNOTATION ||
    pathname?.match(/(\/annotation|\/annotate|\/batch)/) !== null
  );
};

export default useIsAnnotationMode;

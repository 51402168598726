import { isNil } from 'lodash';
import { useCallback, useRef } from 'react';

import useRequest from '@hooks/useRequestWithLogging';
import { usersApi } from '@utils/api/serverRequests';

const useFetchUser = () => {
  const request = useRequest();
  const refRequest = useRef<ReturnType<typeof useRequest>>(request);
  refRequest.current = request;

  const fetchUser = useCallback(
    async (workspaceUid?: number) => {
      if (isNil(workspaceUid)) return undefined;

      // setIsUserLoading(true);

      const nextUser = await refRequest.current(
        usersApi.getCurrentUserCurrentUserGet,
        { workspaceUid },
      );

      return nextUser;
    },
    [refRequest],
  );

  return fetchUser;
};

export default useFetchUser;

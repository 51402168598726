import { useDispatch } from 'react-redux';

import type { Workspace } from '@api/tdm';
import * as actions from '@global/state/actions';
import useGlobalPreferences from '@hooks/useGlobalPreferences';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

import useFetchUser from './useFetchUser';

const useSetWorkspaces = () => {
  const { savedWorkspace, updateSavedWorkspace } = useGlobalPreferences();
  const dispatch = useDispatch();
  const fetchUser = useFetchUser();
  const { asPath } = useSnorkelRouter();

  const updateWorkspaces = (
    workspaces: Workspace[],
    adminWorkspaces: Workspace[],
  ) => {
    dispatch(actions.workspaces.setWorkspaces(workspaces));
    dispatch(actions.workspaces.setAdminWorkspaces(adminWorkspaces));

    // on an application page, the selected workspace should be
    // the workspace related to the application, no need to ovewrite it
    if (savedWorkspace && asPath && !asPath.startsWith('/applications')) {
      const selectedWorkspace = workspaces.find(
        w => w.workspace_uid === savedWorkspace,
      );

      if (selectedWorkspace) {
        dispatch(actions.workspaces.setSelectedWorkspace(selectedWorkspace));
      }
    }
  };

  const updateSelectedWorkspace = async (workspace: Workspace) => {
    dispatch(actions.workspaces.setSelectedWorkspace(workspace));
    updateSavedWorkspace(workspace.workspace_uid!);

    const currentUser = await fetchUser(workspace.workspace_uid);

    if (currentUser) dispatch(actions.auth.setUser(currentUser));
  };

  return { updateSelectedWorkspace, updateWorkspaces };
};

export default useSetWorkspaces;

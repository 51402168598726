import type { RunningJobs } from '@core/types';
import type { RootState, Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { ManagerPageState } from './types';

export const selectManagerPage: Selector<ManagerPageState> = ({
  managerPage,
}: RootState) => managerPage;

export const selectJobs: Selector<RunningJobs> = pipe(
  selectManagerPage,
  ({ jobs }: ManagerPageState) => jobs,
);

export default null;

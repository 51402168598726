import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { CandidateState } from './types';

const selectCandidateState: Selector<CandidateState> = state => state.candidate;

export const selectCandidate: Selector<CandidateState['candidate']> = pipe(
  selectCandidateState,
  ({ candidate }) => candidate,
);

export const selectContextFields: Selector<CandidateState['contextFields']> =
  pipe(selectCandidateState, ({ contextFields }) => contextFields);

export const selectContexts: Selector<CandidateState['contexts']> = pipe(
  selectCandidateState,
  ({ contexts }) => contexts,
);

export const selectCurrentPage: Selector<CandidateState['currentPage']> = pipe(
  selectCandidateState,
  ({ currentPage }) => currentPage,
);

export const selectCurrLfVotes: Selector<CandidateState['currLfVotes']> = pipe(
  selectCandidateState,
  ({ currLfVotes }) => currLfVotes,
);

export const selectLfVotesLoading: Selector<CandidateState['lfVotesLoading']> =
  pipe(selectCandidateState, ({ lfVotesLoading }) => lfVotesLoading);

export const selectLoading: Selector<CandidateState['loading']> = pipe(
  selectCandidateState,
  ({ loading }) => loading,
);

export const selectMessage: Selector<CandidateState['message']> = pipe(
  selectCandidateState,
  ({ message }) => message,
);

export const selectModel: Selector<CandidateState['model']> = pipe(
  selectCandidateState,
  ({ model }) => model,
);

export const selectSpanFields: Selector<CandidateState['spanFields']> = pipe(
  selectCandidateState,
  ({ spanFields }) => spanFields,
);

export const selectTotalCount: Selector<CandidateState['totalCount']> = pipe(
  selectCandidateState,
  ({ totalCount }) => totalCount,
);

export const selectUidField: Selector<CandidateState['uidField']> = pipe(
  selectCandidateState,
  ({ uidField }) => uidField,
);

export default null;

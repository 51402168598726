import { uniqueId } from 'lodash';
import type { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import HoverableSidebar from '@global/NavigationContainer/HoverableSidebar';
import NavigationSidebar from '@global/NavigationContainer/NavigationSidebar';
import * as selectors from '@global/state/selectors';
import useEffectAbortable from '@hooks/useEffectAbortable';
import useGetWorkspaces from '@hooks/useGetWorkspaces';
import useGlobalPreferences from '@hooks/useGlobalPreferences';
import getFlag, { Flags } from '@utils/getFlag';

import useSetObjectStorageUrlToNavMeta from './hooks/useSetObjectStorageUrlToNavMeta';
import useUserLastNodePageVisited from './hooks/useUserLastNodePageVisited';

type NavigationContainerProps = PropsWithChildren<{}>;

const NavigationContainer = ({ children }: NavigationContainerProps) => {
  const { refreshWorkspaces } = useGetWorkspaces();
  const { savedWorkspace } = useGlobalPreferences();

  const isBetterSidebarEnabled = !!getFlag(Flags.BETTER_SIDEBAR_EXPAND);

  const user = useSelector(selectors.auth.selectUser);

  useEffectAbortable(refreshWorkspaces, [savedWorkspace, user]);
  useUserLastNodePageVisited();
  useSetObjectStorageUrlToNavMeta();

  return (
    <>
      {isBetterSidebarEnabled ? (
        <HoverableSidebar key={uniqueId()} />
      ) : (
        <NavigationSidebar key={uniqueId()} />
      )}
      {children}
    </>
  );
};

export default NavigationContainer;

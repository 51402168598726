/* eslint-disable local-rules/no-layering-violations */
import type { Request, Response } from 'express';
import isNil from 'lodash/isNil';

import {
  ActorsApi,
  ApplyApi,
  ClustersApi,
  CodeLfApi,
  ContextDatasetApi,
  DatasetApi,
  DownloadApi,
  FiltersApi,
  HighlightApi,
  IndexApi,
  LabelsApi,
  LabelsAutofillApi,
  LfSuggesterApi,
  MarginSuggestionsApi,
  MemoryProfilingApi,
  PackageApi,
  PreviewApi,
  PromptApi,
  SearchApi,
  LfsApi as StudioLfsApi,
  SuggestionsApi,
  SummaryApi,
  VisualizationsApi,
} from '@api/studio/api';
import type { BaseAPI as StudioBaseAPI } from '@api/studio/base';
import { Configuration as StudioConfiguration } from '@api/studio/configuration';
import {
  AnalysisApi,
  AnnotationApi,
  AnnotationsApi,
  ApiKeysApi,
  ApplicationsApi,
  ApplicationTemplatesApi,
  BatchesApi,
  BlocksApi,
  CommentsApi,
  CpuProfilingApi,
  DataConnectorApi,
  DataConnectorConfigApi,
  DataConnectorRolesRouterApi,
  DatasetBatchApi,
  DatasetCommentsApi,
  DatasetsApi,
  DatasetTagTypesApi,
  DatasetViewsApi,
  DatasourcesApi,
  DefaultApi,
  DefaultsApi,
  DeployApi,
  DevXsApi,
  EmbeddingsApi,
  EvaluationApi,
  EventsApi,
  ExternalLlmConfigsApi,
  FmIntegrationsApi,
  GroundTruthApi,
  InvitesApi,
  JobsApi,
  LabelMatricesApi,
  LabelSchemasApi,
  LfPackagesApi,
  LfsApi,
  LicenseCheckApi,
  LogsApi,
  MetricsApi,
  ModelRegistryApi,
  ModelsApi,
  MonitoringApi,
  NodesApi,
  NotebooksApi,
  NotebookSettingsApi,
  NotificationsApi,
  ObjectStorageApi,
  OperatorsApi,
  PopulatorsApi,
  PostprocessorsApi,
  PredictionApiApi,
  PredictionsApi,
  ProgressReportApi,
  PromptBuilderApi,
  PromptsApi,
  RbacResourceRolesRouterApi,
  ResourceRecencyRouterApi,
  SourcesApi,
  SsoApi,
  StaticAssetApi,
  StaticAssetUploadMethodApi,
  StaticAssetUploadMethodRolesRouterApi,
  TagMapApi,
  TagsApi,
  TaskDatasourcesApi,
  TasksApi,
  TmpFilesApi,
  TrainingSetsApi,
  TransferApi,
  UsersApi,
  UserSettingsApi,
  WarmStartApi,
  WorkflowsApi,
  WorkspaceApi,
} from '@api/tdm/api';
import type { BaseAPI as TDMBaseAPI } from '@api/tdm/base';
import { Configuration as TDMConfiguration } from '@api/tdm/configuration';
import { calcBasePath } from '@utils/api/calcBasePath';
import getHeaders from '@utils/getHeaders';
import publicRuntimeConfig from '@utils/publicRuntimeConfig';

import generateAPIUrl from './generateApiUrl';
import init from './init';

export const calcRequestPath = (url: string): string => {
  const basePath = calcBasePath();
  if (url.startsWith(`${basePath}/api/`)) return url.slice(basePath.length + 5);
  if (url.startsWith('/api/')) return url.slice(5);
  if (url.startsWith('/')) return url.slice(1);

  return url;
};

export const makeServerRequest =
  (host: string | undefined) =>
  async (path: string, data?: any, method?: string, headers?: any) => {
    const requestPath = calcRequestPath(path);
    const url = `${host}/${requestPath}`;
    const options = init(data, method, { headers });

    const isEmptyPostRequestBody =
      options.method === 'POST' &&
      Number.parseInt(headers['content-length'], 10) === 0;

    const result = await fetch(url, {
      ...options,
      headers: (headers && typeof headers === 'object'
        ? getHeaders(headers as Record<string, string>)
        : headers) as HeadersInit,
      body: isEmptyPostRequestBody ? null : options.body,
    });

    return result;
  };

export const studioUrl =
  publicRuntimeConfig.STUDIO_API_URL || process.env.STUDIO_API_URL;
export const tdmUrl =
  publicRuntimeConfig.TDM_API_URL || process.env.TDM_API_URL;
const basePath = calcBasePath();

// If we don't error early, the Next.js dev server will error downstream but swallow the errors,
// which can make for a frustrating experience.
if (process.env.NODE_ENV === 'development') {
  if (isNil(studioUrl)) {
    throw new Error(
      'STUDIO_API_URL is not defined. If you are running a development instance of Snorkel Flow, you should first run the following command: `export STUDIO_API_URL=http://localhost:8484`',
    );
  }

  if (isNil(tdmUrl)) {
    throw new Error(
      'TDM_API_URL is not defined. If you are running a development instance of Snorkel Flow, you should first run the following command: `export TDM_API_URL=http://localhost:8686`',
    );
  }
}

export const serverRequest = makeServerRequest(studioUrl);
export const tdmServerRequest = makeServerRequest(tdmUrl);

export const serverResponse = async (res: any, result?: any) => {
  res.setHeader('Content-Type', 'application/json');
  res.statusCode = result.status;

  try {
    const data = await result.json();
    res.end(JSON.stringify(data));
  } catch (error: any) {
    res.end('{}');
  }
};

const makeServerDefault =
  (requestFunction: Function) =>
  async ({ url, body, method, headers }: any, res: any) => {
    const requestPath = calcRequestPath(url);

    const result = await requestFunction(
      requestPath,
      method !== 'GET' ? body : undefined,
      method,
      getHeaders(headers),
    );
    serverResponse(res, result);
  };

const makeDataStreamDefault =
  (requestFunction: Function) => async (req: Request, res: Response) => {
    const { url, headers, method } = req;
    const requestPath = calcRequestPath(url);

    const data: any = [];
    req.on('data', chunk => {
      data.push(chunk);
    });

    req.on('end', async () => {
      const body = Buffer.concat(data);
      const response: any = await requestFunction(
        requestPath,
        body,
        method,
        headers,
      );
      serverResponse(res, response);
    });
  };

export const serverDefault = makeServerDefault(serverRequest);
export const tdmServerDefault = makeServerDefault(tdmServerRequest);
export const tdmDataStreamDefault = makeDataStreamDefault(tdmServerRequest);

const tdmConfiguration = new TDMConfiguration({ basePath: tdmUrl });
const studioConfiguration = new StudioConfiguration({
  basePath: studioUrl,
});

const tdmCreateApi =
  (configuration: TDMConfiguration) =>
  <T extends TDMBaseAPI>(C: new (conf: TDMConfiguration) => T): T =>
    new C(configuration);

const studioCreateApi =
  (configuration: StudioConfiguration) =>
  <T extends StudioBaseAPI>(C: new (conf: StudioConfiguration) => T): T =>
    new C(configuration);

const createTdmApi = <T extends TDMBaseAPI>(
  C: new (conf: TDMConfiguration) => T,
  customConfiguration?: Partial<TDMConfiguration>,
) =>
  typeof window === 'undefined'
    ? tdmCreateApi(
        new TDMConfiguration({
          ...tdmConfiguration,
          ...customConfiguration,
        }),
      )(C)
    : tdmCreateApi(
        new TDMConfiguration({
          basePath: generateAPIUrl({ basePath }),
          ...customConfiguration,
          baseOptions: {
            ...tdmConfiguration?.baseOptions,
            ...customConfiguration?.baseOptions,
          },
        }),
      )(C);

const createStudioApi = <T extends StudioBaseAPI>(
  C: new (conf: StudioConfiguration) => T,
) =>
  typeof window === 'undefined'
    ? studioCreateApi(new StudioConfiguration(studioConfiguration))(C)
    : studioCreateApi(
        new StudioConfiguration({
          basePath: generateAPIUrl({ basePath }),
          baseOptions: studioConfiguration?.baseOptions,
        }),
      )(C);

export const datasetCommentsApi = createTdmApi(DatasetCommentsApi);
export const datasetTagsApi = createTdmApi(DatasetTagTypesApi);
export const actorsApi = createStudioApi(ActorsApi);
export const applyApi = createStudioApi(ApplyApi);
export const clustersApi = createStudioApi(ClustersApi);
export const codeLfApi = createStudioApi(CodeLfApi);
export const contextDatasetApi = createStudioApi(ContextDatasetApi);
export const datasetApi = createStudioApi(DatasetApi);
export const studioLfsApi = createStudioApi(StudioLfsApi);
export const downloadApi = createStudioApi(DownloadApi);
export const filtersApi = createStudioApi(FiltersApi);
export const highlightApi = createStudioApi(HighlightApi);
export const indexApi = createStudioApi(IndexApi);
export const labelsApi = createStudioApi(LabelsApi);
export const labelsAutofillApi = createStudioApi(LabelsAutofillApi);
export const lfSuggesterApi = createStudioApi(LfSuggesterApi);
export const marginSuggestionsApi = createStudioApi(MarginSuggestionsApi);
export const memoryProfilingApi = createStudioApi(MemoryProfilingApi);
export const packageApi = createStudioApi(PackageApi);
export const previewApi = createStudioApi(PreviewApi);
export const promptApi = createStudioApi(PromptApi);
export const searchApi = createStudioApi(SearchApi);
export const suggestionsApi = createStudioApi(SuggestionsApi);
export const summaryApi = createStudioApi(SummaryApi);
export const visualizationsApi = createStudioApi(VisualizationsApi);
export const datasetBatchApi = createTdmApi(DatasetBatchApi);
export const labelSchemasApi = createTdmApi(LabelSchemasApi);
export const analysisApi = createTdmApi(AnalysisApi);
export const annotationsApi = createTdmApi(AnnotationsApi);
export const annotationApi = createTdmApi(AnnotationApi);
export const apiKeysApi = createTdmApi(ApiKeysApi);
export const applicationsApi = createTdmApi(ApplicationsApi);
export const applicationTemplatesApi = createTdmApi(ApplicationTemplatesApi);
export const batchesApi = createTdmApi(BatchesApi);
export const blocksApi = createTdmApi(BlocksApi);
export const commentsApi = createTdmApi(CommentsApi);
export const cpuProfilingApi = createTdmApi(CpuProfilingApi);
export const dataConnectorApi = createTdmApi(DataConnectorApi);
export const dataConnectorConfigApi = createTdmApi(DataConnectorConfigApi);
export const dataConnectorRolesApi = createTdmApi(DataConnectorRolesRouterApi);
export const datasetsApi = createTdmApi(DatasetsApi);
export const datasetViewsApi = createTdmApi(DatasetViewsApi);
export const datasourcesApi = createTdmApi(DatasourcesApi);
export const defaultApi = createTdmApi(DefaultApi);
export const defaultsApi = createTdmApi(DefaultsApi);
export const deployApi = createTdmApi(DeployApi);
export const devXsApi = createTdmApi(DevXsApi);
export const evaluationApi = createTdmApi(EvaluationApi);
export const eventsApi = createTdmApi(EventsApi);
export const externalLLMConfigsApi = createTdmApi(ExternalLlmConfigsApi);
export const groundTruthApi = createTdmApi(GroundTruthApi);
export const invitesApi = createTdmApi(InvitesApi);
export const jobsApi = createTdmApi(JobsApi);
export const labelMatricesApi = createTdmApi(LabelMatricesApi);
export const lfPackagesApi = createTdmApi(LfPackagesApi);
export const lfsApi = createTdmApi(LfsApi);
export const licenseCheckApi = createTdmApi(LicenseCheckApi);
export const logsApi = createTdmApi(LogsApi);
export const metricsApi = createTdmApi(MetricsApi);
export const modelsApi = createTdmApi(ModelsApi);
export const monitoringApi = createTdmApi(MonitoringApi);
export const nodesApi = createTdmApi(NodesApi);
export const notebooksApi = createTdmApi(NotebooksApi);
export const notebookSettingsApi = createTdmApi(NotebookSettingsApi);
export const notificationsApi = createTdmApi(NotificationsApi);
export const objectStorageApi = createTdmApi(ObjectStorageApi);
export const operatorsApi = createTdmApi(OperatorsApi);
export const populatorsApi = createTdmApi(PopulatorsApi);
export const postprocessorsApi = createTdmApi(PostprocessorsApi);
export const predictionsApi = createTdmApi(PredictionsApi);
export const predictionApiApi = createTdmApi(PredictionApiApi);
export const progressReportApi = createTdmApi(ProgressReportApi);
export const promptBuilderApi = createTdmApi(PromptBuilderApi);
export const promptsApi = createTdmApi(PromptsApi);
export const sourcesApi = createTdmApi(SourcesApi);
export const ssoApi = createTdmApi(SsoApi);
export const tagMapApi = createTdmApi(TagMapApi);
export const tagsApi = createTdmApi(TagsApi);
export const taskDatasourcesApi = createTdmApi(TaskDatasourcesApi);
export const tasksApi = createTdmApi(TasksApi);
export const tmpFilesApi = createTdmApi(TmpFilesApi);
export const trainingSetsApi = createTdmApi(TrainingSetsApi);
export const transferApi = createTdmApi(TransferApi);
export const usersApi = createTdmApi(UsersApi);
export const userSettingsApi = createTdmApi(UserSettingsApi);
export const workflowsApi = createTdmApi(WorkflowsApi);
export const warmStartApi = createTdmApi(WarmStartApi);
export const workspacesApi = createTdmApi(WorkspaceApi);
export const modelRegistryApi = createTdmApi(ModelRegistryApi);
export const embeddingsApi = createTdmApi(EmbeddingsApi);
export const staticAssetsApi = createTdmApi(StaticAssetApi);
export const staticAssetUploadMethodRolesApi = createTdmApi(
  StaticAssetUploadMethodRolesRouterApi,
);
export const staticAssetUploadMethodApi = createTdmApi(
  StaticAssetUploadMethodApi,
);
export const rbacResourceRolesRouterApi = createTdmApi(
  RbacResourceRolesRouterApi,
);
export const fmIntegrationsApi = createTdmApi(FmIntegrationsApi);
export const resourceRecencyApi = createTdmApi(ResourceRecencyRouterApi);

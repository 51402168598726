import { fromPairs } from 'lodash';

import type { HydratedBatch } from '@api/tdm';
import type { BatchDetails } from '@app/Annotations/types';
import { DateFormat, formatDate } from '@coral/utils/date';
import type { SplitType } from '@core/types';

const mapBatch = (batch: HydratedBatch): BatchDetails => {
  let progress = 0;

  const userSourceMap = fromPairs(
    batch.sources?.map(({ source_uid, user_uid }) => [user_uid, source_uid]),
  );

  const annotators = batch.annotators?.map(annotator => {
    progress += annotator.annotation_count;

    return {
      sourceUid: userSourceMap[annotator.user_uid],
      userUid: annotator.user_uid,
      progress: annotator.annotation_count,
      size: annotator.annotation_target,
    };
  });

  const size = batch.batch_size || 0;

  return {
    uid: batch.batch_uid,
    name: batch.name,
    nodeUid: batch.node_uid,
    appName: batch.application_name,
    appUid: batch.application_uid,
    sources: batch.sources || [],
    annotators: annotators || [],
    createdAt: formatDate(batch.ts, DateFormat.slashSeparated),
    expertSourceUid: batch.expert_source_uid,
    size,
    progress,
    metadata: batch.metadata,
    ...(batch.split ? { split: batch.split as SplitType } : {}),
  };
};

export default mapBatch;

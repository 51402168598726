import type { RootState, Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { NodeDetailsState } from './types';

export const selectNodeDetails: Selector<NodeDetailsState> = ({
  nodeDetails,
}: RootState) => nodeDetails;

export const selectAll: Selector<NodeDetailsState> = ({
  nodeDetails,
}: RootState) => nodeDetails;

export const selectNodeDetailsNode: Selector<NodeDetailsState['node']> = pipe(
  selectAll,
  ({ node }: NodeDetailsState) => node,
);

export const selectNodeDetailsApplication: Selector<
  NodeDetailsState['application']
> = pipe(selectAll, ({ application }: NodeDetailsState) => application);

export const selectNodeDetailsNodeId: Selector<NodeDetailsState['node']['id']> =
  pipe(selectNodeDetailsNode, ({ id }) => id);

export default null;

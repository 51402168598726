import { createReducerSlice } from '@utils/redux/reducer';

import type { UserPrefsState } from './types';

export const initialState: UserPrefsState = {
  isSidebarNavVisible: true,
  applyPostprocessors: {},
  candidateSelected: {},
  candidateContextIndex: {},
};

const reducerSlice = createReducerSlice('userPrefs', initialState, {
  toggleIsSidebarNavVisible(state: UserPrefsState): UserPrefsState {
    let { isSidebarNavVisible } = state;
    isSidebarNavVisible = !isSidebarNavVisible;
    state = { ...state, isSidebarNavVisible };

    return state;
  },

  setApplyPostprocessors(
    state: UserPrefsState,
    payload: { applyPostprocessors: boolean; stateKey: string },
  ): UserPrefsState {
    const { applyPostprocessors: applyPostprocessorsValue, stateKey } = payload;

    return applyPostprocessorsValue === state.applyPostprocessors?.[stateKey]
      ? state
      : {
          ...state,
          applyPostprocessors: {
            ...state.applyPostprocessors,
            [stateKey]: applyPostprocessorsValue,
          },
        };
  },

  setCandidateSelected(
    state: UserPrefsState,
    payload: {
      stateKey: string;
      value: UserPrefsState['candidateSelected'][0];
    },
  ): UserPrefsState {
    const { stateKey, value } = payload;

    return {
      ...state,
      candidateSelected: {
        ...state.candidateSelected,
        [stateKey]: value,
      },
    };
  },

  setCandidateContextIndex(
    state: UserPrefsState,
    payload: {
      stateKey: string;
      value: UserPrefsState['candidateContextIndex'][0];
    },
  ): UserPrefsState {
    const { stateKey, value } = payload;

    return {
      ...state,
      candidateContextIndex: {
        ...state.candidateContextIndex,
        [stateKey]: value,
      },
    };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

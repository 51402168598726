export enum ButtonThemes {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
}

export type ThemeOverrides = Readonly<{
  filled?: {
    background?: string;
    hoverBackground?: string;
  };
}>;

export enum ButtonSizes {
  bare = 'bare',
  slim = 'slim',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

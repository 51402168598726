import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import type { DropdownOptionType, OnSelect, OnSelectItem } from '../types';

type UseDropdownValueProps<ValueType> = {
  options: DropdownOptionType<ValueType>[];
  passedValue?: ValueType;
  defaultValue?: ValueType;
  overrideSelect?: OnSelect<ValueType>;
  onSelect?: OnSelect<ValueType>;
  multiSelect: boolean;
  searchable: boolean;
  focusEntry: VoidFunction;
  combobox?: boolean;
};

const useDropdownValue = <ValueType>(
  props: UseDropdownValueProps<ValueType>,
) => {
  const {
    options,
    // value passed from parent component, aka value
    passedValue,
    defaultValue,
    overrideSelect,
    onSelect,
    searchable,

    focusEntry,
    multiSelect,
    combobox,
  } = props;

  const getOptionIndex = (valueToGet: any): number =>
    options.findIndex(({ value }) => value === valueToGet);

  const getInitialValues = (valueToGet: any): Set<number> => {
    if (multiSelect) {
      let valueToGetMulti = new Set<any>();

      if (typeof valueToGet === 'string') {
        valueToGetMulti = new Set([valueToGet]);
      } else {
        valueToGetMulti = (valueToGet as Set<any>) || new Set<any>();
      }

      return new Set(
        options
          .map(({ value }, index) =>
            valueToGetMulti.has(value) ? index : null,
          )
          .filter(v => typeof v === 'number') as number[],
      );
    }

    const nextIndex = getOptionIndex(valueToGet);

    return new Set(nextIndex !== -1 ? [nextIndex] : []);
  };

  const initialValueIndexSet = getInitialValues(defaultValue || passedValue);
  const initialValueIndexArray = Array.from(initialValueIndexSet);
  const lastInitialValueIndex =
    initialValueIndexArray[initialValueIndexArray.length - 1] ?? -1;
  const initialValue =
    options[initialValueIndexArray[lastInitialValueIndex] ?? -1];
  const [allSelectedIndexes, setSelectedIndexes] =
    useState<Set<number>>(initialValueIndexSet);

  const [searchValue, setSearchValue] = useState<string>(
    !searchable && combobox ? initialValue?.title || '' : '',
  );

  const [activeValueIndex, setActiveValueIndex] = useState<number>(
    lastInitialValueIndex,
  );

  const clearSelection = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedIndexes(initialValueIndexSet);
    setActiveValueIndex(-1);
    setSearchValue('');

    focusEntry();

    if (onSelect) {
      onSelect({ value: null as unknown as ValueType, title: '' });
    }
  };

  const setValuesByIndex = (valueIndex: number) => {
    if (valueIndex === -1) {
      if (multiSelect) {
        return;
      }

      // reset if valueIndex is -1 and not multiselect
      setSelectedIndexes(new Set());

      return;
    }

    if (allSelectedIndexes.has(valueIndex) && multiSelect) {
      setSelectedIndexes(prev => {
        prev.delete(valueIndex);

        return new Set(prev);
      });

      setSearchValue('');

      return;
    }

    setSelectedIndexes(prev => {
      if (multiSelect) {
        prev.add(valueIndex);

        setSearchValue('');

        return new Set(prev);
      }

      return new Set([valueIndex]);
    });

    if (!multiSelect) {
      setSearchValue(combobox ? options[valueIndex]?.title || '' : '');
    }

    setActiveValueIndex(valueIndex);
  };

  const onSelectItem: OnSelectItem = (value: any) => {
    const index = getOptionIndex(value);

    focusEntry();

    if (overrideSelect) {
      overrideSelect(options[index]);

      return;
    }

    setValuesByIndex(index);

    if (onSelect) {
      onSelect(options[index]);
    }
  };

  useEffect(() => {
    const nextInitialValueIndexSet = getInitialValues(passedValue);

    setSelectedIndexes(nextInitialValueIndexSet);

    // for comboboxes since they use searchValue as input value
    if (!multiSelect && !searchable) {
      const index = getOptionIndex(passedValue);
      setSearchValue(combobox ? options[index]?.title || '' : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedValue, JSON.stringify(options)]);

  const allSelectedIndexArray = Array.from(allSelectedIndexes).filter(
    selectedIndex => !!options[selectedIndex],
  );
  const lastSelectedOptionIndex =
    allSelectedIndexArray[allSelectedIndexArray.length - 1];

  const { title: currentTitle = '', value: currentValue = -1 } =
    options[lastSelectedOptionIndex] || {};

  const allSelectedOptions: DropdownOptionType<ValueType>[] =
    allSelectedIndexArray.map(selectedIndex => options[selectedIndex]);

  return {
    activeValueIndex,
    allSelectedOptions,
    allSelectedValues: new Set(allSelectedOptions.map(({ value }) => value)),
    setSearchValue,
    setValuesByIndex,
    setActiveValueIndex,
    searchValue,
    currentTitle,
    currentValue,
    onSelectItem,
    clearSelection,

    // last index
    fallbackIndex: lastSelectedOptionIndex || lastInitialValueIndex,
  };
};

export default useDropdownValue;

import { createReducerSlice } from '@utils/redux/reducer';

import type { JobEntry, JobId, JobsState } from './types';

export const initialState: JobsState = {};

const omit = <T extends object>(obj: T, prop: keyof T): T => {
  obj = { ...obj };
  delete obj[prop];

  return obj;
};

const reducerSlice = createReducerSlice('jobs', initialState, {
  addJob(state: JobsState, jobEntry: JobEntry): JobsState {
    const { jobUid } = jobEntry;

    return state[jobUid] ? state : { ...state, [jobUid]: jobEntry };
  },
  removeJob(state: JobsState, jobUid: JobId): JobsState {
    return state[jobUid] ? omit(state, jobUid) : state;
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';

const useNodeId = (): number => {
  // TODO: ENG-20683
  const nodeId = useSelector(selectors.task.selectNodeId);
  const id = useSelector(selectors.nodeDetails.selectNodeDetailsNodeId);

  return useMemo(() => id || nodeId, [id, nodeId]);
};

export default useNodeId;

import type { Reducer } from 'redux';

import type { Action, ActionCreator, ActionType, ReducerSlice } from './types';

// --------------------------------------------------------------------
type ActionHandler<TState, TPayload = never> = [TPayload] extends [never]
  ? (state: TState) => TState
  : (state: TState, payload: TPayload) => TState;

type ActionHandlerPayload<TState, THandler> = THandler extends ActionHandler<
  TState,
  never
>
  ? never
  : THandler extends ActionHandler<TState, infer TPayload>
  ? TPayload
  : never;

type ActionHandlerMap<TState> = Record<string, ActionHandler<TState, any>>;

type ActionCreatorsFromHandlers<TState, TActionHandlerMap> = {
  [K in keyof TActionHandlerMap]: TActionHandlerMap[K] extends ActionHandler<
    TState,
    never
  >
    ? ActionCreator<never>
    : TActionHandlerMap[K] extends ActionHandler<TState, infer TPayload>
    ? ActionCreator<TPayload>
    : never;
};

// --------------------------------------------------------------------
export const createActionSansPayload = (type: ActionType): Action<never> =>
  ({ type } as Action<never>);

export const createActionWithPayload = <TPayload extends any>(
  type: ActionType,
  payload: TPayload,
): Action<TPayload> => ({ type, payload } as Action<TPayload>);

// --------------------------------------------------------------------
export const createReducer = <TState>(
  handlers: ActionHandlerMap<TState>,
  defaultState: TState,
): Reducer<TState> => {
  const reducer = (
    state: TState = defaultState,
    action: Action<any>,
  ): TState => {
    const handler: ActionHandler<TState, unknown> = handlers[action.type];

    return handler ? handler(state, action.payload) : state;
  };

  // Force cast to pickup our internal action definition.
  return reducer as Reducer<TState>;
};

// --------------------------------------------------------------------
const actionTypeFromKey = (sliceKey: string, k: string): ActionType =>
  `${sliceKey}/${k}` as ActionType;

const createActionTypes = <TState>(
  sliceKey: string,
  actionHandlerMap: ActionHandlerMap<TState>,
): Record<string, ActionType> => {
  const actionTypes = Object.keys(actionHandlerMap).reduce((acc, k) => {
    return { ...acc, [k]: actionTypeFromKey(sliceKey, k) };
  }, {});

  return actionTypes;
};

const createActionCreators = <
  TState,
  TActionHandlerMap extends ActionHandlerMap<TState>,
>(
  sliceKey: string,
  actionHandlerMap: TActionHandlerMap,
) => {
  const actionCreators = Object.keys(actionHandlerMap).reduce((acc, k) => {
    const type = actionTypeFromKey(sliceKey, k);
    const handler = actionHandlerMap[k];
    const actionCreator =
      handler.length === 1
        ? () => createActionSansPayload(type)
        : (payload: ActionHandlerPayload<TState, typeof handler>) =>
            createActionWithPayload(type, payload);

    return { ...acc, [k]: actionCreator };
  }, {});

  return actionCreators as ActionCreatorsFromHandlers<
    TState,
    TActionHandlerMap
  >;
};

const createHandlerMap = <TState>(
  sliceKey: string,
  actionHandlerMap: ActionHandlerMap<TState>,
) => {
  const handlerMap = Object.keys(actionHandlerMap).reduce((acc, k) => {
    const type = actionTypeFromKey(sliceKey, k);

    return { ...acc, [type]: actionHandlerMap[k] };
  }, {});

  return handlerMap as ActionHandlerMap<TState>;
};

export const createReducerSlice = <
  TState,
  TActionHandlerMap extends ActionHandlerMap<TState>,
>(
  key: string,
  initialState: TState,
  actionHandlerMap: TActionHandlerMap,
): ReducerSlice<
  TState,
  ActionCreatorsFromHandlers<TState, TActionHandlerMap>
> => {
  const actionTypes = createActionTypes(key, actionHandlerMap);

  const actionCreators = createActionCreators<TState, TActionHandlerMap>(
    key,
    actionHandlerMap,
  );

  const handlerMap = createHandlerMap(key, actionHandlerMap);
  const reducer = createReducer<TState>(handlerMap, initialState);

  return { key, actionTypes, actionCreators, reducer };
};

import React from 'react';

import DefaultAccordion from './__internal__/DefaultAccordion';
import OnboardingAccordion from './__internal__/OnboardingAccordion';
import OnboardingInnerAccordion from './__internal__/OnboardingInnerAccordion';
import Studio2Accordion from './__internal__/Studio2Accordion';
import { AccordionTheme } from './types';
import type {
  AccordionProps,
  DefaultAccordionProps,
  OnboardingAccordionProps,
  OnboardingInnerAccordionProps,
  Studio2AccordionProps,
} from './types';

const Accordion = ({
  theme = AccordionTheme.Default,
  children,
  ...props
}: AccordionProps) => {
  switch (theme) {
    case AccordionTheme.Studio2:
      return (
        <Studio2Accordion {...(props as Studio2AccordionProps)}>
          {children}
        </Studio2Accordion>
      );
    case AccordionTheme.Onboarding:
      return (
        <OnboardingAccordion {...(props as OnboardingAccordionProps)}>
          {children}
        </OnboardingAccordion>
      );
    case AccordionTheme.OnboardingInner:
      return (
        <OnboardingInnerAccordion {...(props as OnboardingInnerAccordionProps)}>
          {children}
        </OnboardingInnerAccordion>
      );
    case AccordionTheme.Default:
    default:
      return (
        <DefaultAccordion {...(props as DefaultAccordionProps)}>
          {children}
        </DefaultAccordion>
      );
  }
};

export type { AccordionProps };
export { AccordionTheme };

export default Accordion;

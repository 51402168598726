import type { AccessTokenData } from '@core/types';

class AccessToken {
  private static instance: AccessToken | null = null;

  private accessToken: AccessTokenData | null = null;

  private readonly accessTokenBroadcastChannel = new BroadcastChannel(
    'snorkelAccessToken',
  );

  static getInstance() {
    if (!this.instance) {
      this.instance = new AccessToken();
    }

    return this.instance;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setAccessToken(
    token: AccessTokenData | null,
    fromBroadcastChannel: boolean = false,
  ) {
    const tokenWasUpdated =
      this.accessToken?.accessToken !== token?.accessToken;
    this.accessToken = token;

    if (tokenWasUpdated && !fromBroadcastChannel) {
      this.accessTokenBroadcastChannel.postMessage({
        globalAccessToken: token?.accessToken,
      });
    }
  }

  getAccessTokenBroadcastChannel() {
    return this.accessTokenBroadcastChannel;
  }
}

export default AccessToken;

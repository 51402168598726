/* eslint-disable local-rules/require-data-cy */
import { Grow } from '@material-ui/core';
import type { HTMLAttributes, ReactNode } from 'react';
import { forwardRef, useRef, useState } from 'react';

import useOutside from '@coral/hooks/useOutside';
import combineClasses from '@coral/utils/combineClasses';

import DropdownArrow from './DropdownArrow';
import type { Color, Variant } from './types';
import getClasses, {
  baseClasses,
  getDropdownArrowButtonColor,
  getDropdownButtonClasses,
  getDropdownClasses,
  getSizeClasses,
} from './utils/getClasses';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  color?: Color;
  variant?: Variant;
  children: ReactNode;
  additionalOptions?: Array<{
    children: ReactNode | string;
    onClick: Function;
    dataCy?: string;
    disabled?: boolean;
  }>;
  dropdownDataCy?: string;
  size?: 'large' | 'small';
  fontSize?: 'large' | 'small';
  dropdownPosition?: 'bottom' | 'top';
  disabled?: boolean;
}

const SplitButton = forwardRef(
  (
    {
      color = 'default',
      variant = 'default',
      disabled,
      onClick,
      children,
      additionalOptions,
      dropdownDataCy,
      size,
      fontSize,
      dropdownPosition,
      className,
      ...props
    }: Props,
    ref: any,
  ) => {
    const [expanded, setExpanded] = useState(false);
    const buttonRef = useRef(null);

    const rootRef = ref || buttonRef;

    useOutside(rootRef, () => {
      setExpanded(false);
    });

    const handleClick = (fn: any) => {
      if (expanded) {
        setExpanded(false);
      }

      if (!fn) return;
      fn();
    };

    const dropDownBrClass =
      dropdownPosition === 'bottom' ? 'rounded-tl' : 'rounded-bl';
    const borderClass = expanded ? dropDownBrClass : 'rounded-l';

    const downBtnBrClass =
      dropdownPosition === 'bottom' ? 'rounded-tr' : 'rounded-br';
    const dwnBtnClass = expanded ? downBtnBrClass : 'rounded-r';

    const sizeClasses = getSizeClasses(size);
    const fontClasses = fontSize === 'small' ? 'text-xs' : 'text-sm';
    const dropdownClasses = getDropdownClasses(size, dropdownPosition);

    return (
      <span
        className={combineClasses(
          'relative z-10 inline-flex',
          sizeClasses,
          className,
        )}
        ref={rootRef}
      >
        <button
          type="button"
          disabled={disabled}
          onClick={() => handleClick(onClick)}
          className={`relative flex flex-1 items-center leading-none ${fontClasses} ${baseClasses} ${borderClass}  ${getClasses(
            color,
            variant,
            disabled || false,
            false,
          )}`}
          ref={buttonRef}
          {...props}
        >
          {children}
        </button>
        <button
          type="button"
          disabled={disabled}
          onClick={() => setExpanded(prev => !prev)}
          className={combineClasses(
            'align-items relative flex items-center px-2 duration-300 focus:outline-none',
            dwnBtnClass,
            getDropdownArrowButtonColor(disabled, variant, color),
            fontClasses,
          )}
          data-cy={dropdownDataCy}
        >
          <span className={dropdownPosition === 'bottom' ? 'rotate-180' : ''}>
            <DropdownArrow color="inherit" />
          </span>
        </button>
        <span
          className={`absolute inline-flex w-full overflow-hidden ${dropdownClasses}`}
        >
          <Grow in={expanded} style={{ transformOrigin: '100% 0 0' }}>
            <div className="flex w-full flex-col">
              {additionalOptions &&
                expanded &&
                additionalOptions.map(
                  ({
                    children: actionChildren,
                    onClick: onClickAction,
                    dataCy,
                    disabled: dropdownDisabled,
                  }) => (
                    <button
                      disabled={dropdownDisabled}
                      type="button"
                      onClick={() => handleClick(onClickAction)}
                      className={`${baseClasses} flex items-center justify-start text-left ${sizeClasses} ${fontClasses} ${getDropdownButtonClasses(
                        variant,
                        color,
                        dropdownPosition,
                      )} ${disabled ? 'cursor-default' : ''}`}
                      data-cy={dataCy}
                    >
                      {actionChildren}
                    </button>
                  ),
                )}
            </div>
          </Grow>
        </span>
      </span>
    );
  },
);

SplitButton.defaultProps = {
  color: 'default',
  variant: 'default',
  dropdownPosition: 'bottom',
  size: 'large',
  fontSize: 'large',
};

export default SplitButton;

import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { DocFiltersState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<DocFiltersState> = pipe(
  selectStudioPage,
  ({ docFilters }: StudioPageState) => docFilters,
);

export const selectConfig: Selector<DocFiltersState['config']> = pipe(
  selectAll,
  ({ config }: DocFiltersState) => config,
);
export const selectConfigFilters: Selector<
  DocFiltersState['config']['filters']
> = pipe(selectConfig, ({ filters }: DocFiltersState['config']) => filters);

export const selectPage: Selector<DocFiltersState['page']> = pipe(
  selectAll,
  ({ page }: DocFiltersState) => page,
);

export const selectLimit: Selector<DocFiltersState['limit']> = pipe(
  selectAll,
  ({ limit }: DocFiltersState) => limit,
);

export const selectOffset: Selector<number> = pipe(
  selectAll,
  ({ limit, page }: DocFiltersState) => limit * page,
);

export default null;

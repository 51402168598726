import type { UserSettingsJson } from '@api/tdm';
import type { LabelColor } from '@coral/utils/styles/labelColors';

export type UserSettings = Omit<UserSettingsJson, 'custom_colors'> & {
  custom_colors?: LabelColor[];
};

export type UserSettingsState = {
  settings: UserSettings;
  isFetching: boolean;
};

// NOTE: shifting this state to `controllers` and using it then in `mockRedux` is breaking jest.mock somehow. That's why this is kept in reducers/userSettings now
export const initialState: UserSettingsState = {
  settings: {},
  isFetching: true, // Initial state should be true to prevent overriding the user's settings with the default settings
};

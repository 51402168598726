import { StudioViewModes } from '@app/StudioPage/types';
import { emptyObj } from '@core/empty';
import { selectStudioViewMode } from '@global/state/reducers/atoms/selectors';
import type { RootState, Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { CoreFiltersState } from './types';

import * as dataFilters from '../dataFilters/selectors';
import * as docFilters from '../docFilters/selectors';

export const selectFiltersState = (state: RootState): CoreFiltersState => {
  const viewMode = selectStudioViewMode(state);

  return viewMode === StudioViewModes.DOCUMENT_VIEW
    ? docFilters.selectAll(state)
    : dataFilters.selectAll(state);
};

export const selectFiltersStateConfig: Selector<CoreFiltersState['config']> =
  pipe(selectFiltersState, ({ config }) => config);

export const selectFiltersStatePage: Selector<CoreFiltersState['page']> = pipe(
  selectFiltersState,
  ({ page }) => page,
);

export const selectFiltersStateLimit: Selector<CoreFiltersState['limit']> =
  pipe(selectFiltersState, ({ limit }) => limit);

export const selectFiltersStateTrainingSetUid: Selector<
  CoreFiltersState['training_set_uid']
> = pipe(selectFiltersState, ({ training_set_uid }) => training_set_uid);

export const selectFiltersStateSortOptions = (state: RootState) => {
  const viewMode = selectStudioViewMode(state);

  return viewMode === StudioViewModes.DOCUMENT_VIEW
    ? emptyObj
    : dataFilters.selectSortOptions(state);
};

export default null;

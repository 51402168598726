import type { AnnotationState } from '@global/state/reducers/annotation/types';
import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

const selectAnnotation: Selector<AnnotationState> = state => state.annotation;

export const selectIsSplitViewOpen: Selector<boolean> = pipe(
  selectAnnotation,
  ({ isSplitViewOpen }) => isSplitViewOpen,
);

export const selectAnnotationDataviewerCurrAnnotationUid: Selector<
  AnnotationState['annotationDataviewerCurrAnnotationUid']
> = pipe(
  selectAnnotation,
  ({ annotationDataviewerCurrAnnotationUid }) =>
    annotationDataviewerCurrAnnotationUid,
);

export const selectSelectedAnnotatorUid: Selector<
  AnnotationState['selectedAnnotatorUid']
> = pipe(selectAnnotation, ({ selectedAnnotatorUid }) => selectedAnnotatorUid);

import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { SearchInputState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<SearchInputState> = pipe(
  selectStudioPage,
  ({ searchInput }: StudioPageState) => searchInput,
);

export const selectSelectedLabel: Selector<SearchInputState['selectedLabel']> =
  pipe(selectAll, ({ selectedLabel }: SearchInputState) => selectedLabel);

export const selectSelectedLFId: Selector<SearchInputState['selectedLFId']> =
  pipe(selectAll, ({ selectedLFId }: SearchInputState) => selectedLFId);

export const selectSelectedSuggestedLFUid: Selector<
  SearchInputState['selectedSuggestedLFUid']
> = pipe(
  selectAll,
  ({ selectedSuggestedLFUid }: SearchInputState) => selectedSuggestedLFUid,
);

export default null;

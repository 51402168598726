import { useSelector } from 'react-redux';

import Icon from '@coral/components/Icon';
import OptionalTooltip from '@coral/components/tooltips/OptionalTooltip';
import generateRandomID from '@coral/utils/generateRandomID';
import NavigationButton from '@global/NavigationContainer/common/NavigationButton';
import type { NavItemChild } from '@global/state/reducers/navigation/types';
import * as selectors from '@global/state/selectors';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

import ComingSoonTag from './ComingSoonTag';
import ListItem from './ListItem';
import NotificationIndicator from './NotificationIndicator';
import getListItemClass from './utils/getListItemClass';

import usePushNextNav from '../../hooks/usePushNextNav';

type NavListProps = {
  child: NavItemChild;
  openPaths: string[];
};

const NavList = ({ child, openPaths }: NavListProps) => {
  const {
    data,
    selectedWhenChildSelected,
    breakBelow,
    tabbed = true,
    relativeSelector = false,
  } = child;
  const { asPath } = useSnorkelRouter();
  const workspaceType = useSelector(selectors.workspaces.selectType);
  const { pushNextNav } = usePushNextNav();
  const path = asPath.split('?')[0];

  const tabbedClassName = tabbed ? 'pl-4' : '';

  return (
    <>
      <ul
        className={`${tabbedClassName} ${
          relativeSelector ? 'relative border-l' : ''
        } mt-2`}
      >
        {data?.map(options => {
          const {
            hidden,
            title,
            tooltip,
            className,
            hiddenUnlessOn,
            href,
            query,
            disabled,
            hrefOverride,
            children,
            comingSoon,
            icon,
            leftIcon,
            notificationIndicator,
            setModeTo,
            onClick,
          } = options;

          const open =
            (!!hrefOverride && openPaths.includes(hrefOverride)) ||
            (!!href && openPaths.includes(href));
          const selected =
            (href === path && setModeTo === workspaceType) ||
            (open && !!selectedWhenChildSelected);

          if (!open && hiddenUnlessOn) {
            return null;
          }

          if (hidden) {
            return null;
          }

          const idFromTitle =
            title && typeof title === 'string' ? title : generateRandomID();

          const itemId = `nav${(href || idFromTitle).replace(/\//g, '_')}`;

          const listItemClass = getListItemClass({ selected });

          return (
            <ListItem
              relativeSelector={relativeSelector}
              id={itemId}
              data-cy={idFromTitle}
              key={itemId}
              className={listItemClass}
              role="listitem"
            >
              <OptionalTooltip title={tooltip}>
                <NavigationButton
                  selected={selected}
                  className={className}
                  disabled={(!href && disabled) || comingSoon || disabled}
                  onClick={() => {
                    onClick?.();
                    pushNextNav(setModeTo, href, query);
                  }}
                  leftIcon={leftIcon}
                >
                  <span className="flex-1 truncate">
                    {title}
                    {!disabled && notificationIndicator && (
                      <NotificationIndicator />
                    )}
                  </span>
                  {comingSoon && <ComingSoonTag />}
                  {icon && <Icon name={icon} />}
                </NavigationButton>
              </OptionalTooltip>
              {children && <NavList child={children} openPaths={openPaths} />}
            </ListItem>
          );
        })}
      </ul>
      {breakBelow && <hr className={`${tabbedClassName} my-6 mr-4`} />}
    </>
  );
};

export default NavList;

import type { GraphStructure } from '@hooks/useGraph/types';
import { GraphCombiner } from '@hooks/useGraph/types';

import type { FilterConfig } from './types';

const initialGraph: GraphStructure = [GraphCombiner.AND];

export const initialFilterConfig: FilterConfig = {
  filters: [],
  graph: initialGraph,
};

export default null;

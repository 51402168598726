import type {
  CommentTagAssets,
  CommitGTAssets,
  CompleteBatchAnnotationAssets,
  CompleteLongRunningLFAssets,
} from '@api/tdm';
import { NotificationType } from '@api/tdm';
import { WorkspaceType } from '@global/state/reducers/workspaces/types';

export type NotificationAssetUri =
  | CommentTagAssets
  | CommitGTAssets
  | CompleteBatchAnnotationAssets
  | CompleteLongRunningLFAssets;

type GetNotificationRedirectPropsArgs = {
  notificationType: NotificationType;
  assetUri: NotificationAssetUri;
};

const getNotificationRedirectProps = ({
  notificationType,
  assetUri,
}: GetNotificationRedirectPropsArgs) => {
  switch (notificationType) {
    case NotificationType.CompleteBatchAnnotation:
      return {
        url: `/applications/${assetUri.application_uid}/nodes/${assetUri.node_uid}/batch`,
        workspace: WorkspaceType.ANNOTATION,
      };
    case NotificationType.CommitGt:
      return {
        url: `/applications/${assetUri.application_uid}/nodes/${assetUri.node_uid}/overview/ground-truth-versions`,
        workspace: WorkspaceType.ANNOTATION,
      };
    case NotificationType.CompleteLongRunningLf:
      return {
        url: `/applications/${assetUri.application_uid}/nodes/${assetUri.node_uid}/studio`,
        workspace: WorkspaceType.DEVELOPMENT,
      };
    default:
      return { url: ``, workspace: WorkspaceType.DEVELOPMENT };
  }
};

export default getNotificationRedirectProps;

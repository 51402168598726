/* eslint-disable local-rules/no-layering-violations */
import generateDefaultHeaders from '@hooks/useRequest/utils/generateDefaultHeaders';

const defaultInit = {
  headers: {},
};

const init = (
  data: any,
  method: string | undefined,
  reqInit: RequestInit = defaultInit,
) => {
  if (data) {
    const { headers, ...restInit } = reqInit;
    const modifiedHeaders = {
      ...generateDefaultHeaders(),
      'content-type': 'application/json',
      ...headers,
    };
    const typeJson =
      (modifiedHeaders?.['content-type']).indexOf('application/json') !== -1;

    return {
      method: method || 'POST',
      headers: modifiedHeaders,
      body: typeJson ? JSON.stringify(data) : data,
      ...restInit,
    };
  }

  return {
    method: method || 'GET',
    ...reqInit,
  };
};

export default init;

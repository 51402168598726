import Icon, { Icons } from '@coral/components/Icon';
import combineClasses from '@coral/utils/combineClasses';

import DetailAccordion from './DetailAccordion';
import type { PageRegionTag } from './types';

export type PageRegionUncaughtErrorAlertProps = Readonly<{
  tag?: PageRegionTag;
  message: string;
  isWrappingPopUp: boolean;
}>;

const PageRegionUncaughtErrorAlert = ({
  tag,
  message,
  isWrappingPopUp,
}: PageRegionUncaughtErrorAlertProps) => {
  const className = combineClasses(
    'bg-red-100',
    'p-4',
    isWrappingPopUp ? '' : 'shadow-banner',
  );

  return (
    <div className={className} data-cy="uncaught-error-component">
      <div className="mb-4 flex items-start">
        <Icon name={Icons.WARNING_TRIANGLE} />
        <div className="ml-2">
          <span className="font-bold">Something is wrong.</span>
          {tag ? (
            <span>{` There was an error loading the ${tag}. `}</span>
          ) : null}
          <div className="mt-2">
            If the issue persists, please contact Snorkel support.
          </div>
        </div>
      </div>
      {message ? <DetailAccordion>{message}</DetailAccordion> : null}
    </div>
  );
};

export default PageRegionUncaughtErrorAlert;

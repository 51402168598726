import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import useRequest from '@hooks/useRequestWithLogging';
import { notificationsApi } from '@utils/api/serverRequests';

const useNotifications = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const notifications = useSelector(selectors.atoms.selectNotifications);
  const dispatch = useDispatch();
  const request = useRequest();

  const getNotifications = useCallback(async () => {
    setLoading(true);

    const result = await request(
      notificationsApi.listNotificationsNotificationsGet,
      {},
    );

    setLoading(false);

    dispatch(actions.atoms.setNotifications(result?.notifications ?? []));
  }, [request, dispatch]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const deleteNotification = async (notificationUid: number) => {
    await request(
      notificationsApi.deleteNotificationNotificationsNotificationUidDelete,
      { notificationUid },
    );
  };

  const resolveNotification = (notificationUid: number | undefined) => {
    if (!notificationUid) {
      return;
    }

    deleteNotification(notificationUid);
    dispatch(
      actions.atoms.setNotifications(
        notifications.filter(
          notification => notification.notification_uid !== notificationUid,
        ),
      ),
    );
  };

  return {
    loading,
    resolveNotification,
    getNotifications,
    notifications,
  };
};

export default useNotifications;

import { LLMType, PromptTool } from '@api/tdm';
import type { SearchSnippet } from '@core/types';

import type { ContextualSearchSnippet } from '../StudioSearch/types';
import { SnippetType } from '../StudioSearch/types';

export const isContextualSearchSnippet = (
  snippet: any,
  snippetType: SnippetType,
): snippet is ContextualSearchSnippet => {
  return snippetType === SnippetType.CONTEXTUAL_SNIPPET && snippet?.snippets;
};

export const isSearchSnippet = (
  snippet: any,
  snippetType: SnippetType,
): snippet is SearchSnippet => {
  return snippetType === SnippetType.SNIPPET && snippet?.snippet;
};

export enum PromptTemplateType {
  PREDICT = 'PREDICT',
  KEYWORD_SEARCH = 'KEYWORD_SEARCH',
  CUSTOM = 'CUSTOM',
}

export type PromptTask =
  | 'sequence_tagging'
  | 'classification'
  | 'pdf_extraction';

export type DropdownPrompt = {
  label: string;
  prompt: string;
  mapperCode: string;
  templateType: PromptTemplateType;
};

export type ClientPromptInputModel = {
  modelType: LLMType;
  modelName: string;
  promptText: string;
  tool: PromptTool;
  primaryTextField?: string;
  labelsToTargets?: Record<string, string>;
  outputCode?: string;
  useRag?: boolean;
};

export enum PromptDataState {
  NOT_STARTED = 'NOT_STARTED',
  ABORTED = 'ABORTED',
  COMPLETED = 'COMPLETED',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
}

// These are properties that the FE maintains, derived
// from the PromptOutputData BE response for display in
// the modular data viewer (MDV)
export type TransformedPromptOutputData = {
  // Stores prompt snippet text as a primary key col
  text: string;
  // Ground truth string, expected from the GT cell
  groundTruthLabel: string;
  // FM label string, expected from PromptView
  fmLabelString: string;
};

// This is a FE-only type adapted from the base generated
// PromptOutputData type from openAPI
export type ClientPromptOutputData = {
  xUid: string;
  contextUid: string;
  output: string;
  confidence: number;
  label: number;
  groundTruth: number;
  snippetData: ContextualSearchSnippet | SearchSnippet;
  // This remaps the snippetType string from the BE to this enum
  snippetType: SnippetType;
} & TransformedPromptOutputData;

export const PromptToolToLLMTypeMapper: [PromptTool, LLMType][] = [
  [PromptTool.ExtractiveDocvqa, LLMType.DocVqa],
  [PromptTool.Freeform, LLMType.Text2textQa],
  [PromptTool.ExtractiveQa, LLMType.Qa],
  [PromptTool.ExtractiveLlmQa, LLMType.PdfText2textExtractor],
];

import isEqual from 'lodash/isEqual';

import type { Workspace } from '@api/tdm';
import { DEFAULT_WORKSPACE } from '@core/constants';
import { emptyList } from '@core/empty';
import { createReducerSlice } from '@utils/redux/reducer';

import type { WorkspacesState } from './types';
import { WorkspaceType } from './types';

export const initialState = {
  type: WorkspaceType.DEVELOPMENT,
  adminWorkspaces: emptyList as Workspace[],
  workspaces: emptyList as Workspace[],
  selectedWorkspace: DEFAULT_WORKSPACE,
};

const reducerSlice = createReducerSlice('workspaces', initialState, {
  setWorkspaceType(
    state: WorkspacesState,
    type: WorkspaceType,
  ): WorkspacesState {
    return type === state.type ? state : { ...state, type };
  },

  setAdminWorkspaces(
    state: WorkspacesState,
    adminWorkspaces: Workspace[],
  ): WorkspacesState {
    return adminWorkspaces === state.adminWorkspaces
      ? state
      : { ...state, adminWorkspaces };
  },

  setWorkspaces(
    state: WorkspacesState,
    workspaces: Workspace[],
  ): WorkspacesState {
    return workspaces === state.workspaces ? state : { ...state, workspaces };
  },

  setSelectedWorkspace(
    state: WorkspacesState,
    selectedWorkspace: Workspace,
  ): WorkspacesState {
    return isEqual(selectedWorkspace, state.selectedWorkspace)
      ? state
      : { ...state, selectedWorkspace };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import type {
  ClusterResponse,
  ContextualSearchSnippets,
  SearchSnippets,
  SnippetType,
} from '@app/StudioPage/components/StudioSearch/types';
import type { FeedbackResponse } from '@app/StudioPage/types';
import type { AppliedLFStats, Label } from '@core/types';
import type { GraphStructure } from '@hooks/useGraph/types';

export enum LFTableTab {
  ACTIVE = 0,
  SUGGESTED = 1,
  INACTIVE = 2,
  IN_PROGRESS = 3,
}

export type SearchMetrics = Partial<AppliedLFStats> & {
  num_matches?: number;
  class_distribution?: Record<number | string, number>;
  highest_precision_class?: number;
  feedback_responses?: FeedbackResponse[];
};

type SearchDataSnippetsCore = {
  total_matches?: number;
  cluster_response?: ClusterResponse;
};

export type SearchDataSnippetsDefault = SearchDataSnippetsCore & {
  snippet_type: SnippetType.SNIPPET;
  snippets?: SearchSnippets;
};

export type SearchDataSnippetsContextual = SearchDataSnippetsCore & {
  snippet_type: SnippetType.CONTEXTUAL_SNIPPET;
  snippets?: ContextualSearchSnippets;
};

export type SearchDataSnippetsUnknown = SearchDataSnippetsCore & {
  snippet_type: SnippetType.UNKNOWN;
  snippets?: null;
};

export type SearchDataSnippets =
  | SearchDataSnippetsDefault
  | SearchDataSnippetsContextual
  | SearchDataSnippetsUnknown;

export type SearchData = {
  data: SearchDataSnippets;
  selectedUid: string;
  isLoading: boolean;
};

export type LastSearchConfig = {
  templates: Record<string, any>[];
  graph: GraphStructure;
  label?: Label;
};

export type StudioState = Readonly<{
  overrideShowDocMetrics?: boolean;
  searchData: SearchData;
  searchMetrics: SearchMetrics;
  // Client-side ID that increments whenever a new search is submitted.
  // Can be used to couple other state to the lifecycle of a search.
  lastSearchConfigClientId: number;
  // The last search that was submitted (e.g. through Preview LF)
  lastSearchConfig: LastSearchConfig;
  selectedLFTableTab: LFTableTab;

  isLabelDetailsVisible: boolean;
  isTextViewerLoading: boolean;

  // Studio initilization state
  initialized: boolean;
}>;

import { addSeconds } from 'date-fns';

import { usersApi } from '@utils/api/serverRequests';

import { getUserSupportedFeatures } from './supportedFeatures';

const getAccessToken = async (refreshToken?: string) => {
  if (!refreshToken) {
    throw new Error('No refresh token');
  }

  const {
    status,
    data: { access_token, expires_in, refresh_token: newRefreshToken },
  } = await usersApi.refreshTokenRefreshTokenPost({
    refreshToken: { refresh_token: refreshToken },
  });

  if (!access_token || status >= 400) {
    throw new Error('Login failed');
  }

  return {
    accessToken: access_token,
    refreshToken: newRefreshToken,
    expiry: addSeconds(new Date(), expires_in).getTime(),
    supportedFeatures: getUserSupportedFeatures(),
  };
};

export default getAccessToken;

import type { MouseEvent, PropsWithChildren } from 'react';
import { useState } from 'react';

import Icon, { Icons } from '@coral/components/Icon';

const DetailAccordion = ({ children }: PropsWithChildren<{}>) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(v => !v);
  };

  return (
    <>
      <div
        className="flex cursor-pointer select-none items-center justify-between"
        data-cy="details-accordion-toggle"
        onClick={toggleDetails}
      >
        <div className="flex">
          <Icon name={isOpen ? Icons.CARET__DOWN : Icons.CARET__RIGHT} />
          <div className="ml-2">Details</div>
        </div>
      </div>
      {isOpen ? (
        <div
          className="mt-4 max-h-500px overflow-y-auto border-l border-black bg-white px-2 py-2"
          data-cy="details-accordion-content"
        >
          {children}
        </div>
      ) : null}
    </>
  );
};

export default DetailAccordion;

import * as Sentry from '@sentry/nextjs';
import { useDispatch } from 'react-redux';

import * as actions from '@global/state/actions';
import type {
  ErrorMetaData,
  OptionalUid,
  WithoutVariant,
} from '@global/state/reducers/notifications/types';
import type { CreateFSEventFnType } from '@hooks/useFullstoryEvent';
import useFullstoryEvent from '@hooks/useFullstoryEvent';
import type { Logger } from '@hooks/useLogger';
import useLogger from '@hooks/useLogger';
import getFlag, { Flags } from '@utils/getFlag';

import type {
  AlertDispatchers,
  AlertProps,
  FnSetErrorAlertData,
} from './types';

import { tagPageRegionRoot } from '../constants';
import type { PageRegionTag } from '../types';

type AlertLevel = 'info' | 'warn' | 'error';

const isTestEnv = process.env.NODE_ENV === 'test';

type AlertDispatcherKey =
  | 'showInfoAlert'
  | 'showWarningAlert'
  | 'showSuccessAlert'
  | 'showErrorAlert';

const alertDispatcherKeys: AlertDispatcherKey[] = [
  'showInfoAlert',
  'showWarningAlert',
  'showSuccessAlert',
  'showErrorAlert',
];

const mapLevelFromMethod: Record<keyof AlertDispatchers, AlertLevel | null> = {
  showInfoAlert: 'info',
  showWarningAlert: 'warn',
  showSuccessAlert: 'info',
  showErrorAlert: 'error',
};

const calcMetaDataFromProps = (
  alertProps: WithoutVariant<OptionalUid<AlertProps>>,
  level: AlertLevel,
) => {
  return level === 'error' ? (alertProps as any).metaData : undefined;
};

const calcShouldDisplay = ({ status_code = '500' }: ErrorMetaData): boolean => {
  const statusCode = parseInt(status_code, 10);

  return statusCode >= 400 && statusCode !== 404;
};

const calcJsonableProps = (
  alertProps: WithoutVariant<OptionalUid<AlertProps>>,
  level,
) => {
  const metaData = calcMetaDataFromProps(alertProps, level);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { traceback, ...metaDataRest } = metaData ?? {};

  return metaData ? { ...alertProps, ...metaDataRest } : alertProps;
};

export const logAlert = (
  logger: Logger,
  createFSEvent: CreateFSEventFnType,
  region: PageRegionTag,
  level: AlertLevel,
  alertProps: WithoutVariant<OptionalUid<AlertProps>>,
): void => {
  const jsonableProps = calcJsonableProps(alertProps, level);

  if (level === 'error') {
    const sentryEventInfo = { ...jsonableProps, level, region };
    Sentry.captureException(sentryEventInfo);
  }

  const fsEventInfo = { ...jsonableProps, type: 'snackbar', level, region };
  createFSEvent(`snackbar-${level}`, fsEventInfo);

  logger[level]({ ...jsonableProps, level, region });

  // eslint-disable-next-line no-console
  if (!isTestEnv) console.warn('Alert =>', { ...alertProps, level, region });
};

const memoizedTrueDispatchersByTag = {};
const memoizedFalseDispatchersByTag = {};

const useAlertDispatchers = (
  tag: PageRegionTag,
  setErrorAlertData: FnSetErrorAlertData | Function,
): AlertDispatchers => {
  const dispatch = useDispatch();
  const logger = useLogger();
  const { createFSEvent } = useFullstoryEvent(); // Add Custom Fullstory Events logging snackbar notifications
  const { notifications } = actions;
  const shouldShowContextualErrors =
    getFlag(Flags.SHOW_CONTEXTUAL_ERRORS) ?? false;
  const shouldHideApiErrors = getFlag(Flags.HIDE_API_ERRORS) ?? false;

  const memoizedDispatchersByTag = shouldShowContextualErrors
    ? memoizedTrueDispatchersByTag
    : memoizedFalseDispatchersByTag;

  const calcDispatcher = (key: AlertDispatcherKey) => {
    const notify = notifications[key.replace('Alert', 'Notification')];
    const level = mapLevelFromMethod[key]!;

    return (props: WithoutVariant<OptionalUid<AlertProps>>) => {
      logAlert(logger, createFSEvent, tag, level, props);

      const shouldNotShowSnackbar =
        shouldHideApiErrors &&
        (origin === 'snorkel-api-call' || origin === 'onRequestFailed');

      if (shouldNotShowSnackbar) {
        return;
      }

      if (level === 'error') {
        const metaData = calcMetaDataFromProps(props, level) ?? [];
        const shouldDisplay = calcShouldDisplay(metaData);

        if (shouldDisplay) {
          if (shouldShowContextualErrors && tag !== tagPageRegionRoot) {
            setErrorAlertData(props as any);
          } else {
            dispatch(notify(props));
          }
        }
      } else {
        dispatch(notify(props));
      }
    };
  };

  const reduceAlertDispatchers = (acc, key: AlertDispatcherKey) => {
    acc[key] = calcDispatcher(key);

    return acc;
  };

  const calcDispatcherForTag = (): AlertDispatchers => {
    return alertDispatcherKeys.reduce(reduceAlertDispatchers, {});
  };

  if (memoizedDispatchersByTag[tag] === undefined) {
    memoizedDispatchersByTag[tag] = calcDispatcherForTag();
  }

  return memoizedDispatchersByTag[tag];
};

export default useAlertDispatchers;

import { serialize } from 'cookie';

/**
 * Create the default headers (mostly for security) for API calls
 * Header specifications:
 *  CONTENT-SECURITY-POLICY: Content-Security-Policy is required without the attributes 'unsafe-inline' and 'unsafe-eval'
 *   SET-COOKIE: Cookies should be configured to all the appropriate security attributes; HTTPONLY, SECURE, and Same Site.
 *   STRICT-TRANSPORT-SECURITY: Strict-Transport-Security recommends the attributes: preload, include Subdomains & max-age >= 63072000
 *   X-CONTENT-TYPE-OPTIONS: The problem this header solves is called MIME sniffing, which is actually a browser feature
 *   X-FRAME-OPTIONS: HTTP header that allows sites control over how your site may be framed within an iframe. Clickjacking is a practical attack that allows malicious sites to trick users into clicking links on your site even though they may appear to not be on your site at all. As such, the use of the X-Frame-Options header is mandatory for all new websites, and all existing websites are expected to add support for X-Frame-Options as soon as possible
 * @param accessToken - something like a session ID for accessing the app
 * @param cookieName - cookie name
 * @param cookieValue - cookie value
 * @return {} return the correct secure HTTP headers
 */
const generateDefaultHeaders = (
  accessToken?: string,
  cookieName?: string,
  cookieValue?: string,
): {} => {
  const headers = {
    'Content-Security-Policy': "default-src 'self'",
    'Strict-Transport-Security': 'max-age=63072000; preload; includeSubDomains',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'SAMEORIGIN',
    ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
  };

  if (cookieName && cookieValue) {
    headers['Set-Cookie'] = serialize(cookieName, cookieValue, {
      httpOnly: true,
      sameSite: 'strict',
      secure: true,
    });
  }

  return headers;
};

export default generateDefaultHeaders;

import pipe from '@utils/pipe';

import type { LFComposerState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<LFComposerState> = ({
  lfComposer,
}: RootState) => lfComposer;

export const selectActiveDatasetType: Selector<
  LFComposerState['activeDatasetType']
> = pipe(
  selectAll,
  ({ activeDatasetType }: LFComposerState) => activeDatasetType,
);

export const selectIsLoading: Selector<LFComposerState['isLoading']> = pipe(
  selectAll,
  ({ isLoading }: LFComposerState) => isLoading,
);

export const selectIsInverted: Selector<LFComposerState['isInverted']> = pipe(
  selectAll,
  ({ isInverted }: LFComposerState) => isInverted,
);

export const selectLabel: Selector<LFComposerState['label']> = pipe(
  selectAll,
  ({ label }: LFComposerState) => label,
);

export const selectImageTool: Selector<LFComposerState['imageTool']> = pipe(
  selectAll,
  ({ imageTool }: LFComposerState) => imageTool,
);

export const selectImageToolImagePath: Selector<
  LFComposerState['imageTool']['imagePath']
> = pipe(
  selectImageTool,
  ({ imagePath }: LFComposerState['imageTool']) => imagePath,
);

export const selectImageToolBoundingBox: Selector<
  LFComposerState['imageTool']['boundingBox']
> = pipe(
  selectImageTool,
  ({ boundingBox }: LFComposerState['imageTool']) => boundingBox,
);

export const selectModelTool: Selector<LFComposerState['modelTool']> = pipe(
  selectAll,
  ({ modelTool }: LFComposerState) => modelTool,
);

export const selectModelToolIndex: Selector<
  LFComposerState['modelTool']['index']
> = pipe(selectModelTool, ({ index }: LFComposerState['modelTool']) => index);

export const selectModelToolLabel: Selector<
  LFComposerState['modelTool']['label']
> = pipe(selectModelTool, ({ label }: LFComposerState['modelTool']) => label);

export const selectThresholds: Selector<LFComposerState['thresholds']> = pipe(
  selectAll,
  ({ thresholds }: LFComposerState) => thresholds,
);

export const selectThresholdsPresentThreshold: Selector<
  LFComposerState['thresholds']['presentThreshold']
> = pipe(
  selectThresholds,
  ({ presentThreshold }: LFComposerState['thresholds']) => presentThreshold,
);

export const selectThresholdsAbsentThreshold: Selector<
  LFComposerState['thresholds']['absentThreshold']
> = pipe(
  selectThresholds,
  ({ absentThreshold }: LFComposerState['thresholds']) => absentThreshold,
);

export const selectData: Selector<LFComposerState['data']> = pipe(
  selectAll,
  ({ data }: LFComposerState) => data,
);

export const selectDataHistogramBins: Selector<
  LFComposerState['data']['histogramBins']
> = pipe(
  selectData,
  ({ histogramBins }: LFComposerState['data']) => histogramBins,
);

export const selectDataLFMetrics: Selector<
  LFComposerState['data']['lfMetrics']
> = pipe(selectData, ({ lfMetrics }: LFComposerState['data']) => lfMetrics);

export default null;

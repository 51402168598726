import { useMemo, useState } from 'react';

import { JobState } from '@api/tdm';
import Dropdown from '@coral/components/Dropdown';
import DropdownOption from '@coral/components/Dropdown/DropdownOption';
import Spinner from '@coral/components/Spinner';
import SearchInput from '@coral/prebuilts/SearchInput';
import usePollAllJobs from '@global/NavigationContainer/NavigationSidebarNav/JobsDialog/hooks/usePollAllJobs';
import JobItem from '@global/NavigationContainer/NavigationSidebarNav/JobsDialog/JobItem';
import useDebouncedValue from '@hooks/useDebouncedValue';

const jobStateToValueMap: Record<JobState, string> = {
  [JobState.Running]: 'Running',
  [JobState.Completed]: 'Completed',
  [JobState.Failed]: 'Failed',
  [JobState.Cancelled]: 'Cancelled',
};

type JobsViewProps = {
  isOpen: boolean;
};

const JobsView = ({ isOpen }: JobsViewProps) => {
  const [query, setQuery, debouncedQuery] = useDebouncedValue('');
  const [jobState, setJobState] = useState<JobState | undefined>(undefined);
  const { isLoading, jobs } = usePollAllJobs({
    jobState,
    limit: 100,
    shouldPoll: isOpen,
  });

  const filteredJobs = useMemo(
    () =>
      jobs?.filter(job => {
        if (debouncedQuery === '') {
          return true;
        }

        const caseInsensitiveQuery = debouncedQuery.toLowerCase();

        return (
          job.job_type.toLowerCase().includes(caseInsensitiveQuery) ||
          job.uid.toLowerCase().includes(caseInsensitiveQuery)
        );
      }),
    [jobs, debouncedQuery],
  );

  return (
    <div className="h-full w-full">
      {/* Header (search bar + status filter dropdown) */}
      <div className="flex w-full items-center justify-between gap-2 px-4 py-2">
        <div className="w-3/4">
          <SearchInput
            placeholder="Search by job type or job id"
            fullWidth
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        </div>
        <div className="w-1/4">
          <Dropdown<JobState | undefined>
            data-cy="jobs-view-filter-by-status"
            placeholder="Filter by status"
            value={jobState}
            onSelect={({ value }) => setJobState(value)}
          >
            <DropdownOption value={undefined}>All</DropdownOption>
            {Object.entries(jobStateToValueMap).map(([state, value]) => (
              <DropdownOption key={state} value={state}>
                {value}
              </DropdownOption>
            ))}
          </Dropdown>
        </div>
      </div>

      {/* loading state */}
      {isLoading && filteredJobs?.length === 0 ? (
        <div className="my-4 flex h-full w-full items-center justify-center gap-3">
          <Spinner />
          <span className="text-base text-gray-700">Loading jobs...</span>
        </div>
      ) : null}

      {/* empty state */}
      {!isLoading && filteredJobs?.length === 0 ? (
        <div className="my-4 flex h-full w-full items-center justify-center gap-2">
          <span className="text-base italic text-gray-600">No jobs found</span>
        </div>
      ) : null}

      {/* jobs list */}
      {filteredJobs && filteredJobs.length > 0 ? (
        <ul className="h-full w-full list-none px-4 py-2">
          {filteredJobs.map(job => (
            <JobItem key={job.uid} job={job} />
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default JobsView;

import type { RootState, Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { DatasetsState } from './types';

export const selectDatasets: Selector<DatasetsState> = ({
  datasets,
}: RootState) => datasets;

export const selectAll: Selector<DatasetsState> = selectDatasets;

export const selectDatasetsJobs: Selector<DatasetsState['jobs']> = pipe(
  selectAll,
  ({ jobs }: DatasetsState) => jobs,
);

export const selectDatasetsDatasources: Selector<DatasetsState['datasources']> =
  pipe(selectAll, ({ datasources }: DatasetsState) => datasources);

export default null;

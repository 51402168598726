import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { PlotState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<PlotState> = pipe(
  selectStudioPage,
  ({ plot }: StudioPageState) => plot,
);

type PlotSelector<T> = (state: PlotState) => T;

const plotSelector = <T>(selector: PlotSelector<T>): Selector<T> => {
  return pipe(selectAll, selector);
};

export const selectPlotMode = plotSelector(
  ({ plotMode }: PlotState) => plotMode,
);

export const selectPlotData = plotSelector(
  ({ plotData }: PlotState) => plotData,
);

export const selectFilteredPlotData = plotSelector(
  ({ filteredPlotData }: PlotState) => filteredPlotData,
);

export const selectDrawingMode = plotSelector(
  ({ drawingMode }: PlotState) => drawingMode,
);

export const selectChartPoints = plotSelector(
  ({ chartPoints }: PlotState) => chartPoints,
);

export const selectPlotFields = plotSelector(
  ({ plotFields }: PlotState) => plotFields,
);

export const selectTemplateFields = plotSelector(
  ({ templateFields }: PlotState) => templateFields,
);

export const selectFetchingDatasetOffset = plotSelector(
  ({ fetchingDatasetOffset }: PlotState) => fetchingDatasetOffset,
);

export const selectFetchComplete = plotSelector(
  ({ fetchComplete }: PlotState) => fetchComplete,
);

export const selectFilteredFetchComplete = plotSelector(
  ({ filteredFetchComplete }: PlotState) => filteredFetchComplete,
);

export const selectAutoConfigurationOptions = plotSelector(
  ({ autoConfigurationOptions }: PlotState) => autoConfigurationOptions,
);

export const selectNumericFields = plotSelector(
  ({ numericFields }: PlotState) => numericFields,
);

export const selectTruncatedNumericFields = plotSelector(
  ({ truncatedNumericFields }: PlotState) => truncatedNumericFields,
);

export const selectSelectedDataPoints = plotSelector(
  ({ selectedDataPoints }: PlotState) => selectedDataPoints,
);

export const selectPlotLFVotes = plotSelector(
  ({ plotLFVotes }: PlotState) => plotLFVotes,
);

export default null;

/* eslint-disable local-rules/no-layering-violations */
import type { AxiosResponse } from 'axios';
import type { MutatorOptions, SWRConfiguration } from 'swr';

import type { UseRequestOptions } from '@hooks/useRequest/types';
import type { Unwrap } from '@hooks/useRequest/utils/unwrap';
import type { RequestError } from '@utils/api';

export type UseCachedRequestOptions<TFallback> =
  UseRequestOptions<TFallback> & {
    useSWROptions?: SWRConfiguration;
  };

// If `TCustom` us undefined, then extract type `TPromise` from the OpenAPI request to use as the return type.
// Explicitly declaring `TCustom` is useful when `onSuccess` resolves to a different type than `TPromise`.
export type CachedData<
  TCustom,
  TPromise extends Promise<AxiosResponse<any>>,
> = TCustom extends undefined
  ? Unwrap<TPromise>['data']
  : TCustom extends unknown
  ? Unwrap<TPromise>['data']
  : TCustom;

type StandardCacheResponse = {
  error?: RequestError;
  isLoading: boolean;
  isValidating?: boolean;
};

export type CacheKeyArgs<
  TArgs extends Record<any, any>,
  TPromise extends Promise<AxiosResponse<any>>,
> = [(args: TArgs) => TPromise, UseRequestOptions, TArgs];

export type MutateArgs<TArgs> = {
  /**
   * @type {TArgs}
   * @memberof MutateArgs<TArgs>
   * `optimisticData` is the data that will be used to update the cache before the mutation has resolved.
   * Unlike `onMutateArgs`, `optimisticData` should be the entire contents of the cache, not just the part that is changing.
   */
  optimisticData?: TArgs;
  /**
   * @type {TArgs}
   * @memberof MutateArgs<TArgs>
   * `onMutateArgs` is the data that will be passed to the optional `onMutate` callback.
   */
  onMutateArgs?: TArgs;
};

export interface UseCachedRequestResult<
  TCustom,
  TPromise extends Promise<AxiosResponse<any>>,
> extends StandardCacheResponse {
  data?: CachedData<TCustom, TPromise>;
  mutate: (
    mutateArgs?: MutateArgs<CachedData<TCustom, TPromise>>,
    swrMutateOptions?: MutatorOptions<any>,
  ) => Promise<void>;
}

export const mutatorOptions: MutatorOptions = {
  populateCache: false, // Don't use the response from `onMutate` to populate the cache
  revalidate: true,
  rollbackOnError: true,
};

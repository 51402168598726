import pipe from '@utils/pipe';

import type { TagsState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll = ({ tags }: RootState) => tags;

export const selectIsTagsMenuOpen: Selector<boolean> = pipe(
  selectAll,
  ({ isTagsMenuOpen }: TagsState) => isTagsMenuOpen,
);
export const selectIsSuggestLFMenuOpen: Selector<boolean> = pipe(
  selectAll,
  ({ isSuggestLFMenuOpen }: TagsState) => isSuggestLFMenuOpen,
);
export const selectDataTags: Selector<TagsState['dataTags']> = pipe(
  selectAll,
  ({ dataTags }: TagsState) => dataTags,
);
export const selectDocDataTags: Selector<TagsState['docDataTags']> = pipe(
  selectAll,
  ({ docDataTags }: TagsState) => docDataTags,
);
export const selectCurrentTagId: Selector<number> = pipe(
  selectAll,
  ({ currentTagId }: TagsState) => currentTagId,
);

export default null;

import { useDispatch, useSelector } from 'react-redux';

import useCandidateState from '@app/StudioPage/hooks/candidateState/useCandidateState';
import { usePageRegionAlerts } from '@components/PageRegion';
import { emptyObj } from '@coral/empty';
import { setItem } from '@coral/utils/localStorage';
import type { RunningJob, SplitType } from '@core/types';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import useCachedJobPoller from '@hooks/useCachedJobPoller';
import useCachedRequest from '@hooks/useCachedRequest';
import useCurrentBatchUid from '@hooks/useCurrentBatchUid';
import { getSplitLocalStorageKey } from '@hooks/useUpdateInitialSplit';
import { datasetApi } from '@utils/api/serverRequests';

type UseStudioInitializerType = {
  setSplit: (newSplit: SplitType) => void;
  stopPolling: () => void;
  status: Partial<RunningJob>;
};

const useCachedStudioInitializer = (): UseStudioInitializerType => {
  const dispatch = useDispatch();
  const nodeId = useSelector(selectors.task.selectNodeId);
  const split = useSelector(selectors.task.selectSplit);
  const { showErrorAlert } = usePageRegionAlerts();

  const batchUid = useCurrentBatchUid();

  const { setSelected, setContextIndex } = useCandidateState();

  const { data: fetchJobIdResponse } = useCachedRequest(
    datasetApi.loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost,
    {
      split,
      nodeUid: nodeId,
      loadDatasetParams: { batch_uid: batchUid || undefined },
    },
    {
      suspendFetch: !nodeId || !split,
      useSWROptions: {
        onError: () =>
          showErrorAlert({
            message: 'Failed to initialize data',
            origin: 'useCachedStudioInitializer',
          }),
      },
    },
  );

  const { job_id: jobId } = fetchJobIdResponse ?? {};

  const { status, stopPolling } = useCachedJobPoller({
    jobUid: jobId,
    onChangeStatus: newStatus => ({
      ...newStatus,
      complete: newStatus?.state === 'completed',
    }),
  });

  const resetDataviewerState = () => {
    dispatch(actions.studioPage.dataFilters.update({ page: 0 }));
    dispatch(actions.studioPage.docFilters.update({ page: 0 }));
    setSelected([]);
    setContextIndex(0);
    dispatch(actions.data.setSelectedIndex(0));
  };

  const setStudioSplit = (newSplit: SplitType) => {
    dispatch(actions.task.setSplit(newSplit));
    setItem(getSplitLocalStorageKey(nodeId), newSplit);
  };

  const setSplit = async (newSplit: SplitType) => {
    dispatch(actions.lfs.resetLFMetrics());
    dispatch(actions.studioPage.studio.setIsInitialized(false));
    resetDataviewerState();
    setStudioSplit(newSplit);
  };

  return {
    setSplit,
    stopPolling,
    status: status || emptyObj,
  };
};

export default useCachedStudioInitializer;

import { GenericSizes } from '@components/types';
import UserProfilePicture from '@components/User/UserProfilePicture';
import Tooltip from '@coral/components/tooltips/TooltipV2';
import type { User } from '@global/state/reducers/users/types';

type UserWithProfileHorizontalProps = {
  user: User;
};

const UserWithProfileHorizontal = ({
  user: { username },
}: UserWithProfileHorizontalProps) => (
  <div className="flex w-full items-center gap-3">
    <UserProfilePicture size={GenericSizes.Small} name={username} />
    <Tooltip title={username}>
      <div className="mr-1 max-w-full overflow-hidden text-ellipsis">
        {username}
      </div>
    </Tooltip>
  </div>
);

export default UserWithProfileHorizontal;

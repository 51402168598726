import combineClasses from '@coral/utils/combineClasses';

type ListItemProps = React.HTMLAttributes<HTMLDivElement> & {
  relativeSelector?: boolean;
};

const ListItem = ({
  className,
  relativeSelector,
  children,
  ...props
}: ListItemProps) => {
  return (
    <div
      className={combineClasses(
        "[&.selected]:before:absolute [&.selected]:before:h-[35px] [&.selected]:before:w-px [&.selected]:before:rounded-r [&.selected]:before:bg-blue-100 [&.selected]:before:content-['']",
        relativeSelector
          ? '[&.selected]:before:left-[-1px]'
          : '[&.selected]:before:left-0',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default ListItem;

import type { TagType } from '@api/tdm';
import type { DEFAULT_MULTI_LABEL_KEY } from '@coral/constants';

/*
LABEL TYPES
*/

export type LabelMap = {
  [label: string]: number;
};

export type InverseLabelMap = {
  [index: number]: string;
};

type SingleLabelType = string;
type SequenceLabelType = string;

enum AnomalyDetectionClass {
  ANOMALY = 'ANOMALY',
  REGULAR = 'REGULAR',
}

export enum MultiLabelClass {
  PRESENT = 'PRESENT',
  ABSENT = 'ABSENT',
  ABSTAIN = 'ABSTAIN',
}

export const MultiLabelStatusTypes = {
  [MultiLabelClass.PRESENT]: 1,
  [MultiLabelClass.ABSENT]: 0,
  [MultiLabelClass.ABSTAIN]: -1,
};

// these are the values 1, 0, -1
export type MultiLabelStatusType =
  (typeof MultiLabelStatusTypes)[keyof typeof MultiLabelStatusTypes];

export type RawMultiLabelKey = number | typeof DEFAULT_MULTI_LABEL_KEY;
export type RawMultiLabelMap = {
  [label in RawMultiLabelKey]: MultiLabelStatusType;
};
// const exampleRawMultiLabelMap = {
//   0: MultiLabelStatusTypes[MultiLabelClass.ABSENT],
//   1: MultiLabelStatusTypes[MultiLabelClass.PRESENT],
//   2: MultiLabelStatusTypes[MultiLabelClass.ABSENT],
//   3: MultiLabelStatusTypes[MultiLabelClass.ABSTAIN],
//   _default: MultiLabelStatusTypes[MultiLabelClass.ABSTAIN],
// };

export type MultiLabelKey = string | typeof DEFAULT_MULTI_LABEL_KEY;
export type MultiLabelMap = {
  [label in MultiLabelKey]: MultiLabelClass;
};
// const exampleMultiLabelMap = {
//   Cancer: MultiLabelClass.PRESENT,
//   'Heart Disease': MultiLabelClass.ABSENT,
//   Diabetes: MultiLabelClass.ABSTAIN,
// };

export type SingleLabelUserLabel = SingleLabelType;
export type MultiLabelUserLabel = MultiLabelMap;
export type AnomalyLabelUserLabel = AnomalyDetectionClass;
export type SequenceLabelUserLabel = [number, number, SequenceLabelType]; // [charStart, charEnd, label]

export type UserLabel =
  | SingleLabelUserLabel
  | MultiLabelUserLabel
  | AnomalyLabelUserLabel
  | SequenceLabelUserLabel;

export type RawLabel = number | RawMultiLabelMap;

export type Label = RawLabel | UserLabel;

export enum LabelSortType {
  ALPHANUMERIC = 'alphanumeric',
  VOTED_FIRST = 'voted_first',
  PRESENT_FIRST = 'present_first',
  ABSENT_FIRST = 'absent_first',
}

/*
LF TYPES
*/

export enum VoteStatus {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  UNKNOWN = 'unknown',
  DEFAULT = 'default',
}

export type SimplifiedLF = { uid: number; name: string };

export type LFVote = [string, VoteStatus]; // [ lfUid, correct ]

export type LFFilterState = {
  pattern: string;
  name: string;
  authors: string[];
  labels: string[];
};

/*
TAG TYPES
*/

export type DataTagMap = {
  [x_uid: number | string]: number[];
};

export type TagProps = {
  createAndAssignTag: (dataUids: string[]) => (name: string) => void;
  onDelete: (tagTypeUid: number) => void;
  makeOnEdit: (tagTypeUid: number) => (name: string) => Promise<boolean>;
  allTags: TagType[];
  uidToTagMap: Record<string, TagType>;
  dataTags: DataTagMap;
  dataUid: string;
  handleSelect: (dataUids: string[]) => (tagTypeUid: number) => void;
  handleDeselect: (dataUids: string[]) => (tagTypeUid: number) => void;
};

export enum FieldTypes {
  Table = 'table',
  String = 'str',
  Integer = 'int',
}

export default null;

import type { PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';

import type { AccessToken, UseRequestContextState } from './UseRequestContext';
import UseRequestContext from './UseRequestContext';

type UseRequestProviderProps = PropsWithChildren<{
  accessToken: AccessToken;
  debugMode?: boolean;
  basePath: string;
}>;

const UseRequestProvider = ({
  children,
  ...initOptions
}: UseRequestProviderProps) => {
  const [{ debugMode, accessToken, basePath }, setState] =
    useState<UseRequestContextState>({
      accessToken: initOptions.accessToken,
      debugMode: initOptions.debugMode || false,
      basePath: initOptions.basePath || '',
    });

  useEffect(() => {
    setState(prev => ({ ...prev, accessToken: initOptions.accessToken }));
  }, [initOptions.accessToken]);

  const setAccessToken = (nextToken: AccessToken) =>
    setState(prev => ({ ...prev, accessToken: nextToken }));

  return (
    <UseRequestContext.Provider
      value={{ debugMode, accessToken, setAccessToken, basePath }}
    >
      {children}
    </UseRequestContext.Provider>
  );
};

export default UseRequestProvider;

import React from 'react';

import makeA11YClickEvents from '../../utils/makeA11YClickEvents';
import Checkbox from '../Checkbox';

type DropdownItemProps = {
  onSelect: VoidFunction;
  selected: boolean;
  children: React.ReactNode;
  active: boolean;
  id: string;
  title?: string;
  multiSelect: boolean;
  hasCustomRender: boolean;
  disableUnselectedOptions?: boolean;
  dataCy?: string;
  isDisabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const DropdownItem = (props: DropdownItemProps) => {
  const {
    children,
    selected,
    onSelect,
    active,
    id,
    title,
    multiSelect,
    hasCustomRender,
    disableUnselectedOptions,
    dataCy,
    isDisabled = false,
    className = '',
    style = {},
  } = props;

  const showMultiSelectUi = multiSelect && !hasCustomRender;

  const { onKeyPress, ...onSelectProps } = makeA11YClickEvents(
    () => {
      if (isDisabled) return;
      onSelect();
    },
    {
      preventDefault: multiSelect,
      stopPropagation: multiSelect,
    },
  );

  const disabled = isDisabled || (!selected && disableUnselectedOptions);

  const getClassName = () => {
    if (selected && !showMultiSelectUi) {
      return 'bg-gray-200';
    }

    if (disabled) {
      return 'text-gray-400 cursor-not-allowed';
    }

    if (active) {
      return 'bg-gray-100';
    }

    return 'hover:bg-gray-100';
  };

  return (
    <li
      id={id}
      {...onSelectProps}
      onKeyDown={onKeyPress}
      tabIndex={isDisabled ? undefined : onSelectProps.tabIndex}
      title={title || (typeof children === 'string' ? children : '')}
      className={`${getClassName()} select-none truncate px-3 py-2 first:rounded-t last:rounded-b ${className}`}
      aria-selected={selected}
      aria-current={active}
      aria-disabled={disabled}
      role="option"
      data-cy={dataCy}
      style={style}
    >
      {showMultiSelectUi ? (
        <Checkbox
          readOnly
          className="pointer-events-none"
          tabIndex={-1}
          checked={selected}
          disabled={disabled}
        >
          <span className="ml-2 text-xs">{children}</span>
        </Checkbox>
      ) : (
        children
      )}
    </li>
  );
};

export default DropdownItem;

import getHeightClass, { HeightSizes } from './getHeightClass';
import getInputStyles from './getInputStyles';

import type { InputSizes, InputVariant } from '../../InputContainer/types';

type GetInputClassNameArgs = {
  size: InputSizes;
  error?: string | boolean;
  className?: string;
  variant?: InputVariant;
};

const getInputClassName = (args: GetInputClassNameArgs) => {
  const { size, error, className = '', variant = 'default' } = args;

  return `leading-4 rounded text-sm w-full px-3 ${getHeightClass(
    HeightSizes[size],
  )} ${getInputStyles({
    size,
    error,
    variant,
  })} disabled:bg-gray-200 disabled:text-black ${className}`;
};

export default getInputClassName;

import React from 'react';

export type ProgressBarProps = {
  value: number;
  className?: string;
};

const ProgressBar = ({ value, className }: ProgressBarProps) => (
  <div className={`relative h-2 overflow-hidden rounded ${className}`}>
    <div className="absolute left-0 top-0 h-2 w-full bg-navy-600" />
    <div
      className="absolute top-0 h-2 w-full rounded bg-navy-100 transition-all duration-200"
      style={{ marginLeft: `-${100 - value}%` }}
    />
  </div>
);

export default ProgressBar;

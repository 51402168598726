import { isNil } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import type { JobInfo } from '@api/tdm';
import { JobState } from '@api/tdm';
import { usePageRegionAlerts } from '@components/PageRegion';
import Spinner from '@components/Spinner';
import Button, { ButtonSizes } from '@coral/components/Button';
import { Icons } from '@coral/components/Icon';
import ProgressBar from '@coral/components/ProgressBar';
import Text from '@coral/components/Text';
import Tooltip from '@coral/components/tooltips/TooltipV2';
import IconButton from '@coral/prebuilts/IconButton';
import * as selectors from '@global/state/selectors';
import useRequest from '@hooks/useRequestWithLogging';
import { jobsApi, logsApi } from '@utils/api/serverRequests';
import convertToTimeAgo from '@utils/convertToTimeAgo';
import formatLogs from '@utils/formatLogs';
import { normalizeUrl } from '@utils/normalizeUrl';
import saveAsFile from '@utils/saveAsFile';

type JobItemProps = { job: JobInfo };

const JobItem = ({ job }: JobItemProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const request = useRequest();
  const { showErrorAlert } = usePageRegionAlerts();
  const users = useSelector(selectors.users.selectUsers);
  const jobUser = users.find(user => user.user_uid === job.user_uid);

  const handleJobCancel = async () => {
    await request(jobsApi.cancelJobJobsJobUidCancelPost, { jobUid: job.uid });
  };

  const downloadLogs = async () => {
    try {
      setIsDownloading(true);
      const data = await request(
        logsApi.getJobLogsLogsJobJobIdGet,
        {
          jobId: job.uid.substring(3),
        },
        {
          surfaceError: true,
        },
      );

      const bodyText = formatLogs(data || '');
      saveAsFile(bodyText, `Snorkel_Flow_Logs_${job.uid}.txt`);
    } catch (e) {
      showErrorAlert({
        message: 'Download failed due to missing permissions',
        origin: 'JobItem',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const shouldShowCancelButton = job.state === JobState.Running;

  const renderJobType = () => (
    <div className="flex w-4/12 flex-col gap-2 truncate">
      <span className="text-xs text-gray-600">Job type</span>
      <Text className="text-xs" truncate>
        {job.job_type}
      </Text>
    </div>
  );

  const renderStartedAt = () => (
    <div className="flex w-2/12 flex-col gap-2">
      <span className="text-xs text-gray-600">Started</span>
      <span>
        <Tooltip
          title={
            isNil(job.enqueued_time)
              ? ''
              : new Date(`${job.enqueued_time}Z`).toLocaleString()
          }
        >
          <span className="text-xs">
            {convertToTimeAgo({
              time: job.enqueued_time,
              fallback: '-',
            })}
          </span>
        </Tooltip>
      </span>
    </div>
  );

  const renderEndedAt = () => (
    <div className="flex w-2/12 flex-col gap-2">
      <span className="text-xs text-gray-600">Ended</span>
      <span>
        <Tooltip
          title={
            isNil(job.end_time)
              ? ''
              : new Date(`${job.end_time}Z`).toLocaleString()
          }
        >
          <span className="text-xs">
            {convertToTimeAgo({
              time: job.end_time,
              fallback: '-',
            })}
          </span>
        </Tooltip>
      </span>
    </div>
  );

  const renderJobUser = () => (
    <div className="flex w-2/12 flex-col gap-2">
      <span className="text-xs text-gray-600">Started by</span>
      <span className="text-xs">{jobUser?.username}</span>
    </div>
  );

  const renderJobPercent = () => (
    <div className="flex items-center gap-2">
      <ProgressBar value={job.percent || 0} />
      <Text className="text-xs">{`${job.percent || 0}%`}</Text>
    </div>
  );

  const renderJobProgress = () => (
    <div className="flex flex-1 text-xs">
      {job.message || `Job ${job.state}`}
    </div>
  );

  const renderDownloadButton = () =>
    isDownloading ? (
      <div className="px-2">
        <Spinner />
      </div>
    ) : (
      <IconButton
        tooltip="Download logs"
        onClick={downloadLogs}
        icon={Icons.DOWNLOAD}
        className="flex-none"
      />
    );

  const renderCancelButton = () =>
    shouldShowCancelButton ? (
      <Button
        onClick={handleJobCancel}
        data-cy="job-cancel-button"
        size={ButtonSizes.small}
        filled={false}
      >
        Cancel
      </Button>
    ) : null;

  return (
    <li className="w-full list-none border" data-cy="job-item">
      <div className="flex items-center gap-2 px-8">
        <div
          className="my-2 flex w-full items-center gap-3"
          data-cy="job-close-state"
        >
          {renderJobType()}
          {renderStartedAt()}
          {renderEndedAt()}
          {renderJobUser()}
          <div className="flex w-5/12 flex-col gap-2">
            {renderJobPercent()}
            <div className="flex items-center justify-between">
              {renderJobProgress()}
              <div className="flex items-center gap-2">
                <IconButton
                  tooltip="View raw logs"
                  href={normalizeUrl(`/jobs/${job.uid}/logs`)}
                  icon={Icons.LAUNCH}
                  className="flex-none"
                />
                {renderDownloadButton()}
                {renderCancelButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default JobItem;

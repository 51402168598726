import * as Accordion from '@radix-ui/react-accordion';
import React, { useState } from 'react';

import combineClasses from '@coral/utils/combineClasses';

import Icon, { Icons } from '../../Icon';
import type { DefaultAccordionProps } from '../types';

const DEFAULT_ACCORDION_ID = 'item-1';

const DefaultAccordion = ({
  summary,
  children,
  divideSummary,
  onClick,
  iconStart,
  open,
  enablePadding = true,
  'data-cy': dataCy,
}: DefaultAccordionProps) => {
  const controlledDefaultValue = open ? DEFAULT_ACCORDION_ID : undefined;

  const [internalValue, setInternalValue] = useState<string | undefined>(
    controlledDefaultValue,
  );

  const renderIcon = () => {
    return (
      <Icon
        name={
          internalValue === DEFAULT_ACCORDION_ID
            ? Icons.CHEVRON__DOWN
            : Icons.CHEVRON__RIGHT
        }
        className="mr-2"
      />
    );
  };

  return (
    <Accordion.Root
      data-cy="default-accordion-root"
      type="single"
      defaultValue={internalValue}
      collapsible
      value={internalValue}
      onValueChange={value => {
        setInternalValue(value);
        onClick?.();
      }}
    >
      <Accordion.Item
        data-cy="default-accordion-item"
        value={DEFAULT_ACCORDION_ID}
      >
        <Accordion.Trigger
          data-cy={dataCy ?? 'default-accordion-trigger'}
          className={combineClasses(
            iconStart ? 'justify-start' : 'justify-between',
            'flex w-full cursor-pointer items-center px-4 py-2',
          )}
        >
          {iconStart ? (
            <>
              {renderIcon()}
              <div className="default-accordion-summary">{summary}</div>
            </>
          ) : (
            <>
              <div className="default-accordion-summary">{summary}</div>
              {renderIcon()}
            </>
          )}
        </Accordion.Trigger>
        {divideSummary ? (
          <div data-cy="divider" className="w-full border-b px-4" />
        ) : null}

        <Accordion.Content
          data-cy="default-accordion-content"
          className={combineClasses(enablePadding ? 'px-4' : '', 'pb-4')}
        >
          {children}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};

export default DefaultAccordion;

import type { ButtonProps } from '@coral/components/Button';
import type { Nominal } from '@coral/types/nominal';

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export enum NotificationDuration {
  DEFAULT = 8_000,
  TIMEOUT = 60_000,
}

export enum NotificationVariant {
  info = 'info',
  warning = 'warning',
  success = 'success',
  error = 'error',
  progress = 'progress',
}

type ErrorMetaDataKeys =
  | 'status_code'
  | 'endpoint'
  | 'pathname'
  | 'host'
  | 'details'
  | 'traceback';

export type ErrorMetaData = Partial<Record<ErrorMetaDataKeys, string>>;

export type NotificationId = Nominal<string, 'NotificationId'>;

type CoreProps = {
  variant: NotificationVariant;
  message: string;
  uid: NotificationId;
  origin: string;
};

export type WithoutVariant<T> = Omit<T, 'variant'>;
export type OptionalUid<T extends CoreProps> = Optional<T, 'uid'>;

export type InfoAlertProps = CoreProps & {
  variant: NotificationVariant.info;
};

export type WarningAlertProps = CoreProps & {
  variant: NotificationVariant.warning;
};

export type SuccessAlertProps = CoreProps & {
  variant: NotificationVariant.success;
  title?: string;
  buttonAction?: ButtonProps;
};

export type ErrorAlertProps = CoreProps & {
  variant: NotificationVariant.error;
  metaData?: ErrorMetaData;
};

export type ProgressNotificationProps = CoreProps & {
  variant: NotificationVariant.progress;
  percent: number | undefined;
  buttonAction?: ButtonProps;
};

export type AlertNotificationProps =
  | InfoAlertProps
  | WarningAlertProps
  | SuccessAlertProps
  | ErrorAlertProps;

export type NotificationItem =
  | AlertNotificationProps
  | ProgressNotificationProps;

export type NotificationsState = NotificationItem[];

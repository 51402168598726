import HorizontalNavigation from '@components/Navigation/HorizontalNavigation';
import SnorkelLink from '@components/SnorkelLink';
import Tooltip from '@coral/components/tooltips/Tooltip';
import useBreadcrumb from '@global/NavigationContainer/hooks/useBreadcrumb';
import NotificationIndicator from '@global/NavigationContainer/NavigationSidebarNav/NavList/NotificationIndicator';
import useIsCurrentAppOnboardingEnabled from '@utils/useIsCurrentAppOnboardingEnabled';

export const NavigationBreadcrumbId = 'navigation-breadcrumb';

const BREADCRUMB_MAX_LENGTH = 80;

const NavigationBreadcrumb = () => {
  const isOnboardingEnabled = useIsCurrentAppOnboardingEnabled();
  const nav = useBreadcrumb({ isOnboardingEnabled });

  return (
    <HorizontalNavigation id={NavigationBreadcrumbId}>
      {nav.map(({ href, title: routeName, notificationIndicator }) => (
        <SnorkelLink key={href} href={`/${href}`}>
          {/* eslint-disable-next-line */}
          <a>
            <Tooltip title={routeName}>
              {(routeName || '').length > BREADCRUMB_MAX_LENGTH
                ? `${(routeName || '').substring(0, BREADCRUMB_MAX_LENGTH)}...`
                : routeName}
              {notificationIndicator && <NotificationIndicator />}
            </Tooltip>
          </a>
        </SnorkelLink>
      ))}
    </HorizontalNavigation>
  );
};

export default NavigationBreadcrumb;

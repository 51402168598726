import type { AxiosResponse } from 'axios';

import type { RequestError, UseRequestOptions } from '../types';

const NON_NETWORK_ERROR_MESSAGE = 'non-network-request-error';

// unit tests provide incomplete URLs, so let's fix them:
const normalizeUrl = (url: string): string => {
  if (url.startsWith('http')) return url;
  if (url.startsWith('//')) return `https:${url}`;

  return `https://snorkel.ai${url}`;
};

const calcUrlInfoFromResponse = response => {
  const url =
    (response as Response)?.url ??
    (response as AxiosResponse)?.config?.url ??
    '';

  const { host = '', pathname = '' } = url ? new URL(normalizeUrl(url)) : {};

  return { host, pathname };
};

const calcEndpointFromPathname = (pathname: string) => {
  return pathname.length === 0
    ? NON_NETWORK_ERROR_MESSAGE
    : pathname.replace(/\d+/g, '[num]');
};

const getNotificationMetaData = (
  e: RequestError,
  options: UseRequestOptions,
) => {
  const { host, pathname } = calcUrlInfoFromResponse(e.response);
  const endpoint = calcEndpointFromPathname(pathname);

  const detail = e.body?.detail;
  const traceback = e.body?.metadata?.traceback;

  const metaData: Record<string, string> = {
    status_code: `${e.status}`,
    endpoint,
  };

  if (pathname.length > 0) {
    metaData.pathname = pathname;
  }

  if (host.length > 0) {
    metaData.host = host;
  }

  if (options.debugMode && detail) {
    metaData.details = detail ?? '';
  }

  if (options.debugMode && traceback) {
    metaData.traceback = traceback;
  }

  return metaData;
};

export default getNotificationMetaData;

import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { SimilarityLfBuilderState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<SimilarityLfBuilderState> = pipe(
  selectStudioPage,
  ({ similarityLfBuilder }: StudioPageState) => similarityLfBuilder,
);

export const selectSimilarityLfState: Selector<
  SimilarityLfBuilderState['similarityLfState']
> = pipe(
  selectAll,
  ({ similarityLfState }: SimilarityLfBuilderState) => similarityLfState,
);

export const selectSimilarityLfStateThreshold: Selector<number | null> = pipe(
  selectSimilarityLfState,
  (state: SimilarityLfBuilderState['similarityLfState']) =>
    state?.threshold ?? null,
);

export const selectSimilarityLfStateFractionGallerySelected: Selector<
  number | undefined
> = pipe(
  selectSimilarityLfState,
  (state: SimilarityLfBuilderState['similarityLfState']) =>
    state?.fractionGallerySelected,
);

export const selectLastSearchConfigProperties: Selector<
  SimilarityLfBuilderState['lastSearchConfigProperties']
> = pipe(
  selectAll,
  ({ lastSearchConfigProperties }: SimilarityLfBuilderState) =>
    lastSearchConfigProperties,
);

export const selectHasSimilarityLfTemplate: Selector<boolean> = pipe(
  selectLastSearchConfigProperties,
  ({
    hasSimilarityLfTemplate,
  }: SimilarityLfBuilderState['lastSearchConfigProperties']) =>
    hasSimilarityLfTemplate,
);

export const selectLastSearchConfigPropertiesHasUnconfiguredSimilarityLf: Selector<boolean> =
  pipe(
    selectLastSearchConfigProperties,
    ({
      hasUnconfiguredSimilarityLf,
    }: SimilarityLfBuilderState['lastSearchConfigProperties']) =>
      hasUnconfiguredSimilarityLf,
  );

export const selectLastSearchConfigPropertiesIsNegated: Selector<
  SimilarityLfBuilderState['lastSearchConfigProperties']['isNegated']
> = pipe(
  selectLastSearchConfigProperties,
  ({ isNegated }: SimilarityLfBuilderState['lastSearchConfigProperties']) =>
    isNegated,
);

export default null;

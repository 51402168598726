import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { JobState } from '@api/tdm';
import { usePageRegionAlerts } from '@components/PageRegion';
import useGetStatus from '@components/RunningJobsTable/RunningJobRow/hooks/useGetStatus';
import type { RunningJob } from '@core/types';
import * as actions from '@global/state/actions';
import type { NotificationId } from '@global/state/reducers/notifications/types';
import useJobFailureNotification from '@hooks/useJobFailureNotification';

type JobProgressNotificationProps = {
  uuid: string;
  job?: RunningJob;
  onComplete: (job: RunningJob) => void;
  onCompleteMessage?: string;
};

// Empty component for polling Job and displaying progress bar in notification
const JobProgressNotification = ({
  job,
  uuid,
  onComplete,
  onCompleteMessage,
}: JobProgressNotificationProps) => {
  const dispatch = useDispatch();
  const { jobFailureNotification } = useJobFailureNotification();

  const initialJobValue: RunningJob = job ?? {
    uid: uuid,
    message: 'Loading job status...',
    percent: 0,
  };

  const [status] = useGetStatus(
    initialJobValue,
    uuid,
    onComplete,
    () => {},
    true,
  );

  const { showSuccessAlert, showWarningAlert } = usePageRegionAlerts();

  useEffect(() => {
    if (!uuid) return () => {};

    const { message = '' } = initialJobValue;
    dispatch(
      actions.notifications.showProgressBarNotification({
        uid: uuid as NotificationId,
        message,
        percent: 0,
        origin: 'JobProgressNotification',
      }),
    );

    return () => dispatch(actions.notifications.remove(uuid as NotificationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (isEmpty(status)) return;

    const { uid, message = '', percent, state } = status;

    if (state === JobState.Completed) {
      showSuccessAlert({
        message: onCompleteMessage ?? message,
        origin: 'JobProgressNotification',
      });

      if (status.detail?.warning) {
        showWarningAlert({
          message: status.detail.warning,
          origin: 'JobProgressNotification',
        });
      }

      dispatch(actions.notifications.remove(uid as NotificationId));
    } else if (state === JobState.Failed || state === JobState.Cancelled) {
      jobFailureNotification({
        ...status,
        message: onCompleteMessage ?? message,
      });
      dispatch(actions.notifications.remove(uid as NotificationId));
    } else {
      dispatch(
        actions.notifications.updateProgressBarNotification({
          uid: uid as NotificationId,
          message,
          percent,
          origin: 'JobProgressNotification',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(status)]);

  return null;
};

export default JobProgressNotification;

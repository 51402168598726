/* eslint-disable local-rules/require-data-cy */
import { Button } from '@material-ui/core';

import Icon, { Icons } from '@coral/components/Icon';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { normalizeUrl } from '@utils/normalizeUrl';

type props = {
  location: string;
  buttonText: string;
  openInNewTab: boolean;
  lightText: boolean;
};

const NotebookDocsButton = ({
  location,
  buttonText,
  openInNewTab,
  lightText,
}: props) => {
  const router = useSnorkelRouter();

  const handleOpen = () => {
    router.push(location);
  };

  const descriptionClassNames = `${lightText ? 'text-gray-300' : ''} mr-2`;

  return (
    <Button
      color={lightText ? 'secondary' : 'default'}
      className={lightText ? 'text-gray-300' : ''}
      {...(openInNewTab
        ? {
            href: location,
            target: '_blank',
          }
        : {
            onClick: handleOpen,
          })}
      href={normalizeUrl(location)}
      target="_blank"
    >
      <Icon name={Icons.DOCUMENTATION} className={descriptionClassNames} />{' '}
      {buttonText}
    </Button>
  );
};

NotebookDocsButton.defaultProps = {
  location: '/user_guide',
  buttonText: 'Docs',
  openInNewTab: true,
  lightText: true,
};

export default NotebookDocsButton;

import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useCallback } from 'react';

import getIsServerRendered from '@utils/getIsServerRendered';
import makeGetAccessToken, {
  shouldRenewToken,
} from '@utils/makeGetAccessToken';
import { defaultSupportedFeatures } from '@utils/supportedFeatures';

import { TIMEOUT_ERROR_MSG } from './constants';
import useMakeOnRequestFailed from './hooks/useMakeOnRequestFailed';
import useUseRequestContext from './hooks/useUseRequestContext';
import type { RequestError, UseRequestOptions } from './types';
import generateAPIUrl from './utils/generateAPIUrl';
import generateDefaultHeaders from './utils/generateDefaultHeaders';
import parseJWTFromAccessToken from './utils/parseJWTFromAccessToken';
import type { Unwrap } from './utils/unwrap';

const getErrorThreshold = () => {
  const errorThreshold =
    typeof window === 'undefined' ? 0 : (window as any).sf?.errorThreshold;

  return errorThreshold ?? 0;
};

const useRequest = showErrorAlert => {
  const {
    setAccessToken,
    basePath,
    accessToken: { accessToken, expiry },
  } = useUseRequestContext();

  const makeOnRequestFailed = useMakeOnRequestFailed(showErrorAlert);

  return useCallback(
    async <
      TArgs extends Record<any, any>,
      TPromise extends Promise<AxiosResponse<any>>,
    >(
      openApiPromise: (args: TArgs) => TPromise,
      args: TArgs,
      options: UseRequestOptions<Unwrap<TPromise>['data']> = {},
      isAutoRefresh = false,
    ): Promise<Unwrap<TPromise>['data']> => {
      const onRequestFailed = makeOnRequestFailed(options);

      if (
        accessToken &&
        !getIsServerRendered() &&
        !isAutoRefresh &&
        shouldRenewToken({
          accessToken,
          expiry,
          supportedFeatures: defaultSupportedFeatures,
        })
      ) {
        const getAccessToken = makeGetAccessToken();
        const newToken = await getAccessToken(null);
        setAccessToken(newToken);
      }

      try {
        const that = {
          basePath: generateAPIUrl({ basePath }),
          axios,
          configuration: {
            baseOptions: {
              headers: generateDefaultHeaders(accessToken),
              validateStatus: () => true,
              signal: options.signal,
            },
          },
        };

        const result = await openApiPromise.call(that, args);

        if (Math.floor(Math.random() * 100) < getErrorThreshold()) {
          result.status = 542;
        }

        const { data, headers } = result;

        if (headers.authorization) {
          try {
            setAccessToken({
              accessToken: headers.authorization,
              expiry: parseJWTFromAccessToken(headers.authorization).exp,
            });
          } catch (e: any) {
            // do nothing
          }
        }

        if (result.status >= 400) {
          const error: RequestError = Object.assign(new Error(), {
            response: {
              ...result,
              config: {
                ...result.config,
                headers: {
                  ...result.config.headers,
                  authorization: '',
                },
              },
            },
            type: 'api-error',
            status: result.status,
            statusText: result.statusText,
            body: {
              user_friendly_message:
                result.status === 408
                  ? TIMEOUT_ERROR_MSG
                  : data?.user_friendly_message,
              detail: data?.detail,
              metadata: data?.metadata,
            },
          });

          return onRequestFailed(error);
        }

        const dataToReturn =
          options && options.onSuccess ? options.onSuccess(data) ?? data : data;

        if (options && options.includeHeaders) {
          return {
            data: dataToReturn,
            headers,
          };
        }

        return dataToReturn;
      } catch (e: any) {
        return onRequestFailed(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken],
  );
};

export default useRequest;

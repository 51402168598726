import React from 'react';

import getFlag, { Flags } from '@utils/getFlag';
import { normalizeUrl } from '@utils/normalizeUrl';

type LogoProps = {
  theme?: 'dark' | 'light';
  svg?: boolean;
};

const Logo = ({ theme = 'light', svg }: LogoProps) => {
  const isNewHomepageEnabled = getFlag(Flags.HOME_PAGE_DESIGN_REFRESH);

  const homepageUrl = normalizeUrl(isNewHomepageEnabled ? '/home' : '/');

  const imageUrl =
    theme === 'light'
      ? '/static/logo.ico'
      : `/static/logo_dark.${svg ? 'svg' : 'png'}`;

  const size = svg ? { width: '61px' } : { width: '110px' };

  return (
    <div className="flex flex-none items-center">
      <a href={homepageUrl}>
        <img src={normalizeUrl(imageUrl)} style={size} alt="Snorkel Logo" />
      </a>
    </div>
  );
};

export default Logo;

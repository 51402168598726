enum Icons {
  CSV = 'CSV',
  DSL = 'DSL',
  RAW = 'RAW',
  SQL = 'SQL',
  X__IN_CIRCLE = 'X--In-circle',
  X__PRIMARY = 'X--primary',
  X = 'X',
  A_OUTLINE = 'a-outline',
  ACTIVITY = 'activity',
  ADD__PRIMARY = 'add--primary',
  ADD_COMMENT = 'add-comment',
  ADD = 'add',
  AI_BRAIN = 'ai-brain',
  ANALYSIS = 'analysis',
  ARCHIVE = 'archive',
  ARROW__DOWN_LEFT = 'arrow--down-left',
  ARROW__DOWN_RIGHT = 'arrow--down-right',
  ARROW__DOWN = 'arrow--down',
  ARROW__LEFT = 'arrow--left',
  ARROW__RIGHT = 'arrow--right',
  ARROW__UP_LEFT = 'arrow--up-left',
  ARROW__UP_RIGHT = 'arrow--up-right',
  ARROW__UP = 'arrow--up',
  ARROW_SQUARE_LEFT = 'arrow-square-left',
  ARROW_SQUARE_RIGHT = 'arrow-square-right',
  ARROW_SQUARE_UP = 'arrow-square-up',
  ARROW_SUB_DOWN_RIGHT = 'arrow-sub-down-right',
  ATTENTION__ERROR = 'attention--error',
  ATTENTION__WARNING = 'attention--warning',
  ATTENTION = 'attention',
  BATCH = 'batch',
  BLOCK = 'block',
  CALENDAR = 'calendar',
  CANCEL = 'cancel',
  CARET__DOWN__THIN = 'caret--down--thin',
  CARET__DOWN = 'caret--down',
  CARET__LEFT = 'caret--left',
  CARET__RIGHT = 'caret--right',
  CARET__UP__THIN = 'caret--up--thin',
  CARET__UP = 'caret--up',
  CHECK = 'check',
  CHECKMARK__IN_CIRCLE__FILLED = 'checkmark--In-circle--filled',
  CHECKMARK__IN_CIRCLE__SUCCESS = 'checkmark--In-circle--success',
  CHECKMARK__IN_CIRCLE = 'checkmark--In-circle',
  CHECKMARK__FAT = 'checkmark--fat',
  CHECKMARK__THIN = 'checkmark--thin',
  CHEVRON__DOWN = 'chevron--down',
  CHEVRON__LEFT = 'chevron--left',
  CHEVRON__RIGHT = 'chevron--right',
  CHEVRON__SORT = 'chevron--sort',
  CHEVRON__UP = 'chevron--up',
  CLOUD_STORAGE = 'cloud-storage',
  COLLAPSE__LEFT = 'collapse--left',
  COLLAPSE__RIGHT = 'collapse--right',
  COLLAPSE_ALL = 'collapse-all',
  COLOR_PALETTE = 'color-palette',
  COMMENT_1 = 'comment-1',
  COMMENT_2 = 'comment-2',
  COMMENT = 'comment',
  COMPLETE = 'complete',
  CONFUSION_MATRIX = 'confusion-matrix',
  COPY__FILE = 'copy--file',
  COPY = 'copy',
  DATA__INTEGER = 'data--integer',
  DATA__SEMISTRUCTURED = 'data--semistructured',
  DATA__TEXT = 'data--text',
  DATABASE = 'database',
  DATABRICKS = 'databricks',
  DATASET = 'dataset',
  DELETE = 'delete',
  DEPLOY = 'deploy',
  DEVELOPMENT = 'development',
  DEVICES = 'devices',
  DOCUMENT_SENTIMENT = 'document-sentiment',
  DOCUMENTATION = 'documentation',
  DOWN = 'down',
  DOWNLOAD = 'download',
  DRAG__HORIZONTAL = 'drag--horizontal',
  DRAG__VERTICAL = 'drag--vertical',
  DRAGGABLE__HORIZONTAL = 'draggable--horizontal',
  DRAGGABLE__VERTICAL = 'draggable--vertical',
  DRAGGABLE = 'draggable',
  EDIT = 'edit',
  ENTERPRISE = 'enterprise',
  ERROR = 'error',
  EVENT__SCHEDULE = 'event--schedule',
  EXPAND_ALL = 'expand-all',
  EXPORT = 'export',
  FIELDS = 'fields',
  FILE_UPLOAD = 'file-upload',
  FILTER_FILLED = 'filter-filled',
  FILTER_OUTLINE = 'filter-outline',
  FILTER_REMOVE = 'filter-remove',
  FILTER = 'filter',
  FINANCE = 'finance',
  FOLDERS = 'folders',
  FSL = 'fsl',
  GLOBE = 'globe',
  GOOGLE_BIGQUERY = 'google-bigquery',
  GRID = 'grid',
  GROUND_TRUTH = 'ground-truth',
  GROUP = 'group',
  HEALTH_CROSS = 'health-cross',
  HELP = 'help',
  HOUSE = 'house',
  HTML = 'html',
  IMAGE = 'image',
  INFORMATION__NORMAL = 'information--normal',
  INFORMATION__PRIMARY = 'information--primary',
  INTEGRATION = 'integration',
  LABEL_UNKNOWN = 'label-unknown',
  LAUNCH = 'launch',
  LF_TEMPLATE = 'lf-template',
  LIGHTNING__BOLT = 'lightning--bolt',
  LIGHTNING_BOLT_BLUE_FILLED = 'lightning-bolt-blue-filled',
  LIGHTNING_BOLT_BLUE = 'lightning-bolt-blue',
  LIGHTNING_BOLT_GRAY = 'lightning-bolt-gray',
  LINEBREAK = 'lineBreak',
  LIST_CHECK = 'list-check',
  LOADING__ERROR = 'loading--error',
  LOADING = 'loading',
  MAGIC_WAND = 'magic-wand',
  MAXIMIZE = 'maximize',
  MENU = 'menu',
  MERGE = 'merge',
  MINIMIZE = 'minimize',
  MODEL__2 = 'model--2',
  MODEL = 'model',
  MULTI_SELECT = 'multi-select',
  NETWORK__1 = 'network--1',
  NETWORK__2 = 'network--2',
  NETWORK__3__REFERENCE = 'network--3--reference',
  NETWORK__3 = 'network--3',
  NETWORK__4 = 'network--4',
  NEWLINE = 'newLine',
  NOMINAL = 'nominal',
  NOTEBOOK = 'notebook',
  NOTIFICATION = 'notification',
  OPEN_AI_LOGO = 'open-ai-logo',
  OVERFLOW_MENU__HORIZONTAL = 'overflow-menu--horizontal',
  OVERFLOW_MENU__VERTICAL = 'overflow-menu--vertical',
  OVERVIEW = 'overview',
  P_FILLED = 'p-filled',
  PACKAGE = 'package',
  PARENT_CHILD = 'parent-child',
  PASSWORD = 'password',
  PASTE = 'paste',
  PLOT = 'plot',
  PRCURVE = 'prCurve',
  PRIMARY_PLAY = 'primary-play',
  REGEX = 'regex',
  RESET = 'reset',
  RESULT_METRICS = 'result-metrics',
  SAFE = 'safe',
  SATELLITE__RADAR = 'satellite--radar',
  SAVE = 'save',
  SCHEMATICS = 'schematics',
  SEARCH = 'search',
  SETTINGS__ADJUST = 'settings--adjust',
  SETTINGS = 'settings',
  SHOPPING__CART = 'shopping--cart',
  SHUFFLE = 'shuffle',
  SLASH = 'slash',
  SNOWFLAKE = 'snowflake',
  SORT__ASCENDING = 'sort--ascending',
  SORT__DESCENDING = 'sort--descending',
  SPAN = 'span',
  SPLIT = 'split',
  STANDBY = 'standby',
  STRICT_LOCATION = 'strict-location',
  SUBTRACT_CIRCLE_FAILURE = 'subtract-circle-failure',
  SUBTRACT_CIRCLE = 'subtract-circle',
  SUBTRACT = 'subtract',
  TABLE_1 = 'table-1',
  TABLE_2 = 'table-2',
  TABLE = 'table',
  TAG__GROUP = 'tag--group',
  TAG = 'tag',
  TARGET = 'target',
  TEMPLATE_1 = 'template-1',
  TEMPLATE = 'template',
  TEXT__HIGHLIGHT = 'text--highlight',
  TIME = 'time',
  TRUSTED = 'trusted',
  UMBRELLA = 'umbrella',
  UNKNOWN__IN_CIRCLE = 'unknown--In-circle',
  UNKNOWN = 'unknown',
  UP = 'up',
  UPLOAD = 'upload',
  USER__ADMIN = 'user--admin',
  USER = 'user',
  VECTOR = 'vector',
  VIEW = 'view',
  VISIBLE = 'visible',
  WARNING_SQUARE_YELLOW = 'warning-square-yellow',
  WARNING_SQUARE = 'warning-square',
  WARNING = 'warning',
  WARNING_SQUARE_BLACK = 'warning_square_black',
  WARNING_TRIANGLE = 'warning_triangle',
  WORKFLOW = 'workflow',
  YES_NO = 'yes-no',
  ZSL = 'zsl',
}

export default Icons;

import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setGlobalAccessToken } from '@global/AccessTokenManager';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import { normalizeUrl } from '@utils/normalizeUrl';
import setWindowLocationHref from '@utils/setWindowLocationHref';

const useUserLogout = () => {
  const dispatch = useDispatch();
  const isLoggingOut = useSelector(selectors.auth.selectIsLoggingOut);
  const user = useSelector(selectors.auth.selectUser);

  const logout = useCallback(async () => {
    if (isLoggingOut) return;

    if (!!user?.user_uid || !!user) {
      dispatch(actions.auth.setIsLoggingOut(true));
      setGlobalAccessToken(null);

      await fetch(normalizeUrl(`/jupyterhub/user/${user.username}/logout`), {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
        redirect: 'manual',
      });

      await fetch(normalizeUrl('/jupyterhub/hub/logout'), {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
        redirect: 'manual',
      });

      Cookies.remove('oauthenticator-state', { path: '/' });
      Cookies.remove(`jupyterhub-user-${user.username}`, {
        path: `/jupyterhub/user/${user.username}/`,
      });
      Cookies.remove('jupyterhub-session-id', { path: '/jupyterhub/' });
      Cookies.remove('jupyterhub-hub-login', { path: '/jupyterhub/hub/' });

      dispatch(actions.auth.setIsLoggingOut(false));
      setWindowLocationHref(normalizeUrl('/logout'));
    } else {
      setGlobalAccessToken(null);

      setWindowLocationHref(normalizeUrl('/logout'));
    }
  }, [dispatch, isLoggingOut, user]);

  return logout;
};

export default useUserLogout;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useGetObjectStorageUrl from '@global/NavigationContainer/NavigationSidebarFooter/ResourcesMenu/hooks/useGetObjectStorageUrl';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';

const useSetObjectStorageUrlToNavMeta = () => {
  const dispatch = useDispatch();
  const objectStorageUrl = useSelector(
    selectors.navigation.selectMetaObjectStorageUrl,
  );
  const { getObjectStorageUrl } = useGetObjectStorageUrl();

  const updateObjectStorageUrl = async () => {
    const updatedObjectStorageUrl = await getObjectStorageUrl();
    if (objectStorageUrl === updatedObjectStorageUrl) return;

    dispatch(
      actions.navigation.setMetaObjectStorageUrl(updatedObjectStorageUrl),
    );
  };

  useEffect(() => {
    updateObjectStorageUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectStorageUrl]);
};

export default useSetObjectStorageUrlToNavMeta;

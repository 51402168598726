import setWindowLocationHref from './setWindowLocationHref';

export async function downloadUrlContents(downloadUrl: string) {
  const preflight = await fetch(downloadUrl, { method: 'HEAD' });

  if (preflight.status === 403) {
    throw new Error('Download failed');
  }

  setWindowLocationHref(downloadUrl);
}

export default null;

/* eslint-disable react-hooks/exhaustive-deps */
import * as FS from '@fullstory/browser';
import { useCallback, useRef } from 'react';

import { isTruthy } from '@coral/utils/isTruthy';
import { VERSION } from '@core/constants';
import useGetRequestParams from '@hooks/useGetRequestParams';
import getFlag, { Flags } from '@utils/getFlag';

export type FSEventAttrs = {
  start?: number;
  description?: string;
} & Record<string, unknown>;

export type CreateFSEventFnType = (
  eventName: string,
  attrs: FSEventAttrs,
) => null;

type MakeCreateFSEventProps = {
  shouldUsePerformance: boolean;
  applicationId?: number;
  nodeUid?: number;
  startTime: Timestamp;
  setStartTime: (value: Timestamp) => Timestamp;
};

type UseFullStoryEventProps = {
  shouldUsePerformance?: boolean;
};

type UseFullStoryEventHookType = (props?: UseFullStoryEventProps) => {
  createFSEvent: CreateFSEventFnType;
};

type Timestamp =
  | ReturnType<typeof Date.now>
  | ReturnType<typeof performance.now>;

const isProduction = process.env.NODE_ENV === 'production';

const calcTimestamp = (shouldUsePerformance: boolean): Timestamp => {
  return shouldUsePerformance ? performance.now() : Date.now();
};

const makeCreateFSEvent = ({
  shouldUsePerformance,
  applicationId,
  nodeUid,
  startTime,
  setStartTime,
}: MakeCreateFSEventProps) => {
  const createFSEvent = (eventName: string, attrs: FSEventAttrs): null => {
    // Return null when Fullstory is not enabled
    if (!getFlag(Flags.FULL_STORY)) return null;

    const endTime = calcTimestamp(shouldUsePerformance);

    // if start was passed in attrs object, override startTime with this value:
    if (isTruthy(attrs.start)) {
      startTime = setStartTime(attrs.start as Timestamp);
    }

    const fsEventAttrs = {
      start: startTime,
      end: endTime,
      duration: endTime - startTime,
      dateUsesPerformance: !!shouldUsePerformance,
      applicationId,
      nodeUid,
      version: VERSION,
      sessionURL: FS.getCurrentSessionURL(),
      ...attrs,
    };

    FS.event(isProduction ? eventName : `test_${eventName}`, fsEventAttrs);

    return null;
  };

  return createFSEvent;
};

const useFullstoryEvent: UseFullStoryEventHookType = (
  props?: UseFullStoryEventProps,
) => {
  const { shouldUsePerformance = false } = props ?? {};
  const { application_uid: applicationId, node_uid: nodeUid } =
    useGetRequestParams();
  const startTimeRef = useRef(calcTimestamp(shouldUsePerformance));

  const setStartTime = (value: ReturnType<typeof Date.now>) => {
    startTimeRef.current = value;

    return value;
  };

  const createFSEvent: CreateFSEventFnType = useCallback(
    makeCreateFSEvent({
      shouldUsePerformance,
      applicationId,
      nodeUid,
      startTime: startTimeRef.current,
      setStartTime,
    }),
    [applicationId, nodeUid, shouldUsePerformance],
  );

  return { createFSEvent };
};

export default useFullstoryEvent;

import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

const useGetLocalStorageKey = (key: string, baseLocalKey?: string) => {
  const {
    query: { nodeId, appId },
  } = useSnorkelRouter();
  const userUid = useSelector(selectors.auth.selectUserUid);
  const localKey = baseLocalKey
    ? `${baseLocalKey}${key}`
    : `${userUid}-${appId}-${nodeId}-${key}`;

  return localKey;
};

export default useGetLocalStorageKey;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import useGetLocalStorageKey from '@hooks/useGetLocalStorageKey';

const useCandidateStateContextIndex = () => {
  const dispatch = useDispatch();
  const stateKey = useGetLocalStorageKey('context-index');

  const contextIndex = useSelector(
    selectors.userPrefs.selectCandidateContextIndex(stateKey),
  );

  const setContextIndex = useCallback(
    stateUpdater => {
      dispatch(
        actions.userPrefs.setCandidateContextIndex({
          stateKey,
          value:
            stateUpdater instanceof Function
              ? stateUpdater(contextIndex)
              : stateUpdater,
        }),
      );
    },
    [dispatch, stateKey, contextIndex],
  );

  return { contextIndex, setContextIndex };
};

export default useCandidateStateContextIndex;

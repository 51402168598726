import { difference, isNil } from 'lodash';

import type { UserSettingsJson } from '@api/tdm';
import { getGlobalAccessToken } from '@global/AccessTokenManager';
import type { UserSettings } from '@global/state/reducers/userSettings/slice';
import type { UserSettingsRequestParams } from '@hooks/useGetRequestParams';
import { userSettingsApi } from '@utils/api/serverRequests';
import { getCustomColorsForDatabase } from '@utils/getCustomColors';

// Global user settings are application-agnostic and do not enforce
// an application_uid to be present in the request.
const GLOBAL_USER_SETTINGS = ['dag_manipulation_enabled', 'global_preferences'];

const updateUserSettingsToBackend = async (
  updatedSettings: Partial<UserSettings>,
  requestParams: Partial<UserSettingsRequestParams>,
) => {
  const settingsKeys = Object.keys(updatedSettings);

  // Check if settings keys include any non global settings, block update if true
  const hasGlobalSettings =
    difference(settingsKeys, GLOBAL_USER_SETTINGS).length === 0;

  if (
    !settingsKeys.length ||
    isNil(requestParams.user_uid) ||
    (isNil(requestParams.application_uid) &&
      isNil(requestParams.dataset_batch_uid) &&
      !hasGlobalSettings)
  ) {
    // Prevent setting global workspace settings until we have a reason otherwise.
    return;
  }

  const {
    custom_colors: customColors,
    studio_preferences: studioPreferences,
    ...settingsToUpdate
  } = updatedSettings;

  // Do not pass back all the settings! Only the ones that have been updated. Keep it sparse.
  const nextSettings: Partial<UserSettingsJson> = {
    ...settingsToUpdate,
    ...(customColors
      ? { custom_colors: getCustomColorsForDatabase(customColors) }
      : {}),
    ...(studioPreferences &&
    requestParams.application_uid &&
    requestParams.node_uid
      ? { studio_preferences: studioPreferences }
      : {}),
  };

  // onlyGlobalSettings should be not be saved at application level, only user level. That's why removing application data from request params and only keeping user uid in request params
  const params = hasGlobalSettings
    ? { user_uid: requestParams.user_uid }
    : requestParams;

  const accessToken = getGlobalAccessToken();

  await userSettingsApi.updateUserSettingUserSettingsPost(
    {
      updateUserSettingsRequest: {
        ...params,
        settings: nextSettings,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export default updateUserSettingsToBackend;

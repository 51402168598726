import { useDispatch } from 'react-redux';

import type { HydratedBatch } from '@api/tdm';
import mapBatch from '@app/Annotations/BatchesPage/utils/mapBatch';
import * as actions from '@global/state/actions';
import useCachedRequest from '@hooks/useCachedRequest';
import useGetRequestParams from '@hooks/useGetRequestParams';
import { batchesApi } from '@utils/api/serverRequests';

const useGetCurrentBatch = () => {
  const dispatch = useDispatch();
  const { batch_uid: batchUid } = useGetRequestParams();

  const { data = null, ...rest } = useCachedRequest(
    batchesApi.getBatchBatchesBatchUidGet,
    {
      batchUid:
        batchUid! /** we can use ! op here as we are suspending fetch if the batchUid is undefined */,
    },
    {
      suspendFetch: !batchUid,
      onSuccess: (batch: HydratedBatch | undefined) => {
        if (!batchUid || !batch) {
          return;
        }

        const newBatch = mapBatch(batch);

        dispatch(actions.navigation.setMetaBatchName(newBatch.name));
      },
    },
  );

  return { ...rest, currentBatch: data ? mapBatch(data) : null };
};

export default useGetCurrentBatch;

/* eslint-disable local-rules/require-data-cy */

import Logo from '@components/Logo/logo';
import Icon from '@coral/components/Icon';
import Icons from '@coral/components/Icon/icons';
import Tooltip from '@coral/components/tooltips/Tooltip';

type NavigationLogoProps = {
  toggleVisibility?: VoidFunction;
  isSidebarOpen?: boolean;
};

const NavigationLogo = ({
  isSidebarOpen,
  toggleVisibility,
}: NavigationLogoProps) => {
  return isSidebarOpen ? (
    <div className="flex w-50 items-center justify-between px-4">
      <Logo theme="dark" svg />
      <Tooltip title="Hide sidebar">
        <button
          type="button"
          onClick={toggleVisibility}
          data-cy="collapse-left-sidebar-button"
        >
          <Icon name={Icons.COLLAPSE__LEFT} />
        </button>
      </Tooltip>
    </div>
  ) : (
    <div className="flex items-center justify-between gap-3 px-4">
      <Tooltip title="Show sidebar">
        <button
          type="button"
          onClick={toggleVisibility}
          data-cy="expand-left-sidebar-button"
        >
          <Icon className="content-center" name={Icons.MENU} />
        </button>
      </Tooltip>

      <Logo theme="dark" svg />
    </div>
  );
};

export default NavigationLogo;

import type { SandboxResponseState } from '@app/Deployments/SandboxPage/SandboxResponse/types';
import type { SandboxState } from '@global/state/reducers/sandbox/types';
import { createReducerSlice } from '@utils/redux/reducer';

export const initialState: SandboxState = {
  responseState: { isLoading: false },
};

const reducerSlice = createReducerSlice('sandbox', initialState, {
  clearError: (state: SandboxState) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { errorMessage, ...responseState } = state.responseState;

    return { ...state, responseState };
  },

  setOnStart: (state: SandboxState) => {
    return { ...state, responseState: { isLoading: true } };
  },

  setOnComplete: (
    state: SandboxState,
    { response, duration }: Pick<SandboxResponseState, 'response' | 'duration'>,
  ) => {
    return {
      ...state,
      responseState: { response, duration, isLoading: false },
    };
  },

  setOnError: (state: SandboxState, errorMessage: string | undefined) => {
    return { ...state, responseState: { errorMessage, isLoading: false } };
  },
});

export const { reducer, actionCreators } = reducerSlice;

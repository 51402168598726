import type { HydratedOperatorDag } from '@app/Applications/NewApplicationPage/types';
import pipe from '@utils/pipe';

import type { AppDetailsState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<AppDetailsState> = ({
  appDetails,
}: RootState) => appDetails;

export const selectId: Selector<number> = pipe(
  selectAll,
  ({ id }: AppDetailsState) => id,
);
export const selectName: Selector<string> = pipe(
  selectAll,
  ({ name }: AppDetailsState) => name,
);
export const selectDag: Selector<HydratedOperatorDag> = pipe(
  selectAll,
  ({ dag }: AppDetailsState) => dag,
);
export const selectDatasetUid: Selector<number> = pipe(
  selectAll,
  ({ datasetUid }: AppDetailsState) => datasetUid,
);

export default null;

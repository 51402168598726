import type { RunningJob, RunningJobs } from '@core/types';
import { createReducerSlice } from '@utils/redux/reducer';

import { initialState } from './initialState';
import type { ManagerPageState } from './types';

const reducerSlice = createReducerSlice('managerPage', initialState, {
  reset(): ManagerPageState {
    return initialState;
  },

  addJob(state: ManagerPageState, job: RunningJob): ManagerPageState {
    return { ...state, jobs: { ...state.jobs, [job.uid]: job } };
  },

  setJobs(state: ManagerPageState, jobs: RunningJobs): ManagerPageState {
    return { ...state, jobs };
  },
});

export const { actionTypes, actionCreators, reducer } = reducerSlice;

export default null;

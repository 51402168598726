import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import type { Workspace } from '@api/tdm';
import MenuButton from '@components/Form/MenuButton';
import Dialog from '@components/Modals/Dialog';
import Divider from '@coral/components/Divider';
import Icon, { Icons } from '@coral/components/Icon';
import SelectMenuButton from '@coral/components/SelectMenuButton';
import JobsDialog from '@global/NavigationContainer/NavigationSidebarNav/JobsDialog';
import NavList from '@global/NavigationContainer/NavigationSidebarNav/NavList';
import calcParentPaths from '@global/NavigationContainer/NavigationSidebarNav/utils/calcParentPaths';
import * as selectors from '@global/state/selectors';
import useDialogControl from '@hooks/useDialogControl';
import useGlobalPreferences from '@hooks/useGlobalPreferences';
import useSetWorkspaces from '@hooks/useSetWorkspaces';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import getFlag, { Flags } from '@utils/getFlag';

import CurrentApp from './CurrentApp';
import NotebookItem from './NotebookItem';
import NotificationsMenu from './NotificationMenu';

import NavigationButton from '../common/NavigationButton';
import { ROUTES_SUPPORTING_WORKSPACE_SWITCHING } from '../NavigationSidebarFooter';

const NavigationSidebarNav = () => {
  const navigation = useSelector(selectors.navigation.selectNavigation);
  const currentApplicationNavigation = useSelector(
    selectors.navigation.selectCurrentApplicationNavigation,
  );
  const { updateSelectedWorkspace } = useSetWorkspaces();
  const { enableNotifications } = useGlobalPreferences();

  const workspaces = useSelector(selectors.workspaces.selectWorkspaces);
  const selectedWorkspace = useSelector(
    selectors.workspaces.selectSelectedWorkspace,
  );

  const isNewHomepageEnabled = getFlag(Flags.HOME_PAGE_DESIGN_REFRESH);

  const router = useSnorkelRouter();
  const { handleDialogOpen, handleDialogClose, dialogOpen } =
    useDialogControl();

  const { asPath } = useSnorkelRouter();
  const path = asPath.split('?')[0];

  const [tempSelectedWorkspace, setTempSelectedWorkspace] =
    useState<Workspace | null>(null);

  const notifications = useSelector(selectors.atoms.selectNotifications);

  const onWorkspaceChange = (nextWorkspaceName: string) => {
    const route = router.asPath.split('/').join('');
    const nextWorkspace = workspaces.find(ws => ws.name === nextWorkspaceName);

    if (!nextWorkspace) {
      handleDialogOpen();

      return;
    }

    setTempSelectedWorkspace(nextWorkspace);

    if (ROUTES_SUPPORTING_WORKSPACE_SWITCHING.includes(route)) {
      updateSelectedWorkspace(nextWorkspace);
    } else {
      handleDialogOpen();
    }
  };

  const goToHome = () => {
    const index = workspaces.findIndex(
      ({ workspace_uid }) =>
        tempSelectedWorkspace!.workspace_uid === workspace_uid,
    );

    if (index !== -1) {
      updateSelectedWorkspace(workspaces[index]);
      router.push('/');
    }
  };

  const openPaths = useMemo(
    () =>
      calcParentPaths({
        navItems: [...navigation, ...currentApplicationNavigation],
        targetHref: path,
      }),
    [navigation, currentApplicationNavigation, path],
  );

  return (
    <>
      <div className="my-2" />

      {isNewHomepageEnabled ? (
        <NavigationButton
          href="/home"
          selected={path === '/home'}
          leftIcon={Icons.HOUSE}
        >
          <span
            data-cy="home-page-navigation-button"
            className="flex-1 truncate"
          >
            Home
          </span>
        </NavigationButton>
      ) : null}

      {enableNotifications ? (
        <MenuButton
          menuProps={{
            elevation: 0,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          menu={<NotificationsMenu />}
          button={
            <NavigationButton
              leftIcon={Icons.NOTIFICATION}
              selected={notifications?.length > 0}
              isNotification
            >
              <span
                data-cy="notifications-menu-button"
                className="flex-1 truncate"
              >
                Notifications
              </span>
              <Icon name={Icons.CHEVRON__DOWN} />
            </NavigationButton>
          }
        />
      ) : null}

      <Dialog
        title={`Switch workspace${
          tempSelectedWorkspace?.name
            ? ` to ${tempSelectedWorkspace?.name}`
            : ''
        }`}
        message="Switching workspaces will take you to the applications page for that workspace and exit the current application"
        open={dialogOpen}
        handleClose={handleDialogClose}
        actionButtonProps={{
          buttonText: 'Confirm',
          handleConfirm: goToHome,
          buttonDataCy: 'confirm-workspace-switch',
        }}
      />
      <div className="flex-1">
        <SelectMenuButton
          onValueChange={onWorkspaceChange}
          data={workspaces.map(workspace => workspace.name)}
          searchable
          searchPlaceholder="Search for a workspace"
          button={
            <NavigationButton
              dataCy="workspaces-navigation-button"
              leftIcon={Icons.NOMINAL}
            >
              <span className="flex-1 truncate whitespace-pre">
                {selectedWorkspace?.name ?? 'default'}
              </span>

              <Icon name={Icons.CHEVRON__DOWN} />
            </NavigationButton>
          }
        />
        <Divider className="my-4" />
        <NavList
          child={{ data: navigation, loading: false, tabbed: false }}
          openPaths={openPaths}
        />
        <NotebookItem />
        <CurrentApp data={currentApplicationNavigation} openPaths={openPaths} />
        <JobsDialog />
      </div>
    </>
  );
};

export default NavigationSidebarNav;

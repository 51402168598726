import React from 'react';

import combineClasses from '@coral/utils/combineClasses';

import { AlertTheme } from './types';
import getAlertColors from './utils/getAlertColors';

export { AlertTheme };

type AlertProps = {
  children: React.ReactNode;
  className?: string;
  theme?: AlertTheme;
  showBorder?: boolean;
  'data-cy'?: string;
};

const Alert = (props: AlertProps) => {
  const {
    children,
    className,
    theme = AlertTheme.info,
    showBorder = true,
    'data-cy': dataCy,
  } = props;

  return (
    <div
      className={combineClasses(
        className,
        showBorder ? 'border-l-4' : '',
        getAlertColors(theme),
        'rounded p-4',
      )}
      {...(dataCy ? { 'data-cy': dataCy } : {})}
    >
      {children}
    </div>
  );
};

export default Alert;

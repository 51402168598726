import { createReducerSlice } from '@utils/redux/reducer';

import type { UxState } from './types';

export const initialState: UxState = {
  isAnalysisModalOpen: false,
  isJobsModalOpen: false,
  isLFsModalOpen: false,
  isModelsModalOpen: false,
  isWindowResizing: false,
  scrollToHighlight: null,
};

const reducerSlice = createReducerSlice('ux', initialState, {
  setIsAnalysisModalOpen(
    state: UxState,
    isAnalysisModalOpen: boolean,
  ): UxState {
    return isAnalysisModalOpen === state.isAnalysisModalOpen
      ? state
      : { ...state, isAnalysisModalOpen };
  },

  setIsJobsModalOpen(state: UxState, isJobsModalOpen: boolean): UxState {
    return isJobsModalOpen === state.isJobsModalOpen
      ? state
      : { ...state, isJobsModalOpen };
  },

  setIsLFsModalOpen(state: UxState, isLFsModalOpen: boolean): UxState {
    return isLFsModalOpen === state.isLFsModalOpen
      ? state
      : { ...state, isLFsModalOpen };
  },

  setIsModelsModalOpen(state: UxState, isModelsModalOpen: boolean): UxState {
    return isModelsModalOpen === state.isModelsModalOpen
      ? state
      : { ...state, isModelsModalOpen };
  },

  setIsWindowResizing(state: UxState, isWindowResizing: boolean): UxState {
    return isWindowResizing === state.isWindowResizing
      ? state
      : { ...state, isWindowResizing };
  },

  setScrollToHighlight(
    state: UxState,
    scrollToHighlight: number | null,
  ): UxState {
    return state.scrollToHighlight === scrollToHighlight
      ? state
      : { ...state, scrollToHighlight };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

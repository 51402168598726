import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';

const JotaiAtomsInspector = () => {
  const selectAll = useSelector(selectors.atoms.selectAll);

  useEffect(() => {
    (window as any).sf = (window as any).sf ?? {};
    (window as any).sf.getJotaiAtoms = () => selectAll;

    return () => {
      if ((window as any).sf) (window as any).sf.getJotaiAtoms = undefined;
    };
  }, [selectAll]);

  return null;
};

export default JotaiAtomsInspector;

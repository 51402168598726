import React from 'react';

const SkipToMainContent = () => (
  <a
    className="fixed left-0 top-0 bg-white opacity-0 focus:z-[100] focus:opacity-100"
    href="#main-content"
    tabIndex={0}
  >
    Skip to main content
  </a>
);

export default SkipToMainContent;

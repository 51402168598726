import * as Select from '@radix-ui/react-select';

type SelectMenuItemProps = {
  value: string;
};

const SelectMenuItem = ({ value }: SelectMenuItemProps) => {
  return (
    <Select.Item
      value={value}
      className="text-md cursor-pointer px-4 py-[4px] text-left focus:border-none focus:bg-gray-100 focus:outline-none active:bg-blue-700 active:text-white"
    >
      {value}
    </Select.Item>
  );
};

export default SelectMenuItem;

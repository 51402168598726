import { LFMetricNames, SortDirection, SplitType } from '@core/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { LFTableState } from './types';

export const initialState: LFTableState = {
  split: SplitType.DEV,
  sortOptions: {
    order: SortDirection.DESC,
    column: LFMetricNames.DATE,
  },
};

export const reducerSlice = createReducerSlice('lfTable', initialState, {
  setSplit(state: LFTableState, split: SplitType): LFTableState {
    return { ...state, split };
  },
  setSortOptions(
    state: LFTableState,
    sortOptions: { order: SortDirection; column: LFMetricNames },
  ): LFTableState {
    return { ...state, sortOptions };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

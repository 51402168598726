import AccessToken from './AccessToken';

export type SupportedFeatures = {
  admin_settings: boolean;
  workspace_admin_settings: boolean;
  annotation_workspace: boolean;
  view_all_annotations: boolean;
  development_workspace: boolean;
};

export const defaultSupportedFeatures: SupportedFeatures = {
  admin_settings: false,
  workspace_admin_settings: false,
  annotation_workspace: false,
  development_workspace: true,
  view_all_annotations: false,
};

export const getUserSupportedFeatures = () => {
  return (
    AccessToken.getInstance().getAccessToken()?.supportedFeatures ??
    defaultSupportedFeatures
  );
};

export default getUserSupportedFeatures;

import { LinearProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';

type NotebookIframeProps = {
  url?: string;
  iframeKey?: string;
};

const NotebookIframe = ({ url, iframeKey }: NotebookIframeProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
  }, [url]);

  return (
    <>
      {(loading || url === undefined) && (
        <LinearProgress className="w-full flex-none" />
      )}
      <iframe
        onLoad={() => setLoading(false)}
        title="notebook"
        key={iframeKey}
        className="-mt-[44px] h-[calc(100%+44px)] w-full border-none"
        src={url}
      />
    </>
  );
};

export default NotebookIframe;

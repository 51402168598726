import type { JobInfo } from '@api/tdm';
import { JobState } from '@api/tdm';
import { emptyList, emptyObj } from '@core/empty';
import { createReducerSlice } from '@utils/redux/reducer';

import type { OnboardingState } from './types';

export const initialState: OnboardingState = {
  isDataAndTaskTypeDataLoading: false,
  currentXUids: emptyList,
  currentStepName: null,
  submittedGroundTruthLabels: emptyList,

  preprocessingJobs: emptyObj,
  isPreprocessingJobsAccordionOpen: false,
  isSupportedMetricsFetchSuspended: false,

  // We've gone with the approach of keeping the labelname set to UNKNOWN for onboarding,
  // however, we allow the user to edit this UNKNOWN label prior to submission, therefore
  // we need to keep track of it
  unknownLabelName: 'UNKNOWN',
};

const reducerSlice = createReducerSlice('onboarding', initialState, {
  setIsDataAndTaskTypeDataLoading(
    state: OnboardingState,
    isDataAndTaskTypeDataLoading: OnboardingState['isDataAndTaskTypeDataLoading'],
  ): OnboardingState {
    return isDataAndTaskTypeDataLoading === state.isDataAndTaskTypeDataLoading
      ? state
      : { ...state, isDataAndTaskTypeDataLoading };
  },

  setIsPreprocessingJobsAccordionOpen(
    state: OnboardingState,
    isPreprocessingJobsAccordionOpen: OnboardingState['isPreprocessingJobsAccordionOpen'],
  ): OnboardingState {
    return isPreprocessingJobsAccordionOpen ===
      state.isPreprocessingJobsAccordionOpen
      ? state
      : { ...state, isPreprocessingJobsAccordionOpen };
  },

  setIsSupportedMetricsFetchSuspended(
    state: OnboardingState,
    isSupportedMetricsFetchSuspended: OnboardingState['isSupportedMetricsFetchSuspended'],
  ): OnboardingState {
    return isSupportedMetricsFetchSuspended ===
      state.isSupportedMetricsFetchSuspended
      ? state
      : { ...state, isSupportedMetricsFetchSuspended };
  },

  resetPreprocessingJobs(state: OnboardingState): OnboardingState {
    return emptyObj === state.preprocessingJobs
      ? state
      : { ...state, preprocessingJobs: emptyObj };
  },

  updatePreprocessingJob(
    state: OnboardingState,
    { jobUid, job }: { jobUid: string; job: Partial<JobInfo> },
  ): OnboardingState {
    let { preprocessingJobs } = state;
    preprocessingJobs = {
      ...preprocessingJobs,
      [jobUid]: { ...preprocessingJobs[jobUid], ...job },
    };
    state = { ...state, preprocessingJobs };

    return state;
  },

  addPreprocessingJobs(
    state: OnboardingState,
    uids: string[],
  ): OnboardingState {
    if (uids.length === 0) return state;

    let { preprocessingJobs } = state;
    preprocessingJobs = { ...preprocessingJobs };

    uids.forEach(uid => {
      preprocessingJobs[uid] = { uid, state: JobState.Running, percent: 0 };
    });

    state = { ...state, preprocessingJobs };

    return state;
  },

  setUnknownLabelName(
    state: OnboardingState,
    unknownLabelName: OnboardingState['unknownLabelName'],
  ): OnboardingState {
    return unknownLabelName === state.unknownLabelName
      ? state
      : { ...state, unknownLabelName };
  },

  setSubmittedGroundTruthLabels(
    state: OnboardingState,
    submittedGroundTruthLabels: OnboardingState['submittedGroundTruthLabels'],
  ): OnboardingState {
    return submittedGroundTruthLabels === state.submittedGroundTruthLabels
      ? state
      : { ...state, submittedGroundTruthLabels };
  },

  resetCurrentStepName(state: OnboardingState): OnboardingState {
    return state.currentStepName === null
      ? state
      : { ...state, currentStepName: null };
  },

  setCurrentStepName(
    state: OnboardingState,
    currentStepName: OnboardingState['currentStepName'],
  ): OnboardingState {
    return currentStepName === state.currentStepName
      ? state
      : { ...state, currentStepName };
  },

  setCurrentXUids(
    state: OnboardingState,
    currentXUids: OnboardingState['currentXUids'],
  ): OnboardingState {
    if (currentXUids.length === 0 && state.currentXUids.length === 0) {
      return state;
    }

    return currentXUids.length === 0
      ? { ...state, currentXUids: emptyList }
      : { ...state, currentXUids };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { SandboxState } from './types';

const selectSandbox: Selector<SandboxState> = state => state.sandbox;

export const selectResponseState: Selector<SandboxState['responseState']> =
  pipe(selectSandbox, ({ responseState }) => responseState);

export default null;

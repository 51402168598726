import type { PropsWithChildren } from 'react';

import NavigationContainer from '@global/NavigationContainer';

type NavigationWrapperProps = PropsWithChildren<{
  isPublicRoute?: boolean;
}>;

const NavigationWrapper = ({
  children,
  isPublicRoute = false,
}: NavigationWrapperProps) => {
  return isPublicRoute ? (
    <>{children}</>
  ) : (
    <div className="flex h-full w-full justify-end bg-white">
      <NavigationContainer>
        <main
          id="main-content"
          className="flex-1 overflow-auto transition-all ease-in-out"
        >
          {children}
        </main>
      </NavigationContainer>
    </div>
  );
};

export default NavigationWrapper;

export enum HighlightVariant {
  ANNOTATED_SPAN = 'annotatedSpan',
  HIGHLIGHTED = 'highlighted',
  HYPERLINK = 'hyperlink',
  CLF_LF_VOTES = 'classificationLFVotes',
  SEQUENCE_LF_VOTES = 'sequenceLFVotes',
  LIVE = 'live',
  MODEL_VOTES = 'sequenceModelVotes',
  TRAINING_SET_VOTES = 'sequenceTrainingSetVotes',
}

export enum HighlightStatus {
  BLUR = 'blur',
  DEFAULT = 'default',
  FOCUS = 'focus',
  HOVER = 'hover',
  SNIPPET_BLUR = 'snippetBlur',
  PDF = 'pdf',
}

type LabelStyleProps = {
  color?: string;
  backgroundColor?: string;
};

export type HighlightConfigDetails = {
  description?: string[];
  label?: string;
  raw_label?: number;
  negativeLabel?: string;
  title?: string;
  variant?: HighlightVariant;
  confidence?: number;
  type?: string; // Maps to FilterTypes
  start?: number;
  end?: number;
  annotatorId?: number;
  isFocused?: boolean;
};

export type HighlightBounds = {
  borderLeft?: boolean;
  borderRight?: boolean;
};

export type HighlightConfig = LabelStyleProps &
  HighlightConfigDetails &
  HighlightBounds & {
    status?: HighlightStatus;
  };

export type HighlightConfigWithUid = HighlightConfig & { uid?: string };

export default null;

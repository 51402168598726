import { createReducerSlice } from '@utils/redux/reducer';
import { IMAGE_INITIAL_SIMILARITY } from '@wave/Image/utils';

import type { ImageTool, LFComposerState, ModelTool } from './types';

export const initialState: LFComposerState = {
  imageTool: {
    imagePath: null,
    boundingBox: null,
  },
  modelTool: {
    index: null,
    label: null,
  },
  label: null,
  thresholds: {
    presentThreshold: IMAGE_INITIAL_SIMILARITY,
    absentThreshold: IMAGE_INITIAL_SIMILARITY,
  },
  isLoading: false,
  isInverted: false,
  data: {
    histogramBins: null,
    lfMetrics: null,
  },
  activeDatasetType: null,
};

const reducerSlice = createReducerSlice('lfComposer', initialState, {
  update(
    state: LFComposerState,
    delta: Partial<LFComposerState>,
  ): LFComposerState {
    return Object.keys(delta).length === 0 ? state : { ...state, ...delta };
  },

  reset(): LFComposerState {
    return initialState;
  },

  setImageTool(
    state: LFComposerState,
    imageTool: Partial<ImageTool>,
  ): LFComposerState {
    return Object.keys(imageTool).length === 0
      ? state
      : { ...state, imageTool: { ...state.imageTool, ...imageTool } };
  },

  setModelTool(
    state: LFComposerState,
    modelTool: Partial<ModelTool>,
  ): LFComposerState {
    return Object.keys(modelTool).length === 0
      ? state
      : { ...state, modelTool: { ...state.modelTool, ...modelTool } };
  },

  setLabel(
    state: LFComposerState,
    label: LFComposerState['label'],
  ): LFComposerState {
    return label === state.label ? state : { ...state, label };
  },

  setThresholds(
    state: LFComposerState,
    thresholds: LFComposerState['thresholds'],
  ): LFComposerState {
    return { ...state, thresholds };
  },

  setIsLoading(
    state: LFComposerState,
    isLoading: LFComposerState['isLoading'],
  ): LFComposerState {
    return isLoading === state.isLoading ? state : { ...state, isLoading };
  },

  setIsInverted(
    state: LFComposerState,
    isInverted: LFComposerState['isInverted'],
  ): LFComposerState {
    return isInverted === state.isInverted ? state : { ...state, isInverted };
  },

  setHistogramBinsData(
    state: LFComposerState,
    histogramBins: LFComposerState['data']['histogramBins'],
  ): LFComposerState {
    return histogramBins === state.data.histogramBins
      ? state
      : { ...state, data: { ...state.data, histogramBins } };
  },

  setLFMetricsData(
    state: LFComposerState,
    lfMetrics: LFComposerState['data']['lfMetrics'],
  ): LFComposerState {
    return lfMetrics === state.data.lfMetrics
      ? state
      : { ...state, data: { ...state.data, lfMetrics } };
  },

  setActiveDatasetType(
    state: LFComposerState,
    activeDatasetType: LFComposerState['activeDatasetType'],
  ): LFComposerState {
    return activeDatasetType === state.activeDatasetType
      ? state
      : { ...state, activeDatasetType };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;

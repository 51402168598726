import { actionCreators as atoms } from './atoms/slice';
import { actionCreators as dataFilters } from './dataFilters/slice';
import { actionCreators as docFilters } from './docFilters/slice';
import { actionCreators as plot } from './plot/slice';
import { actionCreators as searchInput } from './searchInput/slice';
import { actionCreators as similarityLfBuilder } from './similarityLfBuilder/slice';
import { actionCreators as studio } from './studio/slice';
import { actionCreators as suggestedSearches } from './suggestedSearches/slice';

export const actionCreators = {
  atoms,
  dataFilters,
  docFilters,
  plot,
  searchInput,
  studio,
  similarityLfBuilder,
  suggestedSearches,
};

export default null;

import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import type { Workspace } from '@api/tdm';
import { UserRole } from '@api/tdm';
import * as selectors from '@global/state/selectors';
import useRequest from '@hooks/useRequestWithLogging';
import alphabeticalSort from '@utils/alphabeticalSort';
import { workspacesApi } from '@utils/api/serverRequests';

import useSetWorkspaces from './useSetWorkspaces';

const useGetWorkspaces = () => {
  const request = useRequest();
  const [loading, setLoading] = useState<boolean>(false);
  const { admin_settings, workspace_admin_settings } = useSelector(
    selectors.auth.selectSupportedFeatures,
  );
  const workspaces = useSelector(selectors.workspaces.selectWorkspaces);
  const { updateWorkspaces } = useSetWorkspaces();

  const getWorkspaces = async (
    existingWorkspaces: Workspace[] = [],
    isAdmin?: boolean,
    signal?: AbortSignal,
  ) => {
    setLoading(true);

    const role = (() => {
      if (admin_settings) return [UserRole.Superadmin];
      if (workspace_admin_settings) return [UserRole.Admin];

      return undefined;
    })();

    const data = await request(
      workspacesApi.listWorkspacesWorkspacesGet,
      { roles: isAdmin ? role : undefined },
      { signal },
    );

    setLoading(false);

    if (!isEmpty(data)) {
      const sortedWorkspaces = data.workspaces.toSorted(
        (a: Workspace, b: Workspace) => alphabeticalSort(a.name, b.name),
      );

      return {
        ...data,
        workspaces: sortedWorkspaces,
      };
    }

    return { workspaces: existingWorkspaces };
  };

  const getAdminWorkspaces = async (signal?: AbortSignal) => {
    return (await getWorkspaces([], true, signal)).workspaces || [];
  };

  const refreshWorkspaces = async (signal?: AbortSignal) => {
    const [nextWorkspaces, nextAdminWorkspaces] = await Promise.all([
      getWorkspaces(workspaces, undefined, signal),
      getAdminWorkspaces(signal),
    ]);
    updateWorkspaces(nextWorkspaces.workspaces, nextAdminWorkspaces);
  };

  return {
    loading,
    getWorkspaces,
    getAdminWorkspaces,
    refreshWorkspaces,
  };
};

export default useGetWorkspaces;
